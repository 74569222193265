/* eslint-disable react/react-in-jsx-scope */
import img1 from 'assets/img/support-desk/reports/1.png';
import img2 from 'assets/img/support-desk/reports/2.png';
import img3 from 'assets/img/support-desk/reports/3.png';
import img4 from 'assets/img/support-desk/reports/4.png';
import img5 from 'assets/img/support-desk/reports/5.png';
import img6 from 'assets/img/support-desk/reports/6.png';
import img7 from 'assets/img/support-desk/reports/7.png';
import { BsChevronContract, BsClock, BsEraser, BsFillBuildingFill, BsTag } from 'react-icons/bs';
import {
  FiLogOut,
  FiUserCheck
} from 'react-icons/fi';
import { MdAccountCircle, MdBeachAccess, MdCalendarMonth, MdDevices, MdEmail, MdIntegrationInstructions, MdOutlineAdminPanelSettings, MdSupervisedUserCircle, MdViewList } from 'react-icons/md';

export const reports = {
  analysis: [
    {
      title: 'In-Depth Helpdesk',
      description: 'an overview of your helpdesk system',
      img: img1
    },
    {
      title: 'Trends in Ticket Volume',
      description: 'an overview of the number of tickets',
      img: img2
    }
  ],
  customerSatisfaction: [
    {
      title: 'Analysis of the Top Customers',
      description: 'Check out our customer stories',
      img: img3
    },
    {
      title: 'Customer Satisfaction Survey',
      description: 'Check out the report details',
      img: img4
    }
  ],
  productivity: [
    {
      title: 'Performance of Agents',
      description: 'Check out the report details',
      img: img5
    },
    {
      title: 'Performance in a Group',
      description: 'Check out the report details',
      img: img6
    },
    {
      title: 'Distribution of Performance',
      description: 'Check out the report details',
      img: img7
    }
  ]
};

export const sla = [
  {
    icon: <BsChevronContract className="text-primary fs-2" />,
    color: 'primary',
    title: 'Políticas de SLA',
    description:
      "Configurações de políticas de SLA",
    to: '/registration/sla-policies'
  },
  {
    icon: <BsClock className="text-info fs-2" />,
    color: 'info',
    title: 'Horário de funcionamento',
    description:
      "Configurações de horário comercial",
    to: '/registration/business-hour'
  },
  {
    icon: <BsEraser className="text-warning fs-2" />,
    color: 'warning',
    title: 'Motivos de pausa',
    description:
      "Configurações de motivos de pausa de SLA",
    to: '/registration/pause-reasons'
  }
];

export const ticket = [
  {
    icon: <MdViewList className="text-primary fs-2" />,
    color: 'primary',
    title: 'Catálogos de serviços',
    description:
      "Configurações de catálogos de serviços",
    to: '/registration/services-catalog'
  },
  {
    icon: <MdCalendarMonth className="text-info fs-2" />,
    color: 'info',
    title: 'Tickets agendados',
    description:
      "Configurações de políticas de tickets agendados",
    to: '/registration/scheduleds-tickets'
  },
  {
    icon: <BsTag className="text-warning fs-2" />,
    color: 'warning',
    title: 'Tipos de ticket',
    description:
      "Configurações de tipos de ticket",
    to: '/registration/types-ticket'
  },
];

export const generals = [

  {
    icon: <MdEmail className="text-primary fs-2" />,
    color: 'primary',
    title: 'Caixas de e-mail',
    description:
      "Configurações de caixas de e-mail",
    to: '/registration/mailbox'
  },
  {
    icon: <MdIntegrationInstructions className="text-info fs-2" />,
    color: 'info',
    title: 'Integrações',
    description:
      "Configurações de integrações",
    to: '/integrations/datto-rmm',
    isNew: true
  },
  {
    icon: <MdBeachAccess className="text-warning fs-2" />,
    color: 'warning',
    title: 'Feriados',
    description:
      "Configurações de feriados",
    to: '/registration/holidays'
  },
  {
    icon: <MdDevices className="text-primary fs-2" />,
    color: 'primary',
    title: 'Tipos de ativos',
    description:
      "Configurações de tipos de ativos",
    to: '/registration/asset-types'
  },
];

export const team = [
  {
    icon: <MdAccountCircle className="text-primary fs-2" />,
    color: 'primary',
    title: 'Agentes',
    description:
      "Configurações de agentes",
    to: '/registration/agents'
  },
  {
    icon: <MdSupervisedUserCircle className="text-info fs-2" />,
    color: 'info',
    title: 'Grupos de serviço',
    description:
      'Configurações de grupos de serviço',
    to: '/registration/service-groups'
  },
  {
    icon: <MdOutlineAdminPanelSettings className="text-warning fs-2" />,
    color: 'warning',
    title: 'Perfis de permissões',
    description: 'Configurações de perfis de permissões',
    to: '/registration/profiles'
  }
];

export const account = [
  {
    icon: <FiUserCheck className="text-primary fs-2" />,
    color: 'primary',
    title: 'Meu perfil',
    description:
      'Ver meu perfil',
    to: '/user/profile'
  },
  {
    icon: <BsFillBuildingFill className="text-info fs-2" />,
    color: 'info',
    title: 'Minha empresa',
    description:
      'Ver dados da minha empresa',
    to: '/company/my-company'
  },
  {
    icon: <FiLogOut className="text-warning fs-2" />,
    color: 'warning',
    title: 'Logout',
    description: 'Fazer logout',
    to: `${process.env.REACT_APP_BASE_URL}/logout`
  }
];