import { faChevronDown, faChevronUp, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

const AssetProperties = ({ data }) => {
  const [showBody, setShowBody] = useState(true);
  const [body, setBody] = useState({});

  useEffect(() => {
    setBody(data);
  }, [data]);

  const handleChange = e => {
    setBody({ ...body, [e.target.name]: e.target.value });
  };

  return (
    <>
      <FalconComponentCard>
        <Flex
          className="p-3 bg-100 w-100"
          onClick={() => setShowBody(!showBody)}
          role="button" justifyContent="between">
          <h6 className="m-0">EDITAR PROPRIEDADES</h6>
          <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
        </Flex>
        {showBody &&
          <FalconComponentCard.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={body.name}
                  name="name"
                  onChange={handleChange}
                  required
                  placeholder="Insira um nome" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  required
                  value={body.assetType?.name}
                  onChange={handleChange}
                  name="assetType"
                  placeholder="Insira um tipo" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Número de série</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={body.serialNumber}
                  onChange={handleChange}
                  name="serialNumber"
                  placeholder="Insira um número de série" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={body.description}
                  onChange={handleChange}
                  name="description"
                  placeholder="Insira uma descrição" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  type="switch"
                  disabled
                  value={body.inactive}
                  checked={!body.inactive}
                  name="inactive"
                  onChange={() => setBody({ ...body, inactive: !body.inactive })}
                />
              </Form.Group>
              <Form.Group className="w-100" as={Flex} justifyContent="end">
                <Button disabled>
                  <FontAwesomeIcon icon={faSave} size="sm" className="me-1" />
                  Salvar
                </Button>
              </Form.Group>
            </Form>
          </FalconComponentCard.Body>
        }
      </FalconComponentCard>
    </>
  );
};

export default AssetProperties;