import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer } from 'react';
import { Button, Table } from 'react-bootstrap';

const paddingLeft = {
  category: '2rem',
  subcategory: '6rem'
};

const toggleReducer = (state, action) => {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return action.initialState;
    case 'TOGGLE_CATALOG':
      return {
        ...state,
        catalogs: state.catalogs.includes(action.index)
          ? state.catalogs.filter(i => i !== action.index)
          : [...state.catalogs, action.index]
      };
    case 'TOGGLE_CATEGORY':
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.catalogIndex]: state.categories[action.catalogIndex]?.includes(action.categoryIndex)
            ? state.categories[action.catalogIndex].filter(i => i !== action.categoryIndex)
            : [...(state.categories[action.catalogIndex] || []), action.categoryIndex]
        }
      };
    default:
      return state;
  }
};

const CatalogStatistics = ({ data }) => {
  const [state, dispatch] = useReducer(toggleReducer, {
    catalogs: [],
    categories: {}
  });

  useEffect(() => {
    const initialState = {
      catalogs: data.map((_, index) => index),
      categories: data.reduce((acc, catalog, catalogIndex) => {
        acc[catalogIndex] = catalog.children.map((_, categoryIndex) => categoryIndex);
        return acc;
      }, {})
    };
    dispatch({ type: 'SET_INITIAL_STATE', initialState });
  }, [data]);

  return (
    <div className='mt-3'>
      <Table striped hover>
        <thead>
          <tr>
            <th style={{ width: '20px' }}></th>
            <th>Nome</th>
            <th>Total de Tickets</th>
            <th>Duração Total (Horas)</th>
          </tr>
        </thead>
        <tbody>
          {data && data.map((catalog, catalogIndex) => (
            <React.Fragment key={catalogIndex}>
              <CatalogRow
                catalog={catalog}
                catalogIndex={catalogIndex}
                isExpanded={state.catalogs.includes(catalogIndex)}
                toggleCatalog={() => dispatch({ type: 'TOGGLE_CATALOG', index: catalogIndex })}
              />
              {state.catalogs.includes(catalogIndex) && catalog.children.map((category, categoryIndex) => (
                <React.Fragment key={categoryIndex}>
                  <CategoryRow
                    category={category}
                    catalogIndex={catalogIndex}
                    categoryIndex={categoryIndex}
                    isExpanded={state.categories[catalogIndex]?.includes(categoryIndex)}
                    toggleCategory={() => dispatch({ type: 'TOGGLE_CATEGORY', catalogIndex, categoryIndex })}
                  />
                  {state.categories[catalogIndex]?.includes(categoryIndex) && category.children.map((subcategory, subIndex) => (
                    <SubcategoryRow key={subIndex} subcategory={subcategory} />
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const CatalogRow = ({ catalog, catalogIndex, isExpanded, toggleCatalog }) => (
  <tr>
    <td>
      <Button size='sm' variant="default" onClick={toggleCatalog} className='me-2 py-0 px-2'>
        <FontAwesomeIcon size='sm' style={{ fontSize: 8 }} icon={isExpanded ? faMinus : faPlus} />
      </Button>
    </td>
    <td>{catalog.catalogName}</td>
    <td>{catalog.totalTickets}</td>
    <td>{catalog.totalDuration}</td>
  </tr>
);

const CategoryRow = ({ category, catalogIndex, categoryIndex, isExpanded, toggleCategory }) => (
  <tr>
    <td></td>
    <td style={{ paddingLeft: paddingLeft.category }}>
      <Button size='sm' variant="default" onClick={toggleCategory} className='me-2 py-0 px-2'>
        <FontAwesomeIcon size='sm' style={{ fontSize: 8 }} icon={isExpanded ? faMinus : faPlus} />
      </Button>
      {category.categoryName}
    </td>
    <td>{category.totalTickets}</td>
    <td>{category.totalDuration}</td>
  </tr>
);

const SubcategoryRow = ({ subcategory }) => (
  <tr>
    <td></td>
    <td style={{ paddingLeft: paddingLeft.subcategory }}>{subcategory.subcategoryName}</td>
    <td>{subcategory.totalTickets}</td>
    <td>{subcategory.totalDuration}</td>
  </tr>
);

export default CatalogStatistics;
