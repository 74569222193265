import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const DattoDevice = ({ data }) => {
  const [showBody, setShowBody] = useState(true);

  return (
    <>
      <FalconComponentCard>
        <Flex
          className="p-3 bg-100 w-100"
          onClick={() => setShowBody(!showBody)}
          role="button" justifyContent="between">
          <h6 className="m-0">DISPOSITIVO DATTO RMM</h6>
          <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
        </Flex>
        {showBody &&
          <FalconComponentCard.Body>
            <Flex className="mb-3">
              <Button
                target="_blank"
                size="sm"
                className="me-2"
                as={Link}
                variant="primary"
                to={data.webRemoteUrl}>Acesso remoto</Button>

              <Button
                target="_blank"
                as={Link}
                variant="primary"
                to={data.portalUrl}
                size="sm"
              >Dispositivo</Button>
            </Flex>

            <span className="fs-10">Site Datto RMM</span>
            <span className="fs-10 fw-semibold d-block">{data.siteName}</span>
          </FalconComponentCard.Body>
        }
      </FalconComponentCard>
    </>
  );
};

export default DattoDevice;