import { Toast } from "react-bootstrap";

const SuccessRequest = ({ message, showAlert, setShowAlert }) => {
  return (
    <Toast
      bg='success'
      onClose={setShowAlert}
      autohide
      show={showAlert}
      delay={3000}
      style={{ zIndex: 10000, position: 'fixed', top: 20, right: '40%' }}>
      <Toast.Body><p className="text-white p-0 m-0">{message}</p></Toast.Body>
    </Toast>
  );
};

export default SuccessRequest;