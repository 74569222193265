import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { formatDateTime } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CompanyDetailsHeader = ({ data }) => {
  const [company, setCompany] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setCompany(data);
  }, [data]);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Flex justifyContent="between" alignItems="center" className="w-100">
          <div className='d-flex align-items-center'>
            <IconButton
              onClick={() => navigate(-1)}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
              className="me-2"
            />
            <h5>
              Minha empresa
            </h5>
          </div>
          <div>
            <h6 className="text-uppercase text-600">
              Empresa
              <FontAwesomeIcon icon={faBuilding} className="ms-2" />
            </h6>
          </div>
        </Flex>
      </Card.Header>
      <Card.Body className="d-flex border-top">
        <Flex className="me-3">
          <FontAwesomeIcon
            icon="user"
            className="text-success me-2"
            transform="down-5"
          />
          <div className="flex-1">
            <p className="mb-0">Criado em</p>
            <p className="fs-10 mb-0 text-600">{formatDateTime(company.creationDate)}</p>
          </div>
        </Flex>
        <Flex>
          <FontAwesomeIcon
            icon="user"
            className="text-warning me-2"
            transform="down-5"
          />
          <div className="flex-1">
            <p className="mb-0">Modificado em</p>
            <p className="fs-10 mb-0 text-600">{formatDateTime(company.lastUpdateDate)}</p>
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default CompanyDetailsHeader;
