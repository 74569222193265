import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FailedRequest from 'components/alerts/FailedRequest';
import SuccessRequest from 'components/alerts/SuccessRequest';
import useApi from 'helpers/api';
import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import CompanyDetailsHeader from './CompanyDetailsHeader';
import CompanyInfo from './CompanyInfo';
import CompanyLogo from './CompanyLogo';

const MyCompany = () => {
  const api = useApi();
  const [companyId, setCompanyId] = useState('');
  const [company, setCompany] = useState({});
  const [error, setError] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    getCompanyId();
  }, []);

  useEffect(() => {
    if (companyId)
      getCompany();
  }, [companyId]);

  const getCompany = async () => {
    const response = await api.companies.findById(companyId);
    setCompany(response);
  };

  const getCompanyId = async () => {
    let response = await api.companies.getMyCompanyId();
    if (response)
      setCompanyId(response);
  };

  return (
    <>
      <SuccessRequest
        message="Empresa editada com sucesso"
        showAlert={isSuccess}
        setShowAlert={() => setIsSuccess(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showError}
        setShowAlert={(() => setShowError(false))} />
      <CompanyDetailsHeader data={company} />
      <Row className="g-3">
        <Col xxl={12}>
          <Card>
            <Tab.Container defaultActiveKey="basicData">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="basicData"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Dados básicos</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="logo"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon={faImage}
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Logo</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="basicData">
                    <CompanyInfo
                      data={company}
                      success={response => setIsSuccess(response)}
                      error={error => {
                        setError(error);
                        setShowError(true);
                      }} />
                  </Tab.Pane>
                </Tab.Content>

                <Tab.Pane eventKey="logo">
                  <CompanyLogo company={company} />
                </Tab.Pane>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MyCompany;
