import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Error403 = () => (
  <Card className="text-center h-100">
    <Card.Body className="p-5">
      <div className="display-1 text-300 fs-error">403</div>
      <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
        Acesso negado!
      </p>
      <hr />
      <p>
        Você não tem permissão para executar esta ação. Fale com o administrador de sua equipe.
      </p>
      <Link to="/dashboards/tickets">Voltar para a página inicial</Link>
    </Card.Body>
  </Card>
);

export default Error403;
