import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import { formatDateTime } from 'helpers/utils';
import { PrioritySelect } from 'hooks/useTicketsTable';
import PropTypes from 'prop-types';
import { useKanbanContext } from 'providers/KanbanProvider';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TaskDropMenu = ({ id }) => {
  const { kanbanDispatch } = useKanbanContext();

  const {
    config: { isRTL }
  } = useAppContext();

  const handleRemoveTaskCard = () => {
    kanbanDispatch({
      type: 'REMOVE_TASK_CARD',
      payload: { id }
    });
  };

  return (
    <Dropdown
      onClick={e => e.stopPropagation()}
      align="end"
      className="font-sans-serif"
    >
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
      >
        <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-0" align={isRTL ? 'start' : 'end'}>
        <Dropdown.Item href="#!">Add Card</Dropdown.Item>
        <Dropdown.Item href="#!">Edit</Dropdown.Item>
        <Dropdown.Item href="#!">Copy link</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleRemoveTaskCard} className="text-danger">
          Remove
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const TaskCard = ({
  ticket,
  index,
  agentPhoto
}) => {
  const { kanbanDispatch } = useKanbanContext();

  const defaultPhoto = 'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=';

  const handleModalOpen = () => {
    kanbanDispatch({ type: 'OPEN_KANBAN_MODAL', payload: { image: image } });
  };

  const isValidSlaResponse = (ticket.statusSlaResponse && ticket.statusSlaResponse.description === "Dentro do prazo") || (ticket.statusSlaResponse && ticket.statusSlaResponse.description === "Prestes a estourar");
  const isSlaResponsePaused = (ticket.statusSlaResponse && ticket.statusSlaResponse.description === 'SLA pausado') || (ticket.statusSlaResponse && ticket.statusSlaResponse.description === 'SLA pausado');
  const isValidSlaSolution = (ticket.statusSlaSolution && ticket.statusSlaSolution.description === "Dentro do prazo") || (ticket.statusSlaSolution && ticket.statusSlaSolution.description === "Prestes a estourar");
  const isSlaSolutionPaused = (ticket.statusSlaSolution && ticket.statusSlaSolution.description === 'SLA pausado') || (ticket.statusSlaSolution && ticket.statusSlaSolution.description === 'SLA pausado');

  // styles we need to apply on draggables
  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : ''
  });

  return (
    <Draggable draggableId={`ticket${ticket.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="kanban-item"
        >
          <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
          >
            <Card.Body>
              {ticket.status &&
                <p className="fw-semi-bold mb-3 mb-sm-2">
                  <Link to={`/tickets/${ticket.id}`} style={{ color: ticket.status.color }}>#{ticket.code}</Link>
                </p>}
              <p className="fw-bold mb-3 mb-sm-2 overflow-x-hidden" style={{ textOverflow: 'ellipsis' }}>
                <Link to={`/tickets/${ticket.id}`} className='text-nowrap' style={{ color: ticket.status.color, textOverflow: 'ellipsis' }}>{ticket.subject}</Link>
              </p>
              <div className='mb-2 w-100 d-flex justify-content-between align-items-center'>
                <PrioritySelect
                  title={ticket.priority.description}
                  color={ticket.priority.color}
                  data={ticket.priority.data}
                  className="ms-md-4 ms-xl-0"
                  style={{ width: '7.5rem' }}
                />
                <h6 className="mb-0 overflow-x-hidden">
                  <Link
                    to={`/registration/customers/${ticket.customer.id}`}
                    className="text-800 d-flex align-items-center gap-1 my-2"
                  >
                    <FontAwesomeIcon icon="user" transform="shrink-3 up-1" />
                    <span className='text-nowrap overflow-hidden' style={{ textOverflow: 'ellipsis' }}>{ticket.customer.fantasyName || ticket.customer.name}</span>
                  </Link>
                </h6>
              </div>

              <div className='mb-2 w-100 d-flex justify-content-end align-items-center'>
                {ticket.user && <Avatar
                  size="l"
                  src={agentPhoto || defaultPhoto} />}
              </div>
              <div className='mb-2 w-100 d-flex justify-content-between align-items-center'>
                <span className='fs--3'>{formatDateTime(ticket.creationDate)}</span>
                <div className='d-flex'>
                  <div className='d-flex me-2'>
                    <OverlayTrigger overlay={<Tooltip>
                      {isValidSlaResponse
                        ? formatDateTime(ticket.responseSlaExpiration)
                        : <span>{ticket.statusSlaResponse && ticket.statusSlaResponse.description}</span>
                      }
                    </Tooltip>}>
                      <div style={{ display: 'inline' }}>
                        <FontAwesomeIcon icon={faClock} style={{
                          color: ticket.
                            statusSlaResponse && ticket.statusSlaResponse.color
                        }} />
                      </div>
                    </OverlayTrigger>
                  </div>

                  <div className='d-flex'>
                    <OverlayTrigger overlay={<Tooltip>
                      {isValidSlaSolution
                        ? formatDateTime(ticket.solutionSlaExpiration)
                        : <span>{ticket.statusSlaSolution && ticket.statusSlaSolution.description}</span>
                      }
                    </Tooltip>}>
                      <div style={{ display: 'inline' }}>
                        <FontAwesomeIcon icon={faClock} style={{
                          color: ticket.
                            statusSlaSolution && ticket.statusSlaSolution.color
                        }} />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

TaskDropMenu.propTypes = {
  id: PropTypes.number.isRequired
};

TaskCard.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string
      })
    ),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string
      })
    ),
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string
      })
    ),
    checklist: PropTypes.shape({
      totalCount: PropTypes.number,
      completed: PropTypes.number
    })
  }),
  index: PropTypes.number
};

export default TaskCard;
