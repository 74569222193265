import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TicketsByPriorityChart from './TicketsByPriorityChart';

const TicketsByPriority = ({ data }) => {
  return (
    <div className="h-100 pt-3">
      <Row className="w-100">
        <Col lg={12} xs={12}>
          {data &&
            <TicketsByPriorityChart data={data} />
          }
        </Col>
      </Row>
    </div>
  );
};

export default TicketsByPriority;
