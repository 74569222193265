import FailedRequest from 'components/alerts/FailedRequest';
import SuccessRequest from 'components/alerts/SuccessRequest';
import TicketDetails from 'components/tickets/TicketDetails';
import useApi from 'helpers/api';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ContactInfo from './ContactInfo';
import PropertiesFilteringForm from './PropertiesFilteringForm';
import SlaInfo from './SlaInfo';
import TicketsPreviewHeader from './TicketsPreviewHeader';

const TicketsPreview = () => {
  const { id } = useParams();
  const api = useApi();
  const [ticket, setTicket] = useState({});
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [success, setSuccess] = useState('');
  const ticketDetails = useRef();

  useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = async () => {
    let response = await api.tickets.findById(id);
    setTicket(response);
  };

  const editTicket = async body => {
    let response = await api.tickets.edit(ticket.id, body);
    if (response.status) {
      getDetails();
      ticketDetails.current.getNewLogs();
      setIsSuccess(true);
      setSuccess("Ticket editado com sucesso");
    } else {
      setError(response);
      setShowError(true);
    }
  }

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={isSuccess}
        setShowAlert={() => setIsSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={(() => setShowError(false))} />
      <Row>
        <Col xl={12}>
          <TicketsPreviewHeader
            data={ticket}
            refreshData={() => getDetails()}
            refreshLogs={() => ticketDetails.current.getNewLogs()}
            refreshFiles={() => ticketDetails.current.getFiles()}
            refreshNotes={() => ticketDetails.current.getNotes()}
            success={success => {
              if (success != null) {
                setIsSuccess(true);
                setSuccess(success);
              }
            }}
            error={error => {
              if (error != null) {
                setError(error);
                setShowError(true);
              }
            }} />
        </Col>
        <Col xxl={9} xl={9} className='pe-0'>
          <div className='position-sticky'>
            <TicketDetails data={ticket} refreshData={() => getDetails()} ref={ticketDetails} />
          </div>
        </Col>
        <Col xxl={3} xl={3}>
          <Row className="g-3">
            <Col xs={12}>
              <ContactInfo
                data={ticket} />
            </Col>
            <Col md={6} xl={12}>
              <SlaInfo data={ticket} refreshData={() => getDetails()} />
            </Col>
            <Col md={6} xl={12}>
              <PropertiesFilteringForm data={ticket} handleSubmit={editTicket} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TicketsPreview;