import { createCookie } from "helpers/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Authorized = () => {
  const navigate = useNavigate();

  async function fetchToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const grantType = 'authorization_code';
    const credentialsClient = btoa(`${process.env.REACT_APP_CLIENT_NAME}:${process.env.REACT_APP_CLIENT_SECRET}`);

    const request = await fetch(`${process.env.REACT_APP_BASE_URL}/oauth2/token?grant_type=${grantType}&code=${code}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`, {
      method: 'POST',
      headers: {
        'Authorization': `Basic ${credentialsClient}`,
        'Content-Type': 'application/json'
      }
    });

    if (request.ok) {
      const response = await request.json();
      createCookie('token', response.access_token);
      return navigate('/dashboards/tickets');
    }
  }

  useEffect(() => {
    fetchToken();
  }, []);

  return <h1>Carregando...</h1>
};

export default Authorized;