import { formatDateTime } from "helpers/utils";
import { useEffect, useRef } from "react";
import { Col, Modal, Row } from "react-bootstrap";

const TicketLogViewModal = ({ data, showModal, handleClose }) => {
  const divDescriptionLog = useRef(null);

  useEffect(() => {
    if (divDescriptionLog.current != null) {
      const imgs = divDescriptionLog.current.querySelectorAll('img');

      if (imgs) {
        imgs.forEach(img => {
          img.style.width = '100%';
          img.style.height = 'auto';
        });
      }
    }
  }, [data]);

  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Visualizar atividade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col md={4}>
            <h6>Tipo</h6>
            <p>{data.typeTicketLog && data.typeTicketLog.description}</p>
          </Col>

          <Col md={4}>
            <h6>Data</h6>
            <p>{formatDateTime(data.date)}</p>
          </Col>

          <Col md={4}>
            <h6>Usuário</h6>
            <p>{data.user ? data.user.firstName : "Sistema"}</p>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h6>Descrição</h6>
            <div ref={divDescriptionLog} className="w-100 overflow-x-hidden" dangerouslySetInnerHTML={{ __html: data.description }} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default TicketLogViewModal;