import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import useApi from 'helpers/api';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import HoursIndicatedChart from './HoursIndicatedByAgentChart';

const HoursIndicated = ({ filters }) => {
  const api = useApi();
  const [data, setData] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    getData();
  }, [filters]);

  const handleLegend = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name: name
    });
  };

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.productivity.hoursIndicated(queryParams);
    setData(response);
  };

  const totalExternalHours = data.reduce((acc, item) => acc + item.externalHours, 0);
  const totalInternalHours = data.reduce((acc, item) => acc + item.internalHours, 0);

  const formatValue = (value) => {
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    return `${hours}h ${minutes}m`;
  }
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Horas Apontadas"
        titleTag="h4"
        className="border-200 border-bottom py-2"
      />
      <Card.Body className="py-0">
        <Flex className="g-0">
          <div
            className="border-md-end border-md-200 pt-3 pt-md-0 d-flex flex-column"
          >
            <Row className="flex-md-column pe-3 pt-3">
              <Col
                xs="auto"
                className="border-end border-md-end-0 border-md-bottom pb-md-3 mb-md-3 ps-0 ps-md-3 pe-1 pe-md-3 me-1 me-md-0"
              >
                <div className="d-flex align-items-center mb-1">
                  <Form.Check.Label
                    htmlFor="internal"
                    className="fs-9 mb-1 text-700 cursor-pointer"
                  >
                    {data && formatValue(totalInternalHours)}
                  </Form.Check.Label>
                  <Form.Check.Input
                    onChange={event => handleLegend(event, 'Interno')}
                    type="checkbox"
                    id="internal"
                    defaultChecked
                    className="small-dot mt-0 shadow-none remove-checked-icon rounded-circle cursor-pointer"
                  />
                </div>
                <Form.Check.Label
                  htmlFor="internal"
                  className="fs-11 mb-0 text-700 cursor-pointer"
                >
                  Interno
                </Form.Check.Label>
              </Col>
              <Col
                xs="auto"
                className="border-end border-md-end-0 border-md-bottom pb-md-3 mb-md-3 pe-1 pe-md-3 me-1 me-md-0"
              >
                <div className="d-flex align-items-center mb-1">
                  <Form.Check.Label
                    htmlFor="external"
                    className="fs-9 mb-1 text-700 cursor-pointer"
                  >
                    {data && formatValue(totalExternalHours)}
                  </Form.Check.Label>
                  <Form.Check.Input
                    onChange={event => handleLegend(event, 'Externo')}
                    type="checkbox"
                    id="external"
                    defaultChecked
                    className="small-dot mt-0 shadow-none remove-checked-icon rounded-circle cursor-pointer balance-tickets"
                  />
                </div>
                <Form.Check.Label
                  htmlFor="external"
                  className="fs-11 mb-0 text-700 cursor-pointer"
                >
                  Externo
                </Form.Check.Label>
              </Col>
            </Row>
          </div>
          <div xl={11} xxl={11} className="flex-1 echart-unresolved-tickets-container">
            <HoursIndicatedChart data={data} ref={chartRef} />
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

HoursIndicated.propTypes = {
  filters: PropTypes.object.isRequired
};

export default HoursIndicated;
