import PropTypes from 'prop-types';
import { useKanbanContext } from 'providers/KanbanProvider';
import React from 'react';

const KanbanColumnHeader = ({ id, title, itemCount }) => {
  const { kanbanDispatch } = useKanbanContext();

  const handleRemoveColumn = () => {
    kanbanDispatch({
      type: 'REMOVE_KANBAN_COLUMN',
      payload: { id }
    });
  };

  return (
    <div className="kanban-column-header">
      <h5 className="fs-9 mb-0">
        {title} <span className="text-500">({itemCount})</span>
      </h5>
    </div>
  );
};

KanbanColumnHeader.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default KanbanColumnHeader;
