import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";

const ExportMenu = ({ downloadXLSX, downloadCSV, downloadPDF }) => {
  return (
    <Dropdown className="me-3">
      <Dropdown.Toggle>
        <FontAwesomeIcon icon={faDownload} className='me-1' />
        Exportar
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={downloadPDF}>Exportar para PDF</Dropdown.Item>
        <Dropdown.Item onClick={downloadXLSX}>Exportar para Excel</Dropdown.Item>
        <Dropdown.Item onClick={downloadCSV}>Exportar para CSV</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ExportMenu;