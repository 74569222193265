import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isHover ? 'white' : 'black',
  }),
};

const MultiSelect = forwardRef(({ options, placeholder, styles = {}, ...rest }, ref) => {
  const mergedStyles = {
    ...customStyles,
    ...styles,
  };

  return (
    <Select
      ref={ref}
      closeMenuOnSelect={false}
      isMulti
      options={options}
      placeholder={placeholder}
      classNamePrefix="react-select"
      {...rest}
      styles={mergedStyles}
    />
  );
});

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

export default MultiSelect;
