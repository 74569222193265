import userAvatar from 'assets/img/user-avatar.png';
import Avatar from 'components/common/Avatar';
import useApi from 'helpers/api';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProfileDropdown = () => {
  const api = useApi();

  const [userId, setUserId] = useState('');
  const [userPhoto, setUserPhoto] = useState({ url: '' });

  useEffect(() => {
    getUserIdAuthenticated();
  }, []);

  useEffect(() => {
    if (userId) getUserPhoto();
  }, [userId]);

  const getUserIdAuthenticated = async () => {
    let response = await api.agents.getUserIdAuthenticated();
    if (response) setUserId(response);
  };

  const getUserPhoto = async () => {
    let request = await api.agentPhoto.find(userId);
    if (request.ok) {
      const response = await request.json();
      setUserPhoto(response);
    }
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={userPhoto.url || userAvatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/user/profile">
            Meu Perfil
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/company/my-company">
            Dados da minha empresa
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as={Link}
            to={`${process.env.REACT_APP_BASE_URL}/logout`}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
