import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import useApi from 'helpers/api';
import { getDayName } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import RushHourChart from './RushHourChart';

const NavItem = ({ item, index }) => {
  return (
    <Nav.Item as="li" className={index !== 6 && 'border-bottom'}>
      <Nav.Link className="p-x1 mb-0" eventKey={item.toLowerCase()}>
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

NavItem.propTypes = {
  item: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

const TabItem = ({ percentage, count, data }) => {
  return (
    <>
      <Flex alignItems="center" className="gap-2 mb-4">
        <h4 className="text-primary mb-0">{percentage}%</h4>
        <SubtleBadge pill>
          <span>Total de tickets: {count}</span>
        </SubtleBadge>
      </Flex>
      <RushHourChart data={data} />
    </>
  );
};

TabItem.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.array.isRequired
};

const RushHour = ({ filters }) => {
  const [navItems] = useState([
    'DOM',
    'SEG',
    'TER',
    'QUA',
    'QUI',
    'SEX',
    'SAB'
  ]);

  const api = useApi();
  const [data, setData] = useState({});
  const [percentages, setPercentages] = useState([]);

  useEffect(() => {
    getData();
    getPercentage();
  }, [filters]);

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.overview.rushHour(queryParams);

    if (response.status) {
      setData([]);
    } else {
      const transformedData = transformData(response);
      setData(transformedData);
    }
  };

  const getPercentage = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.overview.ticketPercentagePerDayOfWeek(queryParams);

    setPercentages(response.status ? [] : response);
  };

  const transformData = (data) => {
    const groupedData = {};

    data.forEach(item => {
      const day = getDayName(item.dayOfWeek);
      if (!groupedData[day]) {
        groupedData[day] = [];
      }
      groupedData[day].push({
        hour: item.hour,
        count: item.count,
      });
    });

    return groupedData;
  };

  const getPercentageForDay = (day) => {
    const dayNumber = navItems.indexOf(day) + 1;
    const percentageObject = percentages.find(item => item.dayOfWeek === dayNumber);
    return percentageObject ? percentageObject.percentage : '0';
  };

  return (
    <Card className="mt-3">
      <FalconCardHeader
        title="Horário de Pico de Tickets por Dia da Semana"
        titleTag="h4"
        className="py-2 bg-body-tertiary"
      />
      <Card.Body className="ps-0 py-0">
        <Flex>
          <Tab.Container id="top-customer-tab" defaultActiveKey="seg">
            <Nav
              as="ul"
              className="nav-tabs tab-active-caret top-customers-tab border-0 border-end d-inline-block text-center"
            >
              {navItems.map((item, index) => (
                <NavItem item={item} index={index} key={index} />
              ))}
            </Nav>
            <Tab.Content className="ps-x1 pt-x1 w-100">
              {navItems.map((day, index) => (
                <Tab.Pane unmountOnExit eventKey={day.toLowerCase()} key={index}>
                  <TabItem
                    percentage={getPercentageForDay(day)}
                    count={data[day]?.reduce((sum, item) => sum + item.count, 0) || '0'}
                    data={data[day] || []}
                  />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default RushHour;
