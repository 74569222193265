import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

const DateFilterQueryItem = ({ title, onFilterChange, clearDate, isOpen }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (clearDate) {
      setStartDate('');
      setEndDate('');
      onFilterChange('');
    }
  }, [clearDate]);

  const formatDate = date => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    return `${year}-${month}-${day}`;
  };

  const handleToggleDatePicker = () => {
    setShowDatePicker(showDatePicker => !showDatePicker);
  };

  const onChange = dates => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
    onFilterChange(formatDate(start), formatDate(end));
  };

  return (
    <div className="position-relative">
      <div className="d-flex align-items-center">
        {startDate != '' && (
          <span
            className=" d-flex justify-content-center align-items-center bg-black rounded-circle text-light"
            style={{ width: 16, height: 16, fontSize: 12, fontWeight: 600 }}
          >
            ✓
          </span>
        )}
        <button
          onClick={handleToggleDatePicker}
          className="dropdown-toggle text-secondary bg-transparent border border-0"
          style={{ fontSize: 12 + 'px' }}
        >
          {title}
        </button>
      </div>

      {showDatePicker && isOpen && (
        <div
          className=" position-absolute bottom-1 end-1"
          style={{ zIndex: 10 }}
        >
          <DatePicker
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
      )}
    </div>
  );
};

export default DateFilterQueryItem;
