import Flex from "components/common/Flex";
import useApi from 'helpers/api';
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const TurnOffDattoIntegrationModal = ({ show, close, response }) => {
  const api = useApi();
  const [deleteAll, setDeleteAll] = useState(false);

  return (
    <>
      <Modal show={show} onHide={close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Desabilitar integração</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label className='mb-3'>
                Antes de desativar esta integração, escolha uma das opções abaixo.
              </Form.Label>
              <Form.Check
                type="radio"
                label="Guardar sites, tipos de dispositivos, dispositivos e mapeamentos armazenados pelo Datto RMM"
                value={deleteAll}
                checked={!deleteAll}
                onChange={() => setDeleteAll(false)} />
              <Form.Check
                type="radio"
                label="Excluir sites, tipos de dispositivos, dispositivos e mapeamentos armazenados pelo Datto RMM"
                value={deleteAll}
                checked={deleteAll}
                onChange={() => setDeleteAll(true)} />
            </Form.Group>

            <Form.Group as={Flex} justifyContent="end" className='w-100'>
              <Button variant="link" onClick={close} className='me-2'>Cancelar</Button>
              <Button variant="primary" onClick={() => response(deleteAll)}>Confirmar</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TurnOffDattoIntegrationModal;