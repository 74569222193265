import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

const CustomToggle = React.forwardRef(
  ({ children, onClick, showValueCount }, ref) => (
    <div className="d-flex align-items-center">
      {showValueCount > 0 && (
        <span
          className="d-flex justify-content-center align-items-center rounded-circle text-light bg-black"
          style={{ width: 16, height: 16, fontSize: 12, fontWeight: 600 }}
        >
          {showValueCount}
        </span>
      )}
      <button
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
        className="dropdown-toggle text-secondary bg-transparent border border-0"
        style={{ fontSize: 12 + 'px' }}
      >
        {children}
      </button>
    </div>
  )
);

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="w-100"
          placeholder="Pesquisar"
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            child =>
              !value || child.props.children.toLowerCase().includes(value.toLowerCase())
          )}
        </ul>
      </div>
    );
  }
);

const FilterQueryItem = ({
  title,
  options,
  onFilterChange,
  selectedValues,
  isOpen,
  onToggle
}) => {
  const handleValueChange = event => {
    const { dataset } = event.target;
    const { value } = dataset;
    const newSelectedValues = [...selectedValues];

    if (newSelectedValues.includes(value)) {
      const valueIndex = newSelectedValues.indexOf(value);
      newSelectedValues.splice(valueIndex, 1);
    } else {
      newSelectedValues.push(value);
    }

    onFilterChange(newSelectedValues);
  };

  return (
    <Dropdown show={isOpen} onToggle={onToggle} autoClose={false}>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
        showValueCount={selectedValues.length}
      >
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {selectedValues.length > 0 && (
          <button
            className="w-100 d-flex justify-content-center bg-transparent text-danger border-0"
            onClick={() => onFilterChange([])}
          >
            Remover filtro
          </button>
        )}
        {options.map((option, i) => (
          <Dropdown.Item
            key={i}
            data-value={option.value.toString()}
            onClick={handleValueChange}
            active={selectedValues.includes(option.value.toString())}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilterQueryItem;
