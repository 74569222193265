import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const CategoryRegistrationModal = ({ showModal, handleClose, handleSubmit, data }) => {
  const api = useApi();
  const [validated, setValidated] = useState(false);
  const serviceCatalog = data;
  const [body, setBody] = useState({
    name: '',
    serviceCatalog: { id: null }
  });
  const [serviceCatalogs, setServiceCatalogs] = useState([]);
  const form = useRef(null);

  useEffect(() => {
    const getServiceCatalogs = async () => {
      let response = await api.serviceCatalogs.list();
      setServiceCatalogs(response.content);
    };
    getServiceCatalogs();
  }, []);

  useEffect(() => {
    if(showModal) {
      setBody({ name: '', serviceCatalog: null });
      setValidated(false);
    }
  }, [showModal]);

  const onSubmit = e => {
    if(form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({ name: body.name, serviceCatalog: {id : serviceCatalog.id} });
      setBody({ name: '', serviceCatalog: null });
      setValidated(false);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Nova categoria</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nome"
              autoFocus
              value={body.name}
              onChange={e => setBody(prevBody => ({ ...prevBody, name: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Catálogo de serviço <span className="text-danger">*</span></Form.Label>
            <Form.Select aria-label="Catálogos de serviço" className="flex-1 me-2" onChange={e => setBody(prevBody => ({ ...prevBody, serviceCatalog: { id: e.target.value }}))} required value={serviceCatalog.id} disabled>
              <option value="">Selecione uma opção</option>
              {serviceCatalogs.map((item, i) => (
                <option value={item.id} key={i}>{item.name}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Escolha uma opção!</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" type="submit" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryRegistrationModal;