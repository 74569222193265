import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useApi from 'helpers/api';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Nav, OverlayTrigger, ProgressBar, Row, Tab, Tooltip } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';

const InDepthItem = ({ item, status }) => {
  const progress = status === 'criados' ? item.progressbarWidth[0]
    : status === 'resolvidos' ? item.progressbarWidth[1]
      : status === 'reabertos' ? item.progressbarWidth[2]
        : item.progressbarWidth[3];

  const count = status === 'criados' ? item.count[0]
    : status === 'resolvidos' ? item.count[1]
      : status === 'reabertos' ? item.count[2]
        : item.count[3];

  return (
    <Row className="mt-2">
      <Col xs={3} sm={2} md={3} lg={3}>
        <p className="w-100 mb-0 fs-10 fw-semibold text-600 text-nowrap text-truncate">
          {item.name}
        </p>
      </Col>
      <Col xs={9} sm={10} md={9} lg={9} className="d-flex align-items-center">
        <OverlayTrigger overlay={<Tooltip>Total de tickets {status}: {count}</Tooltip>}>
          <ProgressBar
            now={progress}
            className="w-100 animated-progress-bar"
            style={{
              height: '6px',
              '--falcon-progressbar-width': `${progress}%`
            }}
          />
        </OverlayTrigger>
        <p className="mb-0 fs-10 ps-3 fw-semibold text-600">
          {progress}%
        </p>
      </Col>
    </Row>
  );
};

InDepthItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    progressbarWidth: PropTypes.array.isRequired
  }).isRequired,
  status: PropTypes.string.isRequired
};

const TabItem = ({ status, byServiceGroup, byPriority, byOrigin, byType }) => {
  return (
    <>
      <Row className="mx-0 border-bottom border-dashed">
        <Col md={6} className="p-x1 border-md-end border-bottom border-md-bottom-0 border-dashed">
          <h6 className="fs-10 mb-3">Tickets {status} por Grupo de Serviço</h6>
          {byServiceGroup.map((item, index) => (
            <InDepthItem item={item} key={index} status={status} />
          ))}
        </Col>
        <Col md={6} className="p-x1">
          <h6 className="fs-10 mb-3">Tickets {status} por Prioridade</h6>
          {byPriority.map((item, index) => (
            <InDepthItem item={item} key={index} status={status} />
          ))}
        </Col>
      </Row>
      <Row className="mx-0">
        <Col md={6} className="p-x1 border-md-end border-bottom border-md-bottom-0 border-dashed">
          <h6 className="fs-10 mb-3">Tickets {status} por Origem</h6>
          {byOrigin.map((item, index) => (
            <InDepthItem item={item} key={index} status={status} />
          ))}
        </Col>
        <Col md={6} className="p-x1">
          <h6 className="fs-10 mb-3">Tickets {status} por Tipo</h6>
          {byType.map((item, index) => (
            <InDepthItem item={item} key={index} status={status} />
          ))}
        </Col>
      </Row>
    </>
  );
};

TabItem.propTypes = {
  status: PropTypes.string.isRequired,
  byServiceGroup: PropTypes.array.isRequired,
  byPriority: PropTypes.array.isRequired,
  byOrigin: PropTypes.array.isRequired,
  byType: PropTypes.array.isRequired
};

const IndepthServiceDesk = ({ filters }) => {
  const api = useApi();

  const [data, setData] = useState({
    byServiceGroup: [],
    byPriority: [],
    byOrigin: [],
    byType: []
  });

  const fetchData = useCallback(async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const [serviceGroup, priority, origin, type] = await Promise.all([
      api.reports.overview.byServiceGroup(queryParams),
      api.reports.overview.byPriority(queryParams),
      api.reports.overview.byOrigin(queryParams),
      api.reports.overview.byType(queryParams)
    ]);

    setData({
      byServiceGroup: serviceGroup.status ? [] : serviceGroup,
      byPriority: priority.status ? [] : priority,
      byOrigin: origin.status ? [] : origin,
      byType: type.status ? [] : type
    });
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  return (
    <Card className="my-3">
      <Tab.Container defaultActiveKey="tickets-created">
        <Card.Header className="p-0 bg-body-tertiary overflow-hidden">
          <SimpleBarReact>
            <Nav className="nav-tabs tab-tickets-status flex-nowrap border-0">
              <Nav.Item className="text-nowrap">
                <Nav.Link
                  eventKey="tickets-created"
                  className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                >
                  <FontAwesomeIcon icon="ticket-alt" className="text-600" />
                  <h6 className="mb-0 text-600">Tickets Criados</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="text-nowrap">
                <Nav.Link
                  eventKey="tickets-resolved"
                  className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                >
                  <FontAwesomeIcon icon="check" className="text-600" />
                  <h6 className="mb-0 text-600">Tickets Concluídos</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="text-nowrap">
                <Nav.Link
                  eventKey="tickets-reopened"
                  className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                >
                  <FontAwesomeIcon icon="envelope-open-text" className="text-600" />
                  <h6 className="mb-0 text-600">Tickets Reabertos</h6>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="text-nowrap">
                <Nav.Link
                  eventKey="tickets-unsolved"
                  className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                >
                  <FontAwesomeIcon icon="exclamation-triangle" className="text-600" />
                  <h6 className="mb-0 text-600">Tickets em Aberto</h6>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </SimpleBarReact>
        </Card.Header>
        <Card.Body className="p-0">
          <Tab.Content>
            <Tab.Pane eventKey="tickets-created">
              <TabItem
                byServiceGroup={data.byServiceGroup}
                byPriority={data.byPriority}
                byOrigin={data.byOrigin}
                byType={data.byType}
                status="criados"
              />
            </Tab.Pane>
            <Tab.Pane eventKey="tickets-resolved">
              <TabItem
                byServiceGroup={data.byServiceGroup}
                byPriority={data.byPriority}
                byOrigin={data.byOrigin}
                byType={data.byType}
                status="resolvidos"
              />
            </Tab.Pane>
            <Tab.Pane eventKey="tickets-reopened">
              <TabItem
                byServiceGroup={data.byServiceGroup}
                byPriority={data.byPriority}
                byOrigin={data.byOrigin}
                byType={data.byType}
                status="reabertos"
              />
            </Tab.Pane>
            <Tab.Pane eventKey="tickets-unsolved">
              <TabItem
                byServiceGroup={data.byServiceGroup}
                byPriority={data.byPriority}
                byOrigin={data.byOrigin}
                byType={data.byType}
                status="não resolvidos"
              />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

IndepthServiceDesk.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
};

export default IndepthServiceDesk;