import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeSelect from 'components/common/TreeSelect';
import useApi from 'helpers/api';
import { priorities } from 'helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

const PropertiesFilteringForm = ({ data, handleSubmit }) => {
  const api = useApi();
  const [filters] = useState({ size: 100, includeInactives: false });
  const queryParams = new URLSearchParams(filters).toString();
  const [typesTicket, setTypesTicket] = useState({ content: [] });
  const [agents, setAgents] = useState([]);
  const [serviceGroups, setServiceGroups] = useState({ content: [] });
  const [serviceCatalogs, setServiceCatalogs] = useState([
    { categories: [{ subcategories: [] }] }
  ]);
  const [priority, setPriority] = useState('');
  const [agent, setAgent] = useState({ id: '' });
  const [serviceGroup, setServiceGroup] = useState({ id: '' });
  const [serviceCatalog, setServiceCatalog] = useState({ id: '' });
  const [category, setCategory] = useState({ id: '' });
  const [subcategory, setSubcategory] = useState({ id: '' });
  const [typeTicket, setTypeTicket] = useState({ id: '' });
  const [body, setBody] = useState({});
  const form = useRef(null);
  const [showBody, setShowBody] = useState(true);

  useEffect(() => {
    setBody({});
    setTypeTicket({ id: data.typeTicket ? data.typeTicket.id : '' });
    setPriority(data.priority ? data.priority.priority : '');
    setAgent({ id: data.agent ? data.agent.id : '' });
    setServiceGroup({ id: data.serviceGroup ? data.serviceGroup.id : '' });
    setServiceCatalog({ id: data.serviceCatalog ? data.serviceCatalog.id : '' });
    setCategory({ id: data.category ? data.category.id : '' });
    setSubcategory({ id: data.subcategory ? data.subcategory.id : '' });
    getTypesTicket();
    getServiceGroups();
  }, [data]);

  useEffect(() => {
    getAgents();
    getServiceCatalogs();
  }, [serviceGroup.id]);

  useEffect(() => {
    disableFields();
  }, [data.status]);

  const getTypesTicket = async () => {
    let response = await api.typeTickets.list(queryParams);
    setTypesTicket(response);
  };
  const getServiceGroups = async () => {
    let response = await api.serviceGroups.list(queryParams);
    setServiceGroups(response);
  };
  const getAgents = async () => {
    if (serviceGroup.id) {
      let response = await api.serviceGroupsAgents.list(serviceGroup.id);
      setAgents(response);
    }
  };
  const getServiceCatalogs = async () => {
    if (serviceGroup.id) {
      let response = await api.serviceCatalogs.listByServiceGroupTreeView(
        serviceGroup.id
      );
      setServiceCatalogs(response);
    }
  };

  const disableFields = () => {
    const fields = form.current.elements;
    if (isDisabledForm) {
      for (let i = 0; i < fields.length; i++) {
        fields[i].disabled = true;
      }
    } else {
      for (let i = 0; i < fields.length; i++) {
        fields[i].disabled = false;
      }
    }
  };

  const handleSetBody = (hasId, fieldName, value) => {
    hasId
      ? setBody(prevBody => ({ ...prevBody, [fieldName]: { id: parseInt(value) } }))
      : setBody(prevBody => ({ ...prevBody, [fieldName]: value }));
  };

  const handleSelectServiceCatalog = ids => {
    setServiceCatalog({ id: ids[0] });
    setCategory({ id: ids[1] });
    setSubcategory({ id: ids[2] });
    handleSetBody(true, 'serviceCatalog', ids[0]);
    handleSetBody(true, 'category', ids[1]);
    handleSetBody(true, 'subcategory', ids[2]);
  }

  const onSubmit = () => handleSubmit(body);

  const isDisabledForm = data.status && data.status.status == "COMPLETED" || data.status && data.status.status == "CLOSED";
  return (
    <Card>
      <div className="w-100 d-flex bg-100 card-header py-2 align-items-center justify-content-between" style={{ cursor: 'pointer' }} onClick={() => setShowBody(!showBody)}>
        <h6 className="m-0">Propriedades</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </div>
      {showBody &&
        <>
          <Card.Body>
            <Form ref={form}>
              <div className="mb-2 mt-n2">
                <Form.Label className="mb-1 fs-10">Tipo</Form.Label>
                <Form.Select size="sm" value={typeTicket.id} onChange={e => {
                  setTypeTicket({ id: e.target.value })
                  handleSetBody(true, 'typeTicket', e.target.value)
                }}>
                  {typesTicket.content.map(item => (
                    <option value={item.id} key={item.id + item.name}>{item.name}</option>
                  ))}
                </Form.Select>
              </div>
              <div className="mb-2">
                <Form.Label className="mb-1 mt-2 fs-10">Prioridade</Form.Label>
                <Form.Select size="sm" value={priority} onChange={e => {
                  setPriority(e.target.value)
                  handleSetBody(false, 'priority', e.target.value)
                }}>
                  {priorities.map((item, i) => (
                    <option value={item.value} key={i}>{item.name}</option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <Form.Label className="mb-1 mt-2 fs-10">Grupo de serviço</Form.Label>
                <Form.Select size="sm" value={serviceGroup.id} onChange={e => {
                  setServiceGroup({ id: e.target.value })
                  handleSetBody(true, 'serviceGroup', e.target.value)
                }}>
                  <option value=''>Selecione uma opção</option>
                  {serviceGroups.content.map((item, i) => (
                    <option value={item.id} key={item.name + i}>{item.name}</option>
                  ))}
                </Form.Select>
              </div>
              <div className="mb-2">
                <Form.Label className="mb-1 mt-2 fs-10">Agente</Form.Label>
                <Form.Select size="sm" value={agent.id} onChange={e => {
                  setAgent({ id: e.target.value })
                  handleSetBody(true, 'agent', e.target.value)
                }}>
                  <option value=''>Selecione uma opção</option>
                  {agents.map(item => (
                    <option value={item.id} key={item.phone + item.firstName}>{item.firstName}</option>
                  ))}
                </Form.Select>
              </div>
              <div className="mb-2">
                <Form.Label className="mb-1 mt-2 fs-10">Catálogo de serviço</Form.Label>
                <TreeSelect
                  data={serviceCatalogs}
                  onSelect={handleSelectServiceCatalog}
                  initialValue={[serviceCatalog.id, category.id, subcategory.id]}
                  size='sm'
                  disabled={isDisabledForm} />
              </div>
            </Form>
          </Card.Body>
          <Card.Footer className="border-top border-200 py-x1">
            <Button varient="primary" className="w-100" onClick={() => onSubmit()} disabled={isDisabledForm}>
              Salvar
            </Button>
          </Card.Footer>
        </>
      }
    </Card>
  );
};

export default PropertiesFilteringForm;