import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import DialogModal from "components/modals/DialogModal";
import TicketDescriptionEditModal from "components/modals/TicketDescriptionEditModal";
import AddNoteOffcanvas from "components/offcanvas/AddNoteOffcanvas";
import ForwardNoteOffcanvas from "components/offcanvas/ForwardNoteOffcanvas";
import ReplyNoteOffcanvas from "components/offcanvas/ReplyNoteOffcanvas";
import DOMPurify from "dompurify";
import useApi from 'helpers/api';
import { formatDateInFromNow } from "helpers/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button } from "react-bootstrap";

const TicketInfo = forwardRef(({ data, refreshData, refreshLogs }, ref) => {
  const api = useApi();
  const divDescription = useRef(null);
  const divDescriptionNote = useRef(null);

  const [notes, setNotes] = useState([]);
  const [error, setError] = useState({});
  const [selectedNote, setSelectedNote] = useState({});

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [showReplyNote, setShowReplyNote] = useState(false);
  const [showForwardNote, setShowForwardNote] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const sanitizedHtml = DOMPurify.sanitize(data.description);
  const isDisabledForm = data.status && data.status.status == "COMPLETED" || data.status && data.status.status == "CLOSED";

  useEffect(() => {
    getNotes();
  }, [data.id]);

  useEffect(() => {
    const imgs = divDescription.current.querySelectorAll('img');

    if (imgs) {
      imgs.forEach(img => {
        img.style.maxWidth = '100%';
        img.style.height = 'auto';
      });
    }
  }, [data.description]);

  useEffect(() => {
    if (notes.length > 0 && divDescriptionNote.current != null) {
      const imgs = divDescriptionNote.current.querySelectorAll('img');

      if (imgs) {
        imgs.forEach(img => {
          img.style.maxWidth = '100%';
          img.style.height = 'auto';
        });
      }
    }
  }, [notes]);


  useImperativeHandle(ref, () => ({
    getNotes: () => getNotes()
  }));

  const editDescription = async body => {
    let response = await api.tickets.edit(data.id, body);
    if (response.status) {
      refreshData();
      refreshLogs();
    } else {
      setError(response);
      setShowError(true);
    }
    handleCloseModalDescription();
  };

  const getNotes = async () => {
    if (data.id != undefined) {
      let response = await api.ticketNotes.list(data.id);
      if (response) setNotes(response);
    }
  };

  const deleteNote = async response => {
    if (response) {
      let response = await api.ticketNotes.delete(data.id, selectedNote.id);
      if (response.ok) {
        setShowSuccess(true);
        refreshLogs();
        getNotes();
      } else {
        setError(response);
        setShowError(true);
      }
      setShowDialog(false);
      setSelectedNote({});
    }
  };

  const handleAddNote = () => {
    setSelectedNote({});
    setShowAddNote(true);
  }

  const handleEditNote = item => {
    setSelectedNote(item);
    setShowAddNote(true);
  };

  const handleDeleteNote = item => {
    setSelectedNote(item);
    setShowDialog(true);
  };

  const handleCloseModalDescription = () => {
    setShowModal(false);
  };

  const isClosed = data.status && data.status.status === 'COMPLETED' || data.status && data.status.status === 'CLOSED';
  return (
    <>
      <AddNoteOffcanvas
        show={showAddNote}
        handleClose={() => setShowAddNote(false)}
        refreshNotes={() => getNotes()}
        refreshLogs={() => refreshLogs()}
        refreshData={() => refreshData()}
        data={selectedNote}
        ticket={data} />

      <ReplyNoteOffcanvas
        show={showReplyNote}
        handleClose={() => setShowReplyNote(false)}
        ticketId={data.id}
        contact={data.contactCustomer}
        refreshNotes={() => getNotes()}
        refreshLogs={() => refreshLogs()}
        refreshData={() => refreshData()} />

      <ForwardNoteOffcanvas
        show={showForwardNote}
        handleClose={() => setShowForwardNote(false)}
        ticketId={data.id}
        ticketSubject={data.subject}
        ticketDescription={data.description}
        refreshNotes={() => getNotes()}
        refreshLogs={() => refreshLogs()} />

      <DialogModal
        showModal={showDialog}
        handleClose={() => setShowDialog(false)}
        message="Tem certeza que deseja excluir a nota?"
        response={deleteNote} />

      <FailedRequest
        message={error.description}
        showAlert={showError}
        setShowAlert={(() => setShowError(false))} />

      <SuccessRequest
        message="Nota excluída com sucesso"
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <div>
        <TicketDescriptionEditModal
          showModal={showModal}
          data={data.description}
          handleClose={handleCloseModalDescription}
          handleSubmit={editDescription} />
        <div>
          <div className="d-flex align-items-center">
            <h6 style={{ fontSize: 16 }}>Descrição</h6>
            <Button size='sm' variant='link' className='text-600 py-0 ms-1 pb-2' onClick={() => setShowModal(true)} disabled={isDisabledForm}>
              <FontAwesomeIcon icon={faPencilAlt} size='sm' onClick={() => setShowModal(true)} />
            </Button>
          </div>
          <div
            ref={divDescription}
            style={{ textAlign: 'justify' }}
            className="pe-3 w-100 overflow-x-hidden rich-text-content"
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          <div className="d-flex justify-content-between align-items-center my-5 position-relative text-center">
            <hr className="top-50 border-300 my-0" style={{ width: '40%' }} />
            <span className="position-relative dark__bg-div-dark px-3 z-index-1">
              <Button
                size="sm"
                variant="outline-secondary"
                className="rounded-pill border-300 px-lg-5"
              >
                Comunicação
              </Button>
            </span>
            <hr className="top-100 border-300 my-0" style={{ width: '40%' }} />
          </div>
          {notes.map((item, i) => (
            <div
              key={i}
              className="w-100 mb-3">
              <div className="p-0">
                <Flex justifyContent="between" alignItems="center" className="px-3 py-2 w-100">
                  <div className="d-flex">
                    <FontAwesomeIcon icon={item.typeNote.icon} />
                    <h6 className="ms-3 mb-1">
                      {item.user != null ? item.user.firstName : item.sender} {item.typeNote.description} {item.typeNote.type == "NOTE" && item.typeView.description}
                    </h6>
                  </div>
                  <div className="d-flex">
                    {item.typeNote.type === "NOTE" &&
                      <div>
                        <IconButton
                          size="sm"
                          className="px-2"
                          variant="falcon-default"
                          icon="pencil-alt"
                          transform="shrink-5"
                          style={{ borderRadius: '4px 0 0 4px' }}
                          onClick={() => handleEditNote(item)}
                          disabled={isClosed} />

                        <IconButton
                          size="sm"
                          className="px-2 me-2"
                          variant="falcon-default"
                          icon="trash-alt"
                          transform="shrink-5"
                          style={{ borderRadius: '0 4px 4px 0' }}
                          onClick={() => handleDeleteNote(item)}
                          disabled={isClosed} />
                      </div>
                    }
                    {item.typeNote.type != "NOTE" && <p className="fs-10 px-3 py-2" style={{ width: '40%' }}>Para: {`<${item.recipients}>`}</p>}
                    <p className="w-auto text-nobreak fs-10 me-2 pt-2">{formatDateInFromNow(item.date)}</p>
                  </div>
                </Flex>
              </div>

              <div>
                <div ref={divDescriptionNote} className="overflow-x-hidden" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.note) }} />
                <hr />
              </div>
            </div>
          ))}
          <footer>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="reply"
              transform="shrink-2"
              iconAlign="middle"
              onClick={() => setShowReplyNote(true)}
              className="me-2"
              disabled={isClosed}
            >
              <span className="d-none d-md-inline-block ms-1">Responder</span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="file-alt"
              transform="shrink-2"
              iconAlign="middle"
              onClick={() => handleAddNote()}
              className="me-2"
              disabled={isClosed}
            >
              <span className="d-none d-md-inline-block ms-1">Adicionar nota</span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="share"
              transform="shrink-2"
              iconAlign="middle"
              onClick={() => setShowForwardNote(true)}
              className="me-2"
              disabled={isClosed}
            >
              <span className="d-none d-md-inline-block ms-1">Encaminhar</span>
            </IconButton>
          </footer>
        </div>
      </div >
    </>
  )
});

export default TicketInfo;