import FilterProvider from 'components/tickets/FilterProvider';
import 'helpers/initFA';
import AppProvider from 'providers/AppProvider';
import SelectedViewProvider from 'providers/SelectedViewProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppProvider>
      <FilterProvider>
        <SelectedViewProvider>
          <RouterProvider router={router} />
        </SelectedViewProvider>
      </FilterProvider>
    </AppProvider>
  </React.StrictMode>
);
