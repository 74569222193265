import { faHistory, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FailedRequest from 'components/alerts/FailedRequest';
import SuccessRequest from 'components/alerts/SuccessRequest';
import TimelineTicket from 'components/app/support-desk/tickets-preview/TimelineTicket';
import SoftBadge from 'components/common/SoftBadge';
import TicketSubjectEditModal from 'components/modals/TicketSubjectEditModal';
import useApi from 'helpers/api';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import TicketAppointments from './TicketAppointments';
import TicketAttachments from './TicketAttachments';
import TicketInfo from './TicketInfo';

const TicketDetails = forwardRef(({ data, refreshData }, ref) => {
  const api = useApi();
  const [error, setError] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showModalSubject, setShowModalSubject] = useState(false);
  const timeline = useRef();
  const files = useRef();
  const ticketInfo = useRef();

  useImperativeHandle(ref, () => ({
    getNewLogs: () => timeline.current.getNewLogs(),
    getFiles: () => files.current.getFiles(),
    getNotes: () => ticketInfo.current.getNotes()
  }));

  const editSubject = async body => {
    let response = await api.tickets.edit(data.id, body);
    if (response.status) {
      refreshData();
      timeline.current && timeline.current.getNewLogs();
    } else {
      setError(response);
      setShowError(true);
    }
    handleCloseModalSubject();
  };

  const handleCloseModalSubject = () => {
    setShowModalSubject(false);
  };

  const isDisabledForm = data.status && data.status.status == "COMPLETED" || data.status && data.status.status == "CLOSED";

  return (
    <>
      <SuccessRequest
        message="Cliente editado com sucesso"
        showAlert={isSuccess}
        setShowAlert={() => setIsSuccess(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showError}
        setShowAlert={(() => setShowError(false))} />
      <TicketSubjectEditModal
        showModal={showModalSubject}
        data={data.subject}
        handleClose={handleCloseModalSubject}
        handleSubmit={editSubject} />
      <Row className="g-3">
        <Col xxl={12}>
          <Card>
            <div className='w-100 d-flex align-items-center p-3'>
              <h5 className='me-1'>#{data.code} - {data.subject}</h5>
              <Button size='sm' variant='link' className='text-600' onClick={() => setShowModalSubject(true)} disabled={isDisabledForm}>
                <FontAwesomeIcon icon={faPencilAlt} size='sm' />
              </Button>
              <SoftBadge style={{ backgroundColor: data.status ? data.status.color : '' }}>
                {data.status ? data.status.description : ''}
              </SoftBadge>
            </div>
            <Tab.Container defaultActiveKey="basicData">
              <SimpleBar>
                <Card.Header className="p-0 border-bottom">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="basicData"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Detalhes</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="appointments"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon icon="clock" className="text-600" />
                        <h6 className="text-600 mb-0 ms-1">Apontamentos</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="attachments"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon icon="paperclip" className="text-600" />
                        <h6 className="text-600 mb-0 ms-1">Anexos</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="history"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon={faHistory}
                          className="text-600"
                        />
                        <h6 className="text-600 mb-0 ms-1">Atividades</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="basicData">
                    <TicketInfo data={data} refreshData={refreshData} refreshLogs={() => timeline.current.getNewLogs()} ref={ticketInfo} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="appointments">
                    <TicketAppointments
                      ticketStatus={data.status && data.status.status}
                      ticketId={data.id}
                      refreshLogs={() => timeline.current.getNewLogs()}
                      refreshData={() => refreshData()} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="attachments">
                    <TicketAttachments
                      ref={files}
                      ticketId={data.id}
                      ticketStatus={data.status && data.status.status}
                      refreshLogs={() => timeline.current.getNewLogs()} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="history">
                    <TimelineTicket ticketId={data.id} ref={timeline} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
});

export default TicketDetails;
