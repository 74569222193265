import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

export default () => {
  const responseType = 'code';
  const clientId = 'mspdesk-web';
  const state = uuid();
  const scopes = 'READ WRITE';
  const loginUrl = `${process.env.REACT_APP_BASE_URL}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&state=${state}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=${scopes}`;

  useEffect(() => {
    window.location.href = loginUrl;
  }, []);

  return <h1>Carregando...</h1>
};