import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Editor from "components/editor/Editor";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const TicketDescriptionEditModal = ({ showModal, data, handleClose, handleSubmit }) => {
  const [description, setDescription] = useState('');
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if (data) {
      setDescription(data);
      setValidated(false);
    }
  }, [data]);

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({ description: description });
      setValidated(false);
    }
  }

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar descrição</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Form.Group className="mb-3" controlId="editForm.Name">
              <Form.Label>Descrição*</Form.Label>
              <Editor value={value => setDescription(value)} data={data} />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira uma desrição.</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-default" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );
};

export default TicketDescriptionEditModal;