import LayoutReport from "../LayoutReport";
import ProductivityAnalysis from "./ProductivityAnalysis";

const ReportProductivity = () => {
  return (
    <LayoutReport>
      <ProductivityAnalysis />
    </LayoutReport>
  );
};

export default ReportProductivity;