import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import MaskedInput from "react-text-mask";

const AddressCustomerRegistrationModal = ({ showModal, handleClose, handleSubmit }) => {
  const [body, setBody] = useState({
    cep: '',
    name: '',
    neighborhood: '',
    state: '',
    city: '',
    street: '',
    number: '',
    complement: ''
  });
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if(showModal) {
      clearFields();
      setValidated(false);
    }
  }, [showModal]);

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      handleSubmit({
        cep: body.cep,
        name: body.name,
        neighborhood: body.neighborhood,
        state: body.state,
        city: body.city,
        street: body.street,
        number: body.number,
        complement: body.complement
      });
      clearFields();
    }
  };

  const formattCEP = cep => {
    return cep.replace('-', '');
  };

  const clearFields = () => {
    setBody({
      cep: '',
      name: '',
      neighborhood: '',
      state: '',
      city: '',
      street: '',
      number: '',
      complement: ''
    });
  };

  const searchCEP = async cep => {
    if (body.cep.length == 9) {
      let request = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      if (request.ok) {
        let response = await request.json();
        setBody(prevState => ({
          ...prevState,
          neighborhood: response.bairro,
          city: response.localidade,
          street: response.logradouro,
          state: response.uf,
          complement: response.complemento,
          cep: response.cep
        }));
      }
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Novo endereço</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Row className="mb-3">
              <Form.Group as={Col} md="3" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>CEP <span className="text-danger">*</span></Form.Label>
                <MaskedInput
                  mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                  guide={false}
                  required
                  type="text"
                  placeholder="CEP"
                  autoFocus
                  value={body.cep}
                  onChange={e => setBody(prevState => ({ ...prevState, cep: e.target.value }))}
                  onBlur={() => searchCEP(formattCEP(body.cep))}
                  pattern=".{9}"
                  render={(ref, props) => <Form.Control ref={ref} {...props} />}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">CEP obrigatório.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="7" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Logradouro</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Logradouro"
                  value={body.street}
                  onChange={e => setBody(prevState => ({ ...prevState, street: e.target.value }))}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira uma rua.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="agent.state" as={Col} md="2">
                <Form.Label>Número</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Número"
                  value={body.number}
                  onChange={e => setBody(prevState => ({ ...prevState, number: e.target.value }))}
                />

                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira um número.</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="5" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Cidade"
                  value={body.city}
                  onChange={e => setBody(prevState => ({ ...prevState, city: e.target.value }))}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira uma cidade.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="5" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Bairro"
                  value={body.neighborhood}
                  onChange={e => setBody(prevState => ({ ...prevState, neighborhood: e.target.value }))}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira um país.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Estado"
                  value={body.state}
                  onChange={e => setBody(prevState => ({ ...prevState, state: e.target.value }))}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira um estado.</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Complemento"
                  value={body.complement}
                  onChange={e => setBody(prevState => ({ ...prevState, complement: e.target.value }))}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Tipo de endereço"
                  value={body.name}
                  onChange={e => setBody(prevState => ({ ...prevState, name: e.target.value }))}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira o tipo do endereço.</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-default" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddressCustomerRegistrationModal;