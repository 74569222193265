import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { colors, getColor } from 'helpers/utils';
import React, { useEffect, useRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (data) => ({
  legend: {
    left: 'left',
    textStyle: {
      color: getColor('gray-600')
    }
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      center: window.innerWidth < 530 ? ['65%', '55%'] : ['50%', '55%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: getColor('gray-100'),
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      data: data && data.map((item, i) => ({
        value: item.value,
        name: item.name,
        itemStyle: {
          color: colors[i]
        }
      })),
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-1100') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

const TicketsByServiceGroupChart = ({ data }) => {
  const chartRef = useRef(null);

  const updateDimensions = () => {
    if (window.innerWidth < 530) {
      chartRef.current.getEchartsInstance().setOption({
        series: [
          {
            center: ['65%', '55%']
          }
        ]
      });
    }
    else
      chartRef.current.getEchartsInstance().setOption({
        series: [
          {
            center: ['50%', '55%']
          }
        ]
      });
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(data)}
      ref={chartRef}
      style={{ height: '20rem' }}
    />
  );
};

export default TicketsByServiceGroupChart;
