import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const CustomerDomainEditModal = ({ data, showModal, handleClose, handleSubmit }) => {
  const [body, setBody] = useState({
    domain: ''
  });
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  const onSubmit = e => {
    if(form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      handleSubmit({ domain: body.domain });
      setBody({ domain: '' });
    }
  };

  useEffect(() => {
    setBody(data);
  }, [data]);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar domínio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Domínio*</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Ex: dominio.com"
              autoFocus
              value={body.domain}
              onChange={e => setBody({ domain: e.target.value })}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um domínio.</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerDomainEditModal;