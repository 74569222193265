import IconButton from 'components/common/IconButton';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import TicketRegistrationOffcanvas from 'components/offcanvas/TicketRegistrationOffcanvas';
import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import ThemeControlDropdown from './ThemeControlDropdown';

const TopNavRightSideNavItem = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <TicketRegistrationOffcanvas
        show={show}
        handleClose={() => setShow(false)}
        preTicketData={{}}
        preTicketId={''}
        refreshPretickets={() => {}}
      />
      <Nav
        navbar
        className="navbar-nav-icons ms-auto flex-row align-items-center"
        as="ul"
      >
        <IconButton
          variant="primary"
          size="lg"
          icon="plus"
          transform="shrink-2"
          iconAlign="middle"
          onClick={() => setShow(true)}
          className="me-2"
        >
          <span className="d-none d-md-inline-block ms-1">Novo Ticket</span>
        </IconButton>
        <ThemeControlDropdown />
        {/* <CartNotification />
      <NotificationDropdown />
      <NineDotMenu /> */}
        <ProfileDropdown />
      </Nav>
    </>
  );
};

export default TopNavRightSideNavItem;
