import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import Loading from "components/loading/Loading";
import DialogModal from "components/modals/DialogModal";
import EmailBoxEditModal from "components/modals/EmailBoxEditModal";
import EmailBoxRegistrationModal from "components/modals/EmailBoxRegistrationModal";
import Pagination from "components/pagination";
import useApi from 'helpers/api';
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const EmailBoxRegistration = () => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [selectedEmailBox, setSelectedEmailBox] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [emailBoxes, setEmailBoxes] = useState({
    content: [
      {
        name: '',
        email: '',
        password: '',
        provider: '',
        imap: '',
        port: '',
        status: ''
      }
    ]
  });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: true
  });

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.emailBoxes.list(queryParams);
    if (response.content) setEmailBoxes(response);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const handleCloseModalRegister = () => {
    setShowModal(false);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  }

  const handleCloseDialog = () => {
    setShowDialog(false);
  }

  const handleEdit = item => {
    setSelectedEmailBox(item);
    setShowModalEdit(true);
  };

  const handleDelete = item => {
    setSelectedEmailBox(item);
    setShowDialog(true);
  };

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'email',
      header: 'E-mail',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { status } = rowData.row.original;
        return (
          status == "ACTIVE"
            ? <span className="text-success">Ativo</span>
            : status == "UNAUTHORIZED"
              ? <span className="text-warning">Não autorizado</span>
              : <span className="text-danger">Inativo</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <>
            <button className="btn btn-link btn-sm" onClick={() => handleEdit(item)}>
              <FontAwesomeIcon icon={faPen} size="sm" />
            </button>
            <button className="btn btn-link btn-sm" onClick={() => handleDelete(item)} disabled={item.isAdmin}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </button>
          </>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: emailBoxes.content,
    columns
  });

  const createEmailBox = async body => {
    setIsLoading(true);
    const response = await api.emailBoxes.create(body);
    if (!response.userMessage) {
      getData();
      setSuccess("Caixa de email criada com sucesso");
      setShowAlertSuccess(true);
    } else {
      setError(response);
      setShowAlertError(true);
    }
    handleCloseModalRegister();
    setIsLoading(false);
  };

  const edit = async bodyChange => {
    const response = await api.emailBoxes.edit(selectedEmailBox.id, bodyChange);
    if (response.status) {
      getData();
      setSuccess("Caixa de email editada com sucesso");
      setShowAlertSuccess(true);
    } else {
      setError(response);
      setShowAlertError(true);
    }
    handleCloseModalEdit();
  }

  const deleteEmailBox = async response => {
    if (response) {
      let request = await api.emailBoxes.delete(selectedEmailBox.id);
      if (request.ok) {
        getData();
        setSuccess("Caixa de email excluída com sucesso");
        setShowAlertSuccess(true);
      } else {
        setError(request);
        setShowAlertError(true);
      }
      handleCloseDialog();
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <SuccessRequest message={success} showAlert={showAlertSuccess} setShowAlert={() => setShowAlertSuccess(false)} />
      <FailedRequest message={error.description} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Caixas de E-mail">
          <div className="w-100 d-flex justify-content-between">
            <Button type="button" variant="primary" size="sm" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Button>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <EmailBoxEditModal
            showModal={showModalEdit}
            data={selectedEmailBox}
            handleClose={handleCloseModalEdit}
            handleSubmit={edit} />

          <EmailBoxRegistrationModal
            showModal={showModal}
            handleClose={handleCloseModalRegister}
            handleSubmit={createEmailBox} />

          <DialogModal
            message="Tem certeza que deseja excluir esta caixa de e-mail?"
            showModal={showDialog}
            handleClose={handleCloseDialog} response={deleteEmailBox} />

          <Row className="justify-content-end g-0">
            <Col className="col-auto px-3">
              <Form.Select size="sm" className="mb-3 rounded-pill" onChange={e => setFilters(prevFilters => ({ ...prevFilters, includeInactives: e.target.value }))}>
                <option value="true">Filtro por status</option>
                <option value="true">Todos</option>
                <option value="false">Somente ativos</option>
              </Form.Select>
            </Col>
          </Row>

          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }} />
          </AdvanceTableProvider>
          <Pagination
            data={emailBoxes}
            filters={filters}
            setFilters={setFilters} />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>

  );
};

export default EmailBoxRegistration;