import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { colorsSecondary, getColor, rgbaColor } from 'helpers/utils';
import React from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data) => ({
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-900') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
  },
  xAxis: {
    splitLine: { show: false },
    splitArea: { show: false },
    data: data && data.map(item => item.name),
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      formatter: value => value.substring(0, 4),
    },
    splitLine: {
      show: false
    }
  },
  barWidth: '20px',
  yAxis: {
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLabel: {
      color: getColor('gray-600')
    }
  },
  series: [
    {
      type: 'bar',
      name: 'Total',
      data: data && data.map((item, i) => ({
        value: item.value,
        itemStyle: {
          color: colorsSecondary[i]
        }
      })),
      showSymbol: false,
      symbol: 'circle',
      smooth: false,
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      }
    }
  ],
  grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
});

const TicketsByCustomerChart = ({ data }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(data)}
      style={{ height: 250 }}
    />
  );
};

export default TicketsByCustomerChart;
