import { faInfoCircle, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import Flex from "components/common/Flex";
import CustomPropertyRegistrationModal from "components/modals/CustomPropertyRegistrationModal";
import DialogModal from "components/modals/DialogModal";
import useApi from 'helpers/api';
import { dattoDeviceProperties } from "helpers/utils";
import { useEffect, useState } from "react";
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";

const AssetTypeProperties = ({ assetTypeId, hasIntegration }) => {
  const api = useApi();
  const [properties, setProperties] = useState([]);
  const [mappingCache, setMappingCache] = useState({}); // Cache local para armazenar mapeamentos
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [selectedProperty, setSelectedProperty] = useState({});

  useEffect(() => {
    getProperties();
  }, [assetTypeId, success]);

  const getProperties = async () => {
    const response = await api.assetTypes.properties.list(assetTypeId);
    if (Array.isArray(response)) {
      setProperties(response);
    }
  };

  const deleteProperty = async (response) => {
    if (response) {
      const request = await api.assetTypes.properties.delete(assetTypeId, selectedProperty.id);

      if (request.ok) {
        setSuccess("Propriedade excluída com sucesso");
        setShowSuccess(true);
        setSelectedProperty(null);
      } else {
        setError(request);
        setShowError(true);
      }
      setShowDialog(false);
    }
  };

  // Função para verificar se já existe um mapeamento
  const checkIfThereIsMapping = async (assetPropertyId) => {
    const response = await api.assetTypes.properties.dattoFieldMapping.find(assetTypeId, assetPropertyId);

    setMappingCache(prevCache => ({
      ...prevCache,
      [assetPropertyId]: response
    }));

    return response;
  };

  const mapFieldToDatto = async (assetPropertyId, targetField) => {
    const body = { targetField };
    const response = await api.assetTypes.properties.dattoFieldMapping.map(assetTypeId, assetPropertyId, body);

    if (response.ok) {
      setSuccess("Campo mapeado com sucesso");
      setShowSuccess(true);
    } else {
      setError(response);
      setShowError(true);
    }
  };

  const unmapFieldFromDatto = async (assetPropertyId) => {
    const response = await api.assetTypes.properties.dattoFieldMapping.unmap(assetTypeId, assetPropertyId);

    if (response.ok) {
      setSuccess("Campo desmapeado com sucesso");
      setShowSuccess(true);
    } else {
      setError(response);
      setShowError(true);
    }
  };

  return (
    <>
      <DialogModal
        message="Tem certeza que deseja excluir a propriedade customizada?"
        showModal={showDialog}
        handleClose={() => setShowDialog(false)}
        response={deleteProperty}
      />
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message={success}
      />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error.userMessage}
      />
      <CustomPropertyRegistrationModal
        show={showModal}
        close={() => setShowModal(false)}
        refresh={() => getProperties()}
        assetTypeId={assetTypeId}
        data={selectedProperty || null}
        setData={setSelectedProperty}
      />
      <Flex className="w-100 my-3" justifyContent="between">
        <h5 className="m-0">Propriedades customizáveis</h5>
        <Button
          type="button"
          variant="primary"
          size="sm"
          onClick={() => {
            setShowModal(true);
            setSelectedProperty(null);
          }}>
          <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
          Novo
        </Button>
      </Flex>

      <Table hover className="fs-10 mb-0">
        <thead className="text-nowrap align-middle">
          <tr>
            <th className={`text-900 ${!hasIntegration && ' w-75'}`}>Nome</th>
            {hasIntegration && (
              <>
                <th className="text-900">
                  <span className="me-1">Prop. Disp. Datto RMM</span>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        Mapeie sua propriedade com uma propriedade de dispositivo do Datto RMM para que os valores sejam preenchidos automaticamente na criação de um novo ativo (opcional).
                      </Tooltip>
                    }>
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                    </span>
                  </OverlayTrigger>
                </th>
              </>
            )}
            <th className="text-900">Tipo</th>
            <th className="text-900">Obrigatório</th>
            <th className="text-900"></th>
          </tr>
        </thead>
        <tbody>
          {properties.map(property => (
            <tr key={property.id}>
              <td>{property.name}</td>
              {hasIntegration && (
                <td>
                  <IntegrationField
                    id={property.id}
                    dataType={property.dataType}
                    checkIfThereIsMapping={checkIfThereIsMapping}
                    mapFieldToDatto={mapFieldToDatto}
                    unmapFieldFromDatto={unmapFieldFromDatto}
                  />
                </td>
              )}
              <td>{property.dataType?.value}</td>
              <td>
                {property.required ? (
                  <span className="text-success">Sim</span>
                ) : (
                  <span className="text-danger">Não</span>
                )}
              </td>
              <td>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => {
                    setSelectedProperty(property);
                    setShowModal(true);
                  }}>
                  <FontAwesomeIcon icon={faPen} size="sm" />
                </Button>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => {
                    setShowDialog(true);
                    setSelectedProperty(property);
                  }}>
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const IntegrationField = ({ id, dataType, checkIfThereIsMapping, mapFieldToDatto, unmapFieldFromDatto }) => {
  const dataTypeValue = dataType?.dataType;
  const [body, setBody] = useState({
    assetPropertyId: id,
    targetField: '',
    mapId: ''
  });

  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    const fetchMapping = async () => {
      if (!isFetched) {
        const response = await checkIfThereIsMapping(id);

        if (response && response.id) {
          setBody(prevBody => ({
            ...prevBody,
            targetField: response.targetField,
            mapId: response.id
          }));
        }

        setIsFetched(true);
      }
    };

    fetchMapping();
  }, [id, isFetched]);

  const handleChange = async e => {
    const newValue = e.target.value;
    setBody(prevBody => ({
      ...prevBody,
      targetField: newValue
    }));

    if (newValue) {
      await mapFieldToDatto(id, newValue);
    } else {
      await unmapFieldFromDatto(id);
    }
  };

  return (
    <Form.Select value={body.targetField} onChange={handleChange}>
      <option value="" key="" className="text-danger">
        Não mapeado
      </option>
      {dattoDeviceProperties
        .filter(property => property.dataType === dataTypeValue)
        .map(property => (
          <option value={property.value} key={property.value}>
            {property.name}
          </option>
        ))}
    </Form.Select>
  );
};


export default AssetTypeProperties;
