import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({children}) => {
  const isAuthenticated = !!Cookies.get('token');
  if(!isAuthenticated) {
    return <Navigate to='/login' />
  }

  return children;
};

export default ProtectedRoute;