import { faInfoCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import Editor from "components/editor/Editor";
import Loading from "components/loading/Loading";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";

const ForwardNoteOffcanvas = ({ show, handleClose, ticketId, ticketSubject, ticketDescription, refreshNotes, refreshLogs }) => {
  const api = useApi();
  const form = useRef(null);
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState({
    note: '',
    type: 'PUBLIC',
    recipients: '',
    subject: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      handleSetBody('recipients', '');
      setValidated(false);
    }
  }, [show]);

  useEffect(() => {
    handleSetBody('subject', `Fwd: ${ticketSubject}`);
  }, [ticketSubject]);

  useEffect(() => {
    handleSetBody('note', ticketDescription);
  }, [ticketDescription]);

  const onSubmit = async e => {
    setLoading(true);
    if (form.current.checkValidity() === false || body.note.trim() === '' || body.note === '<p><br></p>') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response = await api.ticketNotes.forward(ticketId, body);
      if (response.id) {
        setShowSuccess(true);
        refreshLogs();
        refreshNotes();
      } else {
        setError(response);
        setShowError(true);
      }
      clearFields();
      handleClose();
    }
    setLoading(false);
  };

  const handleSetBody = (fieldName, fieldValue) => {
    setBody(prevBody => ({ ...prevBody, [fieldName]: fieldValue }));
  };

  const clearFields = () => {
    setBody({
      note: '',
      type: 'PUBLIC',
      recipients: '',
      subject: ''
    });
  };

  return (
    <>
      {loading && <Loading />}
      <SuccessRequest
        message="Nota adicionada com sucesso"
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <Offcanvas show={show} onHide={handleClose} placement='end' style={{ minWidth: '45%', maxWidth: '90%' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Encaminhar</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form ref={form} validated={validated}>
            <div className="mb-2 mt-n2">
              <Form.Label className="mb-1 me-1 fs-10">Para <span className="text-danger">*</span></Form.Label>
              <OverlayTrigger overlay={<Tooltip>Separe os e-mails por ponto e vírgula para enviar para mais de uma pessoa</Tooltip>}>
                <span><FontAwesomeIcon icon={faInfoCircle} /></span>
              </OverlayTrigger>
              <Form.Control
                type="text"
                value={body.recipients}
                required
                onChange={e => handleSetBody('recipients', e.target.value)} />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um ou mais destinatário.</Form.Control.Feedback>
            </div>
            <div className="mb-2">
              <Form.Label className="mb-1 fs-10">Assunto <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                value={body.subject}
                placeholder="Assunto"
                required
                onChange={e => handleSetBody('subject', e.target.value)} />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um assunto.</Form.Control.Feedback>
            </div>
            <div className="mb-2">
              <Form.Label className="mb-1 fs-10">Descrição <span className="text-danger">*</span></Form.Label>
              <Editor value={value => handleSetBody('note', value)} data={body.note} />
            </div>
          </Form>
          <br /><br />

          <footer className="fixed-bottom p-2 d-flex justify-content-end">
            <Button variant="falcon-default" type="submit" className="me-2" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" type="submit" onClick={onSubmit}>
              <FontAwesomeIcon icon={faSave} className="me-2" />
              Salvar
            </Button>
          </footer>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ForwardNoteOffcanvas;