import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

const ProfileDetails = () => {
  const api = useApi();

  const [userId, setUserId] = useState('');
  const [myProfile, setMyProfile] = useState({});

  useEffect(() => {
    getUserIdAuthenticated();
  }, []);

  useEffect(() => {
    if (userId)
      getData();
  }, [userId]);

  const getData = async () => {
    let response = await api.agents.myProfile(userId);
    setMyProfile(response);
  };

  const getUserIdAuthenticated = async () => {
    let response = await api.agents.getUserIdAuthenticated();
    if (response)
      setUserId(response);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Detalhes do perfil</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="g-3">
          <Col md={12}>
            <h6>Nome completo</h6>
            <p className="fs-10 mb-0 text-600">{myProfile.firstName} {myProfile.lastName}</p>
          </Col>
          <Col md={12}>
            <h6>E-mail</h6>
            <p className="fs-10 mb-0 text-600">{myProfile.email}</p>
          </Col>
          <Col md={12}>
            <h6>Telefone</h6>
            <p className="fs-10 mb-0 text-600">{myProfile.phone}</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProfileDetails;