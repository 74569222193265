import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import useApi from 'helpers/api';
import PropTypes from 'prop-types';
import { useSelectedView } from 'providers/SelectedViewProvider';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AllTicketsHeader = ({
  selectedRowIds,
  handleSetFilters,
  showPreMadeFilters,
  setShowPreMadeFilters,
  selectedItems,
  typeView,
  setTypeView,
  toggleViewFilter,
  setToggleViewFilter,
  clearFilters
}) => {
  const api = useApi();
  const [subject, setSubject] = useState('');
  const [serviceGroups, setServiceGroups] = useState([]);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState('');

  const [defaultFilters, setDefaultFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const { selectedViewName, setSelectedViewName } = useSelectedView();

  useEffect(() => {
    getDefaultFilters();
    getSelectedFilter();
  }, []);

  const getDefaultFilters = async () => {
    const response = await api.defaultFilters.list();
    response && setDefaultFilters(response);
  };

  const getSelectedFilter = async () => {
    const response = await api.defaultFilters.getByUser();
    if (response.id) {
      if (defaultFilters.map(item => item.name).includes(selectedViewName)) {
        setSelectedViewName(response.name);
        setSelectedFilter(response.id);
      }
    }
  };

  const handleFilter = ({ id, name }) => {
    setSelectedFilter(id);
    clearFilters();
    setSelectedViewName(name);
    handleSetFilters('defaultFilter', id);
    handleSetFilters('serviceGroup', selectedServiceGroup);
  };

  useEffect(() => {
    getServiceGroups();
  }, []);

  useEffect(() => {
    handleSetFilters('serviceGroup', selectedServiceGroup);
    handleSetFilters('page', 0);
  }, [selectedServiceGroup]);

  const handleSearchClick = () => {
    handleSetFilters('subject', subject);
  };

  const getServiceGroups = useCallback(async () => {
    const response = await api.serviceGroups.listByUser();
    setServiceGroups(response || []);
  }, []);

  return (
    <>
      <div className="w-100 d-flex justify-content-between">
        <Row className="flex-between-center gy-2 px-x1">
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle
                variant="falcon-default"
                size="sm"
                className="dropdown-caret-none"
              >
                <FontAwesomeIcon icon="sliders-h" className='me-2' />
                {selectedViewName}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {defaultFilters.map((filter, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleFilter(filter)}
                    active={filter.id === selectedFilter}
                    className="text-capitalize cursor-pointer"
                    as={Flex}
                    justifyContent="between"
                  >
                    {filter.name}
                    {filter.id === selectedFilter && (
                      <FontAwesomeIcon icon="check" transform="down-4 shrink-4" />
                    )}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col className='ps-0'>
            <Form.Select
              size="sm"
              className='rounded-pill'
              value={selectedServiceGroup}
              onChange={e => setSelectedServiceGroup(e.target.value)}>
              <option value=''>Selecione um grupo</option>
              {serviceGroups.map((item, i) => (
                <option value={item.id} key={i}>{item.name}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <div className="border-bottom border-200 my-3"></div>
        <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
          <div
            className="bg-300 mx-3 d-none d-lg-block d-xl-none"
            style={{ width: '1px', height: '29px' }}
          ></div>
          {(selectedRowIds && Object.keys(selectedRowIds).length > 0) ||
            (selectedItems && selectedItems.length > 0) ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="refund">Refund</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="orders-actions" className='w-100 d-flex justify-content-end'>
              <InputGroup className="pe-2 position-relative input-search-width">
                <FormControl
                  size="sm"
                  id="search"
                  type="search"
                  className="shadow-none"
                  placeholder="Buscar por assunto"
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                />
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="border-300 hover-border-secondary"
                  onClick={handleSearchClick}
                >
                  <FontAwesomeIcon icon="search" className="fs-10" />
                </Button>
              </InputGroup>
              <Button size='sm' className="me-2" variant='falcon-default' as={Link} to="/pre-tickets">Pré-tickets</Button>
              <Dropdown
                align="end"
                className="btn-reveal-trigger d-inline-block me-2"
              >
                <Dropdown.Toggle split variant="falcon-default" size="sm">
                  <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block me-1">
                    Layout
                  </span>
                  <FontAwesomeIcon icon="chevron-down" transform="shrink-2" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="border py-0">
                  <div className="py-2">
                    <Link
                      className={classNames('dropdown-item', {
                        active: typeView === 0
                      })}
                      onClick={() => setTypeView(0)}
                    >
                      Modo lista
                    </Link>
                    <Link
                      className={classNames('dropdown-item', {
                        active: typeView === 1
                      })}
                      onClick={() => setTypeView(1)}
                    >
                      Modo cartão
                    </Link>
                    <Link
                      className={classNames('dropdown-item', {
                        active: typeView === 2
                      })}
                      onClick={() => setTypeView(2)}
                    >
                      Modo kanban
                    </Link>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon='filter'
                transform="shrink-3"
                iconAlign="middle"
                onClick={() => setToggleViewFilter(!toggleViewFilter)}
              >
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                  Filtro
                </span>
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

AllTicketsHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  handleSetFilters: PropTypes.func,
  handleShow: PropTypes.func,
  layout: PropTypes.string,
  selectedItems: PropTypes.array,
  handleTicketsSearch: PropTypes.func
};

export default AllTicketsHeader;