import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { formatValue, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import { useAppContext } from 'providers/AppProvider';
import React, { forwardRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (getThemeColor, data, isDark) => ({
  color: [
    getThemeColor('primary'),
    '#CDFC73',
    isDark ? '#229BD2' : '#73D3FE',
    isDark ? '#195979' : '#A9E4FF'
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('gray-900') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: function (params) {
      const { seriesName, value } = params;
      const hours = Math.floor(value);
      const minutes = Math.round((value - hours) * 60);
      return `${seriesName}: ${hours}h ${minutes}m`;
    }
  },
  legend: {
    show: false
  },
  xAxis: {
    data: data.map(item => formatValue(item.date)),
    splitLine: { show: false },
    splitArea: { show: false },

    axisLabel: {
      color: getThemeColor('gray-600'),
      margin: 8,
    },

    axisLine: {
      lineStyle: {
        color: getThemeColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: {
      show: false
    }
  },
  yAxis: {
    splitLine: {
      lineStyle: {
        color: getThemeColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLabel: {
      color: getThemeColor('gray-600'),
      formatter: value => `${value} hr`
    },
    position: 'right'
  },
  series: [
    {
      name: 'Interno',
      type: 'bar',
      stack: 'one',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getThemeColor('gray-1100'), 0.3),
        }
      },
      data: data.map(item => item.internalHours),
    },
    {
      name: 'Externo',
      type: 'bar',
      stack: 'one',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getThemeColor('gray-1100'), 0.3)
        }
      },
      data: data.map(item => item.externalHours)
    }
  ],

  barWidth: '15px',
  grid: {
    top: '8%',
    bottom: 18,
    left: 0,
    right: 2,
    containLabel: true
  }
});


const HoursIndicatedChart = forwardRef(({ data }, ref) => {
  const { config, getThemeColor } = useAppContext();
  const { isDark } = config;

  return (
    <ReactEChartsCore
      echarts={echarts}
      ref={ref}
      option={getOption(getThemeColor, data, isDark)}
      style={{ height: '21rem' }}
    />
  );
});

HoursIndicatedChart.propTypes = {
  data: PropTypes.array.isRequired
};

export default HoursIndicatedChart;
