import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Pagination from 'components/pagination';
import useApi from 'helpers/api';
import { formatDateTime, formatTime } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';
import ExportMenu from './ExportMenu';

const columns = [
  {
    accessorKey: 'ticketCode',
    header: 'Ticket',
    meta: {
      headerProps: { className: 'text-900 fs-10 ps-2' },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2',
      },
    },
    cell: rowData => {
      const { ticketId, ticketCode, ticketSubject, ticketStatus } = rowData.row.original;
      return (
        <h6 className='mb-0' style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Link
            to={`/tickets/${ticketId}`}
            target='_blank'
            className="fs-10 fw-700">
            <SoftBadge style={{ backgroundColor: ticketStatus?.color }} className="me-2 fs-11">
              {ticketStatus?.description}
            </SoftBadge>
            #{ticketCode}
            {' '}
            {ticketSubject}
          </Link>
        </h6>
      );
    }
  },
  {
    accessorKey: 'typeService',
    header: 'Tipo',
    meta: {
      headerProps: { className: 'text-900' },
    },
    cell: rowData => {
      const { typeService } = rowData.row.original;
      return <p>{typeService && typeService.description}</p>
    }
  },
  {
    accessorKey: 'description',
    header: 'Descrição',
    meta: {
      headerProps: { className: 'text-900' },
    },
    cell: rowData => {
      const { description } = rowData.row.original;
      return (
        <OverlayTrigger overlay={<Tooltip>{description}</Tooltip>}>
          <p style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {description}
          </p>
        </OverlayTrigger>
      );
    }
  },
  {
    accessorKey: 'date',
    header: 'Data',
    meta: {
      headerProps: { className: 'text-900' },
    },
    cell: rowData => {
      const { date } = rowData.row.original;
      return <p>{formatDateTime(date)}</p>
    }
  },
  {
    accessorKey: 'timeSpent',
    header: 'Horas trabalhadas',
    meta: {
      headerProps: { className: 'text-900' },
    },
    cell: rowData => {
      const { timeSpent } = rowData.row.original;
      return <p>{formatTime(timeSpent)}</p>
    }
  },
  {
    accessorKey: 'agent',
    header: 'Agente',
    meta: {
      headerProps: { className: 'text-900' },
    },
    cell: rowData => {
      const { user } = rowData.row.original;
      return <p>{user.firstName}</p>
    }
  }
];

const HoursIndicatedByAgentModal = ({
  show,
  close,
  tickets,
  filters,
  setFilters,
  agentId
}) => {
  const api = useApi();

  const table = useAdvanceTable({
    data: tickets?.content,
    columns
  });

  const downloadFile = async (format, agentId, filters, fileName) => {
    try {
      const queryParams = new URLSearchParams(filters).toString();
      let response;

      switch (format) {
        case 'xlsx':
          response = await api.reports.productivity.export.hoursByAgent.toXLSX(agentId, queryParams);
          break;
        case 'csv':
          response = await api.reports.productivity.export.hoursByAgent.toCSV(agentId, queryParams);
          break;
        case 'pdf':
          response = await api.reports.productivity.export.hoursByAgent.toPDF(agentId, queryParams);
          break;
        default:
          throw new Error('Formato de arquivo desconhecido');
      }

      if (!response.ok) {
        throw new Error(`Erro ao exportar o arquivo ${format.toUpperCase()}: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Limpa o URL do objeto e remove o link do DOM
      window.URL.revokeObjectURL(url);
      link.remove();

    } catch (error) {
      console.error(error.message);
    }
  };

  const fileName = 'horas_apontadas';
  const downloadXLSX = () => downloadFile('xlsx', agentId, filters, `${fileName}.xlsx`);
  const downloadCSV = () => downloadFile('csv', agentId, filters, `${fileName}.csv`);
  const downloadPDF = () => downloadFile('pdf', agentId, filters, `${fileName}.pdf`);

  return (
    <Modal show={show} onHide={close} size='xl'>
      <Modal.Header closeButton>
        <Flex justifyContent="between" className="w-100">
          <Modal.Title>Horas apontadas por agente</Modal.Title>
          <ExportMenu
            downloadXLSX={downloadXLSX}
            downloadCSV={downloadCSV}
            downloadPDF={downloadPDF} />
        </Flex>
      </Modal.Header>
      <Modal.Body>
        {tickets?.content ?
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              headerClassName="bg-body-tertiary text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                borderless: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableProvider>
          : <p>Carregando...</p>}
      </Modal.Body>
      <Modal.Footer>
        <Pagination
          data={tickets}
          filters={filters}
          setFilters={setFilters} />
      </Modal.Footer>
    </Modal>
  );
};

export default HoursIndicatedByAgentModal;