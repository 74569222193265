import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileDetails from './ProfileDetails';
import ProfileOthers from './ProfileOthers';

const ProfileUser = () => {

  return (
    <>
      <Row>
        <Col lg={8}>
          <ProfileOthers />

        </Col>
        <Col lg={4}>
          <ProfileDetails />
        </Col>
      </Row>
    </>
  );
};

export default ProfileUser;