import { faPause, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import Loading from 'components/loading/Loading';
import DialogModal from 'components/modals/DialogModal';
import TicketPauseRegistrationModal from 'components/modals/TicketPauseRegistrationModal';
import AddNoteOffcanvas from 'components/offcanvas/AddNoteOffcanvas';
import ForwardNoteOffcanvas from 'components/offcanvas/ForwardNoteOffcanvas';
import ReplyNoteOffcanvas from 'components/offcanvas/ReplyNoteOffcanvas';
import useApi from 'helpers/api';
import { formatTime } from 'helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const TicketsPreviewHeader = ({ data, refreshData, error, success, refreshLogs, refreshFiles, refreshNotes }) => {
  const navigate = useNavigate();
  const api = useApi();
  const [timeSpent, setTimeSpent] = useState('');
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [showReplyNote, setShowReplyNote] = useState(false);
  const [showForwardNote, setShowForwardNote] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(false);
  const headerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);


  useEffect(() => {
    attachFile();
  }, [selectedFile]);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        setIsSticky(window.scrollY > headerRef.current.offsetTop);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const startTicket = async () => {
    let request = await api.tickets.start(data.id);
    if (request.ok) {
      refreshData();
      refreshLogs();
      success("Ticket iniciado com sucesso");
    } else {
      error(request);
    }
  };

  const pauseTicket = async body => {
    let response = await api.tickets.pause(data.id, body);
    if (response.status) {
      error(response);
    } else {
      refreshData();
      refreshLogs();
      success("Ticket pausado com sucesso")
      handleCloseModalRegister();
    }
  };

  const completeTicket = async () => {
    let request = await api.tickets.complete(data.id);
    if (request.ok) {
      refreshData();
      refreshLogs();
      success("Ticket concluído com sucesso");
    } else {
      error(request);
    }
  };

  const reopenTicket = async () => {
    let request = await api.tickets.reopen(data.id);
    if (request.ok) {
      refreshData();
      refreshLogs();
      success("Ticket reaberto com sucesso");
    } else {
      error(request);
    }
  };

  const deleteTicket = async () => {
    let request = await api.tickets.delete(data.id);
    if (request.ok) {
      navigate('/tickets');
    } else {
      error(request);
    }
  };

  const attachFile = async () => {
    setLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const request = await api.ticketFiles.attach(formData, data.id);
      if (request.ok) {
        refreshLogs();
        refreshFiles();
        success("Arquivo anexado com sucesso");
        setSelectedFile(null);
      } else {
        error(request);
      }
    }
    setLoading(false);
  };

  const printTicket = async () => {
    try {
      const response = await api.tickets.print(data.id);

      if (!response.ok) {
        throw new Error('Failed to download PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ticket_${data.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the PDF', error);
    }
  };


  const handlePauseTicket = async () => {
    let response = await api.tickets.getTimeSpent(data.id);
    setTimeSpent(response && formatTime(response));
    setShowModalRegister(true);
  };

  const handleFileSelect = e => {
    setSelectedFile(e.target.files[0]);
  };

  const handleCloseModalRegister = () => {
    setShowModalRegister(false);
  };

  const handleCloseDialog = () => {
    setShowDialogConfirm(false);
  };

  return (
    <>
      {loading && <Loading />}
      <TicketPauseRegistrationModal
        showModal={showModalRegister}
        handleClose={handleCloseModalRegister}
        handleSubmit={pauseTicket}
        data={timeSpent} />

      <AddNoteOffcanvas
        show={showAddNote}
        handleClose={() => setShowAddNote(false)}
        data={{}}
        ticketId={data.id}
        refreshNotes={() => refreshNotes()}
        refreshLogs={() => refreshLogs()}
        refreshData={() => refreshData()}
        ticket={data} />

      <ReplyNoteOffcanvas
        show={showReplyNote}
        handleClose={() => setShowReplyNote(false)}
        ticketId={data.id}
        contact={data.contactCustomer}
        refreshNotes={() => refreshNotes()}
        refreshLogs={() => refreshLogs()}
        refreshData={() => refreshData()} />

      <ForwardNoteOffcanvas
        show={showForwardNote}
        handleClose={() => setShowForwardNote(false)}
        ticketId={data.id}
        ticketSubject={data.subject}
        ticketDescription={data.description}
        refreshNotes={() => refreshNotes()}
        refreshLogs={() => refreshLogs()} />

      <DialogModal
        message="Tem certeza que deseja excluir este ticket?"
        showModal={showDialogConfirm}
        handleClose={handleCloseDialog}
        response={deleteTicket} />

      <Card ref={headerRef} className={isSticky ? 'sticky-header' : ''}>
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="arrow-left"
            as={Link}
            to={'/tickets'}
          />
          <Flex>
            {data.status && data.status.status === 'IN_PROGRESS' &&
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faPause}
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
                onClick={() => handlePauseTicket()}
              >
                <span className="d-none d-md-inline-block ms-1">Pausar</span>
              </IconButton>
            }

            {data.status && data.status.status !== 'IN_PROGRESS' && data.status.status !== 'COMPLETED' && data.status.status !== 'CLOSED' &&
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="play"
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
                onClick={() => startTicket()}
              >
                <span className="d-none d-md-inline-block ms-1">Iniciar</span>
              </IconButton>
            }

            {data.status && data.status.status === 'COMPLETED' &&
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faUndo}
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
                onClick={() => reopenTicket()}
              >
                <span className="d-none d-md-inline-block ms-1">Reabrir ticket</span>
              </IconButton>
            }

            {data.status && data.status.status !== 'IN_PROGRESS' && data.status.status !== 'COMPLETED' && data.status.status !== 'CLOSED' &&
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="check"
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
                onClick={() => completeTicket()}
              >
                <span className="d-none d-md-inline-block ms-1">Concluir</span>
              </IconButton>
            }

            {data.status && data.status.status !== 'COMPLETED' && data.status.status !== 'CLOSED' &&
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="paperclip"
                transform="shrink-2"
                iconAlign="middle"
                onClick={() => inputFile.current.click()}
                className="me-2"
              >
                <span className="d-none d-md-inline-block ms-1">Anexar</span>
              </IconButton>
            }

            <input type="file" ref={inputFile} onChange={handleFileSelect} style={{ display: 'none' }} />

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="print"
              transform="shrink-2"
              iconAlign="middle"
              className="me-2"
              onClick={() => printTicket()}
            >
              <span className="d-none d-md-inline-block ms-1">Imprimir</span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="trash-alt"
              transform="shrink-2"
              iconAlign="middle"
              className="d-none d-sm-block"
              onClick={() => setShowDialogConfirm(true)}
            >
              <span className="d-none d-md-inline-block ms-1">Excluir</span>
            </IconButton>

            {data.status && data.status.status !== 'COMPLETED' && data.status.status !== 'CLOSED' &&
              <Dropdown
                align="end"
                className="btn-reveal-trigger d-inline-block ms-2"
              >
                <Dropdown.Toggle split variant="falcon-default" size="sm">
                  <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border py-0">
                  <div className="py-2">
                    <Dropdown.Item onClick={() => setShowAddNote(true)}>Adicionar nota</Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowReplyNote(true)}>Responder</Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowForwardNote(true)}>Encaminhar</Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            }
          </Flex>
        </Card.Header>
      </Card>
    </>
  );
};

export default TicketsPreviewHeader;