import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const BusinessHourEditModal = ({ data, showModal, handleClose, handleSubmit }) => {
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState({
    startBusiness: '',
    endBusiness: '',
  });
  const form = useRef(null);

  useEffect(() => {
    setBody({
      startBusiness: data.startBusiness,
      endBusiness: data.endBusiness,
    });
  }, [data]);

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({
        startBusiness: body.startBusiness,
        endBusiness: body.endBusiness,
      });
      clearFields();
      setValidated(false);
    }
  };

  const clearFields = () => {
    setBody({
      startBusiness: '',
      endBusiness: '',
    });
  };

  const setFieldBody = (fieldName, value) => {
    setBody(prevBody => ({ ...prevBody, [fieldName]: value }));
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar horário comercial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="appointment.profile">
              <Form.Label>Início do expediente <span className="text-danger">*</span></Form.Label>
              <Form.Control type="time" value={body.startBusiness} onChange={e => setFieldBody('startBusiness', e.target.value)} required />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira as horas trabalhadas!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="appointment.profile">
              <Form.Label>Fim do expediente <span className="text-danger">*</span></Form.Label>
              <Form.Control type="time" value={body.endBusiness} onChange={e => setFieldBody('endBusiness', e.target.value)} required />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira as horas trabalhadas!</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" type="submit" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusinessHourEditModal;