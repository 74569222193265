import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const AssetTypeRegistrationModal = ({ show, close, refresh }) => {
  const api = useApi();
  const [body, setBody] = useState({
    name: '',
    description: ''
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if (show) {
      setBody({ name: '', description: '' });
      setValidated(false);
    }
  }, [show]);

  const onSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);

      const response = await api.assetTypes.create(body);

      if (response.id) {
        setShowSuccess(true);
        close();
        refresh();
      } else {
        setError(response);
        setShowError(true);
      }
    }
  };

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message="Tipo de ativo cadastrado com sucesso" />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error.userMessage} />
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Novo tipo de ativo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Form.Group className="mb-3" controlId="registration.name">
              <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                autoFocus
                value={body.name}
                onChange={e => setBody({ ...body, name: e.target.value })}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="registration.description">
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                type="text"
                placeholder="Uma breve descrição"
                value={body.description}
                onChange={e => setBody({ ...body, description: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-default" onClick={close}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssetTypeRegistrationModal;