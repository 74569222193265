import { faChevronDown, faChevronUp, faCircle, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessRequest from "components/alerts/SuccessRequest";
import SlaPauseModal from "components/modals/SlaPauseModal";
import useApi from 'helpers/api';
import { formatDateTime } from "helpers/utils";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

const SlaInfo = ({ data, refreshData }) => {
  const [slaPause, setSlaPause] = useState({});
  const [showBody, setShowBody] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const [success, setSuccess] = useState('');
  const [error, setError] = useState({});

  const api = useApi();

  const isPaused = (data.statusSlaResponse && data.statusSlaResponse.description === 'SLA pausado') || (data.statusSlaSolution && data.statusSlaSolution.description === 'SLA pausado');

  const dontHaveSla = (data.statusSlaResponse && data.statusSlaResponse.description === 'Sem SLA') && (data.statusSlaSolution && data.statusSlaSolution.description === 'Sem SLA');

  const isCompleted = (data.status && data.status.description === "Concluído") || (data.status && data.status.description === "Fechado");

  useEffect(() => {
    if (isPaused) {
      getActiveSlaPause();
    }
  }, [data]);

  const getActiveSlaPause = async () => {
    const response = await api.slaPauses.getActivePause(data.id);
    response && setSlaPause(response);
  };

  const handleToggleSla = () => {
    if (isPaused) {
      resumeSla();
    } else {
      setShowModal(true);
    }
  }

  const resumeSla = async () => {
    const request = await api.slaPauses.resume(data.id);
    if (request.id) {
      setSuccess("SLA retomado com sucesso");
      setshowSuccess(true);
      refreshData();
    }
  }

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setshowSuccess(false)} />
      <SlaPauseModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        ticketId={data.id}
        refreshData={() => refreshData()} />
      <Card>
        <div className="w-100 bg-100 d-flex card-header py-2 align-items-center justify-content-between" style={{ cursor: 'pointer' }} onClick={() => setShowBody(!showBody)}>
          <h6 className="m-0">Informações de SLA</h6>
          <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
        </div>
        {showBody &&
          <Card.Body>
            <Row className="align-items-sm-center align-items-xl-start">
              <Col xs={12} sm="auto" xl={12} className="d-flex justify-content-between">
                <div>
                  <p className="fw-bold text-800 fs-11 mb-2">DATA DE CRIAÇÃO</p>
                  <p className="fs-10">{formatDateTime(data.creationDate)}</p>
                </div>
                {!dontHaveSla && !isCompleted &&
                  <OverlayTrigger overlay={<Tooltip>{isPaused ? "Retomar SLA" : "Pausar SLA"}</Tooltip>}>
                    <Button variant="falcon-default" className="h-25" size="sm" onClick={() => handleToggleSla()}>
                      <FontAwesomeIcon transform="shrink-3" size="sm" icon={isPaused ? faPlay : faPause} />
                    </Button>
                  </OverlayTrigger>
                }
              </Col>
              <Col xs={12} sm="auto" xl={12}>
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon icon={faCircle} size="sm" className={`mt-1 me-2`} style={{ color: data.statusSlaResponse && data.statusSlaResponse.color }} />
                  <p className="fw-bold text-800 fs-11 mb-2">PRAZO PARA A PRIMEIRA RESPOSTA VENCE</p>
                </div>
                <p className="fs-10">em {data.responseSlaExpiration ? formatDateTime(data.responseSlaExpiration) : "---"}</p>
              </Col>
              <Col xs={12} sm="auto" xl={12}>
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon icon={faCircle} size="sm" className={`me-2`} style={{ color: data.statusSlaSolution && data.statusSlaSolution.color }} />
                  <p className="fw-bold text-800 fs-11 mb-2">PRAZO PARA SOLUÇÃO VENCE</p>
                </div>
                <p className="fs-10">em {data.solutionSlaExpiration ? formatDateTime(data.solutionSlaExpiration) : "---"}</p>
              </Col>
              <Col xs={12} className="d-flex align-items-center flex-wrap">
                <OverlayTrigger overlay={<Tooltip>Prazo de resposta</Tooltip>}>
                  <Badge bg="" style={{ backgroundColor: data.statusSlaResponse && data.statusSlaResponse.color }} className="flex-grow-1 mb-1 me-1">
                    {data.statusSlaResponse && data.statusSlaResponse.description}
                  </Badge>
                </OverlayTrigger>

                <OverlayTrigger overlay={<Tooltip>Prazo de solução</Tooltip>}>
                  <Badge bg="" style={{ backgroundColor: data.statusSlaSolution && data.statusSlaSolution.color }} className="flex-grow-1 mb-1 me-1">
                    {data.statusSlaSolution && data.statusSlaSolution.description}
                  </Badge>
                </OverlayTrigger>
              </Col>

              {isPaused &&
                <Col xs={12}>
                  <Badge bg="secondary" className="w-100">
                    {slaPause.pauseReason && slaPause.pauseReason.name}
                  </Badge>
                </Col>
              }
            </Row>
          </Card.Body>
        }
      </Card>
    </>
  );
};

export default SlaInfo;