import { faPen, faPlus, faTimes, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FailedRequest from 'components/alerts/FailedRequest';
import SuccessRequest from 'components/alerts/SuccessRequest';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import CategoryEditModal from 'components/modals/CategoryEditModal';
import CategoryRegistrationModal from 'components/modals/CategoryRegistrationModal';
import DialogModal from 'components/modals/DialogModal';
import SubcategoryEditModal from 'components/modals/SubcategoryEditModal';
import SubcategoryRegistrationModal from 'components/modals/SubcategoryRegistrationModal';
import useApi from 'helpers/api';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

const ServiceCatalogDetails = () => {
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const [serviceCatalog, setServiceCatalog] = useState({
    id: '',
    categories: [
      { subcategories: [] }
    ]
  });
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubcategory, setSelectedSubcategory] = useState({});
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [handleModals, setHandleModals] = useState({
    categoryRegistration: false,
    categoryEdit: false,
    subcategoryRegistration: false,
    subcategoryEdit: false,
    error: false,
    success: false,
    dialogConfirm: false
  });
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');

  useEffect(() => {
    getServiceCatalog();
  }, []);

  const getServiceCatalog = async () => {
    const response = await api.serviceCatalogs.findById(id);
    setServiceCatalog(response);
  };

  const createCategory = async body => {
    let response = await api.categories.create(body);
    if (response.status) {
      handleError(response);
    } else {
      handleSuccess("Categoria cadastrada com sucesso");
      getServiceCatalog();
    }
    toggleModal('categoryRegistration', false);
  };

  const editCategory = async body => {
    let response = await api.categories.edit(selectedCategory.id, body);
    if (response.status) {
      handleError(response);
    } else {
      handleSuccess("Categoria editada com sucesso");
      getServiceCatalog();
    }
    toggleModal('categoryEdit', false);
  }

  const createSubcategory = async body => {
    let response = await api.subcategories.create(body);
    if (response.status) {
      handleError(response);
    } else {
      handleSuccess("Sub-categoria cadastrada com sucesso");
      getServiceCatalog();
    }
    toggleModal('subcategoryRegistration', false);
  };

  const unassociateSubcategory = async canUnassociate => {
    if (canUnassociate) {
      let response = await api.subcategoriesCategories.unassociate(selectedSubcategory.id, selectedCategory.id);
      if (response.status == 204)
        getServiceCatalog();
      else
        handleError(response);

      toggleModal('dialogConfirm', false);
    }
  };

  const editSubcategory = async body => {
    let response = await api.subcategories.edit(selectedSubcategory.id, body);
    if (response.status) {
      handleError(response);
    } else {
      handleSuccess("Sub-categoria editada com sucesso");
      getServiceCatalog();
    }

    toggleModal('subcategoryEdit', false);
  };

  const toggleModal = (modalName, value) => {
    setHandleModals(prevState => ({ ...prevState, [modalName]: value }));
  };

  const toggleCategories = categoryId => {
    if (expandedCategories.includes(categoryId)) {
      setExpandedCategories(expandedCategories.filter(id => id !== categoryId));
    } else {
      setExpandedCategories([...expandedCategories, categoryId]);
    }
  };

  const handleEditCategory = item => {
    setSelectedCategory(item);
    toggleModal('categoryEdit', true);
  };

  const handleEditSubcategory = (category, subcategory) => {
    setSelectedSubcategory(subcategory);
    toggleModal('subcategoryEdit', true);
  };

  const handleUnassociateSubcategory = (category, subcategory) => {
    setSelectedCategory(category);
    setSelectedSubcategory(subcategory);
    toggleModal('dialogConfirm', true);
  };

  const handleError = error => {
    setError(error);
    toggleModal('error', true);
  };

  const handleSuccess = success => {
    setSuccess(success);
    toggleModal('success', true);
  };

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={handleModals.success}
        setShowAlert={() => toggleModal('success', false)} />
      <FailedRequest
        message={error.description}
        showAlert={handleModals.error}
        setShowAlert={() => toggleModal('error', false)} />
      <FalconComponentCard>
        <Card.Header>
          <Flex justifyContent="between" alignItems="center" className="w-100">
            <Flex alignItems="center" className='w-25'>
              <IconButton
                onClick={() => navigate(-1)}
                variant="falcon-default"
                size="sm"
                icon="arrow-left"
                className="me-2"
              />

              <h3>{serviceCatalog.name}</h3>
            </Flex>

            <IconButton
              onClick={() => toggleModal('categoryRegistration', true)}
              variant="primary"
              size="sm"
              icon="plus"
              className="me-2"
              iconClassName="text-sm">
              Nova categoria
            </IconButton>
          </Flex>
        </Card.Header>
        <FalconComponentCard.Body>
          <CategoryEditModal
            serviceCatalogId={serviceCatalog.id}
            showModal={handleModals.categoryEdit}
            data={selectedCategory}
            handleClose={() => toggleModal('categoryEdit', false)}
            handleSubmit={editCategory} />

          <CategoryRegistrationModal
            data={serviceCatalog}
            showModal={handleModals.categoryRegistration}
            handleClose={() => toggleModal('categoryRegistration', false)}
            handleSubmit={createCategory} />

          <SubcategoryRegistrationModal
            showModal={handleModals.subcategoryRegistration}
            handleClose={() => toggleModal('subcategoryRegistration', false)}
            handleSubmit={createSubcategory}
            parentCategory={selectedCategory}
            serviceCatalogId={id} />

          <SubcategoryEditModal
            data={selectedSubcategory}
            showModal={handleModals.subcategoryEdit}
            handleClose={() => toggleModal('subcategoryEdit', false)}
            handleSubmit={editSubcategory} />

          <DialogModal
            message="Tem certeza que deseja excluir esta subcategoria?"
            showModal={handleModals.dialogConfirm}
            handleClose={() => toggleModal('dialogConfirm', false)}
            response={unassociateSubcategory} />

          <div id="serviceCatalogs">
            <div className="table-responsive scrollbar">
              <table className="table table-striped fs-10 mb-0">
                <thead className="bg-100 text-900">
                  <tr>
                    <th style={{ width: '30px' }}></th>
                    <th>Nome</th>
                    <th>Status</th>
                    <th style={{ width: '150px' }}>Ações</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {serviceCatalog.categories.map((item, i) => (
                    <React.Fragment key={i + item.name}>
                      <tr key={i}>
                        <td>
                          <Button
                            variant='link'
                            size='sm'
                            className='p-0'
                            onClick={() => toggleCategories(item.id)}>
                            <FontAwesomeIcon style={{ fontSize: '9px', transition: 'all .2s ease' }} className='text-secondary' icon={expandedCategories.includes(item.id) ? faWindowMinimize : faPlus} />
                          </Button>
                        </td>
                        <td className='name'>
                          {item.name}
                        </td>
                        <td>
                          {item.inactive
                            ? <span className="text-danger">Inativo</span>
                            : <span className="text-success">Ativo</span>
                          }
                        </td>
                        <td>
                          <button className="btn btn-link btn-sm" onClick={() => {
                            toggleModal('subcategoryRegistration', true);
                            setSelectedCategory(item);
                          }}>
                            <FontAwesomeIcon icon={faPlus} size="sm" />
                          </button>
                          <button className="btn btn-link btn-sm" onClick={() => handleEditCategory(item)}>
                            <FontAwesomeIcon icon={faPen} size="sm" />
                          </button>
                        </td>
                      </tr>
                      {expandedCategories.includes(item.id) && item.subcategories.length != 0 ? (item.subcategories.map((subcategory, i) => (
                        <tr key={i + subcategory.id}>
                          <td></td>
                          <td>{subcategory.name}</td>
                          <td>
                            {subcategory.inactive
                              ? <span className="text-danger">Inativo</span>
                              : <span className="text-success">Ativo</span>
                            }
                          </td>
                          <td>
                            <button className="btn btn-link btn-sm" onClick={() => handleEditSubcategory(item, subcategory)}>
                              <FontAwesomeIcon icon={faPen} size="sm" />
                            </button>
                            <button
                              className="btn btn-link btn-sm"
                              onClick={() => handleUnassociateSubcategory(item, subcategory)}>
                              <FontAwesomeIcon icon={faTimes} size="sm" />
                            </button>
                          </td>
                        </tr>
                      ))) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>

  );
};

export default ServiceCatalogDetails;
