import useAdvanceTable from './useAdvanceTable';

const useTicketsAppointmentsTable = options => {
  const table = useAdvanceTable({
    sortable: true,
    pagination: true,
    perPage: 20,
    ...options
  });

  return table;
};

export default useTicketsAppointmentsTable;
