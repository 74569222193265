import { formatDateTime } from "helpers/utils";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const AssetOverview = ({ data }) => {
  const activeCustomProperties = data.assetPropertyValues?.filter(
    (propertyValue) => propertyValue.assetProperty?.deleted === false
  ) || [];

  const getPropertyValue = (propertyValue) => {
    return propertyValue.stringValue ||
      propertyValue.intValue ||
      propertyValue.decimalValue ||
      propertyValue.textValue ||
      propertyValue.boolValue ||
      (propertyValue.datetimeValue && formatDateTime(propertyValue.datetimeValue)) ||
      '---';
  };

  return (
    <>
      <h5 className="m-0">Propriedades padrão</h5>
      <Table className="mb-2">
        <tbody>
          <TrItem name="Nome" value={data.name} />
          <TrItem name="Tipo" value={data.assetType?.name} />
          <TrItem name="Cliente" value={<Link to={`/registration/customers/${data.customer?.id}`}>{data.customer?.name}</Link>} />
          <TrItem name="Contato" value={data.contact?.name} />
          <TrItem name="Descrição" value={data.description} />
          <TrItem name="Número de série" value={data.serialNumber} />
          <TrItem
            name="Status"
            className={data.inactive ? 'text-danger' : 'text-success'}
            value={data.inactive ? 'Inativo' : 'Ativo'}
          />
          <TrItem name="Criado em" value={formatDateTime(data.creationDate)} />
          <TrItem name="Atualizado em" value={formatDateTime(data.lastUpdateDate)} />
        </tbody>
      </Table>

      <h5 className="w-100 mt-3 mb-2">Propriedades customizadas</h5>
      <Table className="w-100">
        <tbody>
          {activeCustomProperties.length > 0 ? (
            activeCustomProperties.map((propertyValue) => (
              <TrItem
                key={propertyValue.id}
                name={propertyValue.assetProperty?.name}
                value={getPropertyValue(propertyValue)}
              />
            ))
          ) : (
            <TrItem name="Nenhuma propriedade customizada ativa" value="---" />
          )}
        </tbody>
      </Table>
    </>
  );
};

export default AssetOverview;

const TrItem = ({ name, value, className }) => {
  return (
    <tr style={{ borderColor: 'transparent' }}>
      <td className="w-25 pb-0">{name} </td>
      <td className={`fw-semibold pb-0 ${className}`}>{value || '---'}</td>
    </tr>
  );
};
