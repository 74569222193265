import React from 'react';
import { Account, Generals, Sla, Team, Ticket } from './Admin';

const Settings = () => {
  return (
    <>
      <Team />
      <Generals />
      <Ticket />
      <Sla />
      <Account />
    </>
  );
};

export default Settings;