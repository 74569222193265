import { Table } from "react-bootstrap";

const AssetDetailsFromRMM = ({ data }) => {
  return (
    <Table>
      <tbody>
        <TrItem name="Hostname" value={data.hostname} />
        <TrItem name="Descrição" value={data.description} />
        <TrItem name="Tipo" value={data.deviceTypeCategory + ' > ' + data.deviceTypeType} />
        <TrItem name="Domínio" value={data.domain} />
        <TrItem name="Endereço IP Interno" value={data.intIpAddress} />
        <TrItem name="Endereço IP Externo" value={data.extIpAddress} />
        <TrItem name="Status de Ger. de Soft." value={data.softwareStatus} />
        <TrItem name="Status Antivírus" value={data.antivirusStatus} />
        <TrItem name="Status Patch" value={data.patchStatus} />
        <TrItem name="Fabricante" value={data.manufacturer} />
        <TrItem name="Modelo" value={data.model} />
        <TrItem name="Número de série" value={data.serialNumber} />
        <TrItem name="SO" value={data.operatingSystem} />
        <TrItem name="Arquitetura" value={`${data.a64bit ? '64' : '32'} Bit`} />
        <TrItem name="Placa Mãe" value={data.baseBoard} />
        <TrItem name="Processador" value={data.processor} />
        <TrItem name="Memória RAM" value={Math.ceil(data.totalPhysicalMemory / (1024 ** 3)) + ' GB'} />
        <TrItem name="Último Usuário" value={data.lastLoggedUser} />
      </tbody>
    </Table>
  );
};

export default AssetDetailsFromRMM;

const TrItem = ({ name, value, className }) => {
  return (
    <tr style={{ borderColor: 'transparent' }}>
      <td style={{ width: '40%' }} className="pb-0">{name} </td>
      <td className={`fw-semibold pb-0 ${className}`}>{value || '---'}</td>
    </tr>
  );
};