import FalconCardHeader from 'components/common/FalconCardHeader';
import useApi from 'helpers/api';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import RushDayOfWeekChart from './RushDayOfWeekChart';

const RushDayOfWeek = ({ filters }) => {
  const api = useApi();
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [filters]);

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.overview.rushDayOfWeek(queryParams);

    setData(response.status ? [] : response);
  };

  return (
    <Card className="mt-3">
      <FalconCardHeader
        title="Dia da semana de Pico"
        titleTag="h4"
        className="py-2 bg-body-tertiary"
      />
      <Card.Body>
        <RushDayOfWeekChart data={data} />
      </Card.Body>
    </Card>
  );
};

export default RushDayOfWeek;