import DOMPurify from "dompurify";
import { formatDateTime } from "helpers/utils";
import { Col, Offcanvas, Row } from "react-bootstrap";

const PreticketViewOffcanvas = ({ show, handleClose, data }) => {
  const sanitizedHtml = DOMPurify.sanitize(data.description);
  return (
    <Offcanvas show={show} onHide={handleClose} placement='end' style={{ minWidth: '45%', maxWidth: '100%' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Visualizar Pré Ticket</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col>
            <h6>Assunto</h6>
            <p className="fs-10 mb-0 text-600">{data.subject}</p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h6>Remetente</h6>
            <p className="fs-10 mb-0 text-600">{data.sender}</p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h6>Data</h6>
            <p className="fs-10 mb-0 text-600">{formatDateTime(data.creationDate)}</p>
          </Col>
        </Row>
        <br />
        <Row className="w-100">
          <Col md={12}>
            <h6>Descrição</h6>
            <div className="fs-10 mb-0 text-600 text-break" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default PreticketViewOffcanvas;