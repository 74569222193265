import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ReportList from './ReportList';

const LayoutReport = ({ children }) => {
  const [showMenu, setShowMenu] = useState(true);
  const [reportName, setReportName] = useState('');

  return (
    <Row className="gx-3">
      {showMenu &&
        <Col md={3}>
          <ReportList reportName={name => setReportName(name)} />
        </Col>
      }
      <Col md={showMenu ? 9 : 12}>
        <Card>
          <Flex alignItems="center" className="w-100 px-2 py-3">
            <IconButton
              icon={showMenu ? faArrowLeft : faBars}
              variant="falcon-default"
              sizeIcon="sm"
              size="sm"
              className="ms-2 me-3"
              onClick={() => setShowMenu(!showMenu)} />

            <h4 className="p-0 m-0">{reportName}</h4>
          </Flex>
          <Card.Body>
            <div>
              {children}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LayoutReport;
