import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { colors } from 'helpers/utils';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PizzaChart from './Pie';

const TicketsByCatalogReport = ({ data, title }) => {
  return (
    <div className="h-100 pt-3">
      <Row className="w-100">
        <Col lg={7} xs={12}>
          {data &&
            <PizzaChart data={data} />
          }
        </Col>

        <Col lg={5} xs={12} className="h-100 scroll-area">
          <div className='h-auto'>
            <h6 className="mb-3">Resumo</h6>
            <div>
              {data &&
                data.map((item, i) => (
                  <div key={item.name}>
                    <Flex>
                      <FontAwesomeIcon
                        icon={faCircle}
                        color={colors[i]}
                        size="sm"
                      />
                      <h6
                        className="m-0 p-0 ms-1 text-nowrap overflow-hidden"
                        style={{ textOverflow: 'ellipsis' }}
                      >
                        {item.name}
                      </h6>
                    </Flex>
                    <p className="fs-10">Tickets {item.value}</p>
                  </div>
                ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TicketsByCatalogReport;
