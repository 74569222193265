import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Pagination from 'components/pagination';
import useApi from 'helpers/api';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useEffect, useState } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';

const columns = [
  {
    accessorKey: 'agent',
    header: '	Agente',
    meta: {
      headerProps: { style: { height: '46px' }, className: 'text-900' },
      cellProps: {
        className: 'white-space-nowrap pe-4 w-lg-25 py-3'
      }
    },
    cell: rowData => {
      const { firstName, lastName, isInactive } = rowData.row.original;
      return (
        <Flex alignItems="center" className="gap-2 position-relative py-2">
          <h6 className="mb-0">
            {firstName + ' ' + lastName} {isInactive ? <span className='text-danger'>(Inativo)</span> : ''}
          </h6>
        </Flex>
      );
    }
  },
  {
    accessorKey: 'opened',
    header: 'Tickets Abertos',
    meta: {
      headerProps: { className: 'text-900' },
      cellProps: {
        className: 'white-space-nowrap pe-5 pe-xxl-8'
      }
    },
    cell: rowData => {
      const { createdTickets } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div>
            <Flex alignItems="baseline" className="gap-1 mb-1">
              <h5 className="mb-0">{createdTickets}</h5>
            </Flex>
          </div>
          <div className="ms-4">
            <ProgressBar
              variant="primary"
              now={createdTickets}
              style={{ width: '174px', height: '5px' }}
              className="mb-1"
            />
          </div>
        </Flex>
      );
    }
  },
  {
    accessorKey: 'closeds',
    header: 'Tickets Fechados',
    meta: {
      headerProps: { className: 'text-900' },
      cellProps: {
        className: 'white-space-nowrap'
      }
    },
    cell: rowData => {
      const { closedTickets } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div>
            <Flex alignItems="baseline" className="gap-1 mb-1">
              <h5 className="mb-0">{closedTickets}</h5>
            </Flex>
          </div>
          <div className="ms-4">
            <ProgressBar
              variant="info"
              now={closedTickets}
              style={{ width: '174px', height: '5px' }}
              className="mb-1"
            />
          </div>
        </Flex>
      );
    }
  }
];

const AgentAnalysis = ({ filters }) => {
  const api = useApi();

  const [data, setData] = useState({ content: [] });
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    ...filters
  });

  const table = useAdvanceTable({
    data: data.content || [],
    columns,
    selectionColumnWidth: 30
  });

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    setFilter({
      ...filter,
      ...filters
    });
  }, [filters]);

  const getData = async () => {
    const queryParams = new URLSearchParams(filter).toString();
    const response = await api.reports.overview.agentAnalysis(queryParams);
    setData(response.content ? response : {});
  };

  return (
    <AdvanceTableProvider {...table}>
      <Card className="mt-3">
        <FalconCardHeader
          title="Tickets Abertos e Fechados por Agente"
          titleTag="h4"
          className="py-2 bg-body-tertiary"
        />
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-body-tertiary align-middle"
            rowClassName="btn-reveal-trigger align-middle"
            tableProps={{
              size: 'sm',
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <Pagination data={data} filters={filter} setFilters={setFilter} />
        </Card.Footer>
      </Card>
    </AdvanceTableProvider>
  );
};

export default AgentAnalysis;
