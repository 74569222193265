import { Button, Modal } from "react-bootstrap";

const DialogModal = ({ message, showModal, handleClose, response }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Não
        </Button>
        <Button variant="primary" onClick={() => response(true)}>
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DialogModal;