import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import useApi from 'helpers/api';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Card, Col, Form, Row } from 'react-bootstrap';
import NumberOfTicketsChart from './NumberOfTicketsChart';

const FormCheck = ({ title, id, inputClass, checkBoxClass, handleLegend }) => {
  return (
    <Form.Check
      className={classNames(checkBoxClass, 'd-flex align-items-center mb-0')}
    >
      <Form.Check.Input
        onChange={event => handleLegend(event, title)}
        type="checkbox"
        id={id}
        defaultChecked
        className={classNames(
          inputClass,
          'dot mt-0 shadow-none remove-checked-icon rounded-circle cursor-pointer'
        )}
      />
      <Form.Check.Label
        htmlFor={id}
        className="form-check-label lh-base mb-0 fs--2 text-500 fw-semi-bold font-base cursor-pointer"
      >
        {title}
      </Form.Check.Label>
    </Form.Check>
  );
};

FormCheck.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
  checkBoxClass: PropTypes.string,
  handleLegend: PropTypes.func
};

const TicketsCategory = ({ title, number, percentage, icon, badgeColor }) => {
  return (
    <div>
      <h6 className="fs-0 d-flex align-items-center text-700 mb-1">
        {number}
        <Badge pill bg="transparent" className={classNames(badgeColor, 'px-0')}>
          <FontAwesomeIcon icon={icon} className="fs--2 ms-2 me-1" />
          {percentage}
        </Badge>
      </h6>
      <h6 className="fs--2 text-600 mb-0 text-nowrap">{title}</h6>
    </div>
  );
};

TicketsCategory.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  badgeColor: PropTypes.string
};

const NumberOfTickets = ({ filters }) => {
  const chartRef = useRef(null);
  const api = useApi();
  const [opened, setOpened] = useState([]);
  const [closed, setClosed] = useState([]);
  const [diff, setDiff] = useState([]);
  const [totalOpened, setTotalOpened] = useState(0);
  const [totalClosed, setTotalClosed] = useState(0);

  useEffect(() => {
    getOpened();
    getClosed();
  }, [filters]);

  useEffect(() => {
    if (opened && closed) {
      let subArray = [];
      for (let i = 0; i < Math.min(opened.length, closed.length); i++) {
        let sub;
        if (opened[i].count > closed[i].count)
          sub = opened[i].count - closed[i].count;
        else sub = closed[i].count - opened[i].count;
        subArray.push(sub);
      }
      setDiff(subArray);
    }
  }, [opened, closed]);

  const handleLegend = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name: name
    });
  };

  const getOpened = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.productivity.openedTrend(queryParams);
    if (response) {
      setOpened(response);
      let total = 0;
      for (let i = 0; i < response.length; i++) {
        total += response[i].count;
      }
      setTotalOpened(total);
    }
  };

  const getClosed = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.productivity.closedTrend(queryParams);
    if (response) {
      setClosed(response);
      let total = 0;
      for (let i = 0; i < response.length; i++) {
        total += response[i].count;
      }
      setTotalClosed(total);
    }
  };

  return (
    <Card className="h-100 mt-3">
      <Card.Header className="d-md-flex justify-content-between py-3 py-md-2 card-header">
        <h4 className="mb-2 mb-md-0 py-md-2">Tendência de tickets</h4>
        <Row className="g-md-0">
          <Col xs="auto" className="d-md-flex">
            <FormCheck
              title="Tickets abertos"
              id="openTickets"
              checkBoxClass="me-md-3"
              handleLegend={handleLegend}
            />
            <FormCheck
              title="Tickets fechados"
              id="closedTickets"
              inputClass="closed-tickets"
              checkBoxClass="mt-n1 mt-md-0 me-md-3"
              handleLegend={handleLegend}
            />
            <FormCheck
              title="Saldo"
              id="balance"
              inputClass="form-check-input-warning"
              checkBoxClass="mt-n1 mt-md-0 me-md-3"
              handleLegend={handleLegend}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="scrollbar overflow-y-hidden">
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <TicketsCategory
              title="Total de tickets criados"
              number={totalOpened}
            />
            <div
              className="bg-200 mx-3"
              style={{ height: '24px', width: '1px' }}
            ></div>
          </div>
          <div className="d-flex align-items-center">
            <TicketsCategory
              title="Total de tickets concluídos"
              number={totalClosed}
            />
            <div
              className="bg-200 mx-3"
              style={{ height: '24px', width: '1px' }}
            ></div>
          </div>
        </div>
        <NumberOfTicketsChart
          opened={opened}
          closed={closed}
          diff={diff}
          ref={chartRef}
        />
      </Card.Body>
    </Card>
  );
};

NumberOfTickets.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default NumberOfTickets;
