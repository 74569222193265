import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { colorsSecondary } from 'helpers/utils';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TicketsByCustomerChart from './TicketsByCustomerChart';

const TicketsByCustomer = ({ data }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Tickets em Aberto por Cliente"
        titleTag="h4"
        className="py-2"
      />
      <Card.Body className="p-3 d-flex align-items-center">
        <Row className="w-100 g-2">
          <Col lg={10} xs={12}>
            <TicketsByCustomerChart data={data} />
          </Col>

          <Col lg={2} xs={12} className="h-100 scroll-area">
            <div style={{ height: '300px', overflowY: 'scroll' }}>
              <h6 className="mb-3">Resumo</h6>
              <div>
                {data &&
                  data.map((item, i) => (
                    <div key={item.name}>
                      <Flex>
                        <FontAwesomeIcon
                          icon={faCircle}
                          color={colorsSecondary[i]}
                          size="sm"
                        />
                        <h6
                          className="m-0 p-0 ms-1 text-nowrap overflow-hidden"
                          style={{ textOverflow: 'ellipsis' }}
                        >
                          {item.name}
                        </h6>
                      </Flex>
                      <p className="fs-10">Tickets {item.value}</p>
                    </div>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TicketsByCustomer;
