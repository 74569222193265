import { faExclamationTriangle, faInfoCircle, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import Flex from "components/common/Flex";
import Loading from "components/loading/Loading";
import AssetTypeRegistrationModal from "components/modals/AssetTypeRegistrationModal";
import Pagination from "components/pagination";
import useApi from 'helpers/api';
import { useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";

const DattoDevices = () => {
  const api = useApi();
  const DEFAULT_COOLDOWN_MINUTES = 60;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAssetTypeModal, setShowAssetTypeModal] = useState(false);
  const [syncDevices, setSyncDevices] = useState(false);

  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: false
  });
  const [assetTypes, setAssetTypes] = useState({ content: [] });
  const [initialSelected, setInitialSeletected] = useState({});

  // Estado para armazenar o tempo restante
  const [deviceTypesCooldown, setDeviceTypesCooldown] = useState(null);
  const [devicesCooldown, setDevicesCooldown] = useState(null);

  const [dattoDeviceTypes, setDattoDeviceTypes] = useState([]);

  const [settings, setSettings] = useState({
    mapDevices: false,
    overwriteAssetName: false
  });

  useEffect(() => {
    getSettings();
    getAssetTypes();
    getDattoDeviceTypes();
    checkCooldowns();
  }, []);

  useEffect(() => {
    // Verificação periódica do cooldown a cada minuto
    const intervalId = setInterval(() => {
      checkCooldowns();
    }, 60000); // 1 minuto

    return () => clearInterval(intervalId);
  }, [deviceTypesCooldown, devicesCooldown]);

  const checkCooldowns = () => {
    const updateCooldown = (key, setCooldown) => {
      const storedCooldown = localStorage.getItem(key);
      if (storedCooldown) {
        const { minutesLeft, timestamp } = JSON.parse(storedCooldown);
        const timeRemaining = calculateTimeRemaining(minutesLeft, timestamp);
        setCooldown(prevTime => {
          const newTime = timeRemaining > 0 ? timeRemaining : 0;
          if (newTime === 0) {
            localStorage.removeItem(key); // Remove o cooldown se o tempo expirar
          }
          return newTime;
        });
      }
    };

    updateCooldown('deviceTypesCooldown', setDeviceTypesCooldown);
    updateCooldown('devicesCooldown', setDevicesCooldown);
  };


  // Função para calcular o tempo restante
  const calculateTimeRemaining = (minutesLeft, timestamp) => {
    const currentTime = Date.now();
    const elapsed = Math.floor((currentTime - timestamp) / 60000); // Minutos desde a última sincronização
    return Math.max(minutesLeft - elapsed, 0); // Minutos restantes
  };

  //integration settings
  const getSettings = async () => {
    const response = await api.apiCredentials.dattoRMM.settings.find();

    if (response.id) {
      setSettings(response);
      setSyncDevices(response.mapDevices);
    }
  };
  const editSettings = async () => {
    const response = await api.apiCredentials.dattoRMM.settings.edit(settings);

    handleRequest(response);
  };

  //asset types
  const getAssetTypes = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.assetTypes.list(queryParams);

    if (response.content) {
      setAssetTypes(response);
      fillSelectedValues(response);
    }

  };
  const fillSelectedValues = (response) => {
    const initialValue = {};
    response.content.forEach(type => {
      initialValue[type.id] = type.dattoDeviceType ? type.dattoDeviceType.id : '';
    });
    setInitialSeletected(initialValue);
  };
  // Função para lidar com alterações na seleção de tipos (1-N)
  const handleSelectChange = useCallback((typeId, selectedTypeId) => {
    setInitialSeletected(prevState => ({
      ...prevState,
      [typeId]: parseInt(selectedTypeId)
    }));
  });

  //datto device types
  const getDattoDeviceTypes = async () => {
    const response = await api.dattoDeviceTypes.list();

    if (Array.isArray(response)) {
      setDattoDeviceTypes(response);
    }
  };

  // Função para lidar com a sincronização de tipos de dispositivos
  const forceSyncDeviceTypes = async () => {
    setLoading(true);
    const request = await api.dattoSync.deviceTypes();
    if (request.ok) {
      getDattoDeviceTypes();
      setDeviceTypesCooldown(DEFAULT_COOLDOWN_MINUTES); // Resetar cooldown
      localStorage.setItem('deviceTypesCooldown', JSON.stringify({
        minutesLeft: DEFAULT_COOLDOWN_MINUTES,
        timestamp: Date.now()
      }));
      setSuccess("Sincronização feita com sucesso");
      setShowSuccess(true);
    } else {
      const response = await request.json();
      const errorMessage = response.userMessage;
      setError(errorMessage);
      setShowError(true);
      calculateRemainingTime(errorMessage, 'deviceTypesCooldown');
    }
    setLoading(false);
  };

  // Função para lidar com a sincronização de dispositivos
  const forceSyncDevices = async () => {
    setLoading(true);
    const request = await api.dattoSync.devices();
    if (request.ok) {
      setDevicesCooldown(DEFAULT_COOLDOWN_MINUTES);
      localStorage.setItem('devicesCooldown', JSON.stringify({
        minutesLeft: DEFAULT_COOLDOWN_MINUTES,
        timestamp: Date.now()
      }));
      setSuccess("Sincronização feita com sucesso");
      setShowSuccess(true);
    } else {
      const response = await request.json();
      const errorMessage = response.userMessage;
      setError(errorMessage);
      setShowError(true);
      calculateRemainingTime(errorMessage, 'devicesCooldown');
    }
    setLoading(false);
  };

  // Função para calcular o tempo restante a partir da mensagem de erro
  function calculateRemainingTime(errorMessage, cooldownKey) {
    const match = errorMessage.match(/(\d+) minutos/);
    if (match) {
      const minutesLeft = parseInt(match[1], 10);
      const timestamp = Date.now();

      if (cooldownKey === 'deviceTypesCooldown') {
        setDeviceTypesCooldown(minutesLeft);
      } else if (cooldownKey === 'devicesCooldown') {
        setDevicesCooldown(minutesLeft);
      }

      localStorage.setItem(cooldownKey, JSON.stringify({
        minutesLeft,
        timestamp
      }));
    }
  };

  //Salvar associações e desassociações
  const handleSave = async () => {
    const associations = [];
    const disassociations = [];

    assetTypes.content.forEach(type => {
      const typeId = type.id;
      const originalDattoDeviceType = type.dattoDeviceType ? type.dattoDeviceType.id : null;
      const selectedDattoDeviceType = initialSelected[typeId] || null;

      // Se o cliente selecionado for diferente do original
      if (selectedDattoDeviceType && selectedDattoDeviceType !== originalDattoDeviceType) {
        // Se houver um cliente novo, ele será associado
        associations.push({ assetTypeId: parseInt(typeId), deviceTypeId: parseInt(selectedDattoDeviceType) });
      }

      // Se o cliente original foi desmarcado (seleção nula ou vazia)
      if (originalDattoDeviceType && !selectedDattoDeviceType) {
        disassociations.push(parseInt(typeId));
      }
    });

    let associateSuccess = false;
    let disassociateSuccess = false;

    // Faz a associação dos clientes
    if (associations.length > 0) {
      const response = await api.assetTypes.dattoDeviceTypes.batchAssociate(associations);
      if (response.ok) {
        associateSuccess = true;
        setShowSuccess(true);
      } else {
        setError(response.userMessage);
        setShowError(true);
      }
    }

    // Faz a desassociação dos clientes
    if (disassociations.length > 0) {
      const response = await api.assetTypes.dattoDeviceTypes.batchDisassociate(disassociations);
      if (response.ok) {
        disassociateSuccess = true;
        setShowSuccess(true);
      } else {
        setError(response.userMessage);
        setShowError(true);
      }
    }

    // Se houver sucesso em uma das operações, recarrega os sites
    if (associateSuccess || disassociateSuccess) {
      getAssetTypes();
      setSuccess("Mapeamento alterado com sucesso");
    }
  }

  const handleRequest = (request) => {
    if (request.id) {
      setSuccess("Configurações alterada com sucesso");
      setShowSuccess(true);
      getSettings();
    } else {
      setError(request.userMessage);
      setShowError(true);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <AssetTypeRegistrationModal
        show={showAssetTypeModal}
        close={() => setShowAssetTypeModal(false)}
        refresh={() => getAssetTypes()} />
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <Form>
        <p className="fs-9 fw-bold mb-3">
          Configurações de mapeamento
        </p>

        <Form.Group as={Flex} alignItems="center" className="mb-2">
          <Form.Check
            type="switch"
            value={settings.mapDevices}
            onChange={() => setSettings({ ...settings, mapDevices: !settings.mapDevices })}
            checked={settings.mapDevices}
          />
          <Form.Label className="m-0">
            Sincronizar dispositivos</Form.Label>
          <OverlayTrigger overlay={<Tooltip>A sincronização de dispositivos é feita todos os dias às 03:00, horário de Brasilia.</Tooltip>}>
            <span>
              <FontAwesomeIcon icon={faInfoCircle} size="sm" className="ms-2" />
            </span>
          </OverlayTrigger>
        </Form.Group>

        <Form.Group as={Flex} alignItems="center" className="w-100">
          <Form.Check
            type="switch"
            value={settings.overwriteAssetName}
            onChange={() => setSettings({ ...settings, overwriteAssetName: !settings.overwriteAssetName })}
            checked={settings.overwriteAssetName}
          />
          <Form.Label className="m-0">Substituir nome do ativo quando diferente do hostname RMM</Form.Label>
        </Form.Group>
      </Form>

      <Flex className="w-100" justifyContent="end">

        <Button
          size="sm"
          className="me-2"
          onClick={() => forceSyncDeviceTypes()}
          disabled={deviceTypesCooldown !== null && deviceTypesCooldown > 0}>
          <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />

          {deviceTypesCooldown !== null && deviceTypesCooldown > 0
            ? `Nova sinc. disponível em ${deviceTypesCooldown} minutos`
            : 'Sincronizar Tipos de Disp.'}
        </Button>
        <Button
          size="sm"
          className="me-2"
          onClick={() => forceSyncDevices()}
          disabled={devicesCooldown !== null && devicesCooldown > 0 || !syncDevices}>
          <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />

          {devicesCooldown !== null && devicesCooldown > 0
            ? `Nova sinc. disponível em ${devicesCooldown} minutos`
            : 'Sincronizar Dispositivos'}
        </Button>
        <Button
          size="sm"
          className="me-2"
          onClick={() => setShowAssetTypeModal(true)}>
          <FontAwesomeIcon icon={faPlus} className="me-1" />
          Criar Tipo de Ativo
        </Button>
        <Button onClick={() => editSettings()} size="sm">
          <FontAwesomeIcon icon={faSave} className="me-1" />
          Salvar
        </Button>
      </Flex>

      <Row className="mt-3">
        <Col>
          <Alert className="w-100 text-truncation fs-10 p-2" variant="warning">
            <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
            <span>
              Para garantir a sincronização correta dos dispositivos, é essencial que os tipos de ativos estejam devidamente mapeados com os tipos de dispositivos correspondentes. Se algum tipo não estiver mapeado corretamente, a sincronização poderá falhar.
            </span>
          </Alert>
        </Col>
      </Row>

      <Table responsive>
        <thead>
          <tr>
            <th className="text-900 fs-10">Tipo de Ativo</th>
            <th className="w-75 text-900 fs-10">Tipo de Dispositivo Datto RMM</th>
          </tr>
        </thead>
        <tbody>
          {assetTypes.content.map(type => (
            <tr key={type.id}>
              <td className="fs-10">{type.name}</td>
              <td>
                <Form.Select
                  value={initialSelected[type.id] || ''}
                  onChange={e => handleSelectChange(type.id, e.target.value)}>
                  <option value="" className="text-danger">Não mapeado</option>
                  {dattoDeviceTypes.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Flex className="w-100" justifyContent="end">
        <Button onClick={() => handleSave()} size="sm">
          <FontAwesomeIcon icon={faSave} className="me-1" />
          Salvar
        </Button>
      </Flex>

      <Pagination
        data={assetTypes}
        filters={filters}
        setFilters={setFilters}
      />
    </>
  );
};

export default DattoDevices;