import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Cookies from 'js-cookie';
import 'moment/locale/pt-br';
import moment from 'moment/moment';

dayjs.extend(duration);
moment.locale('pt-br');

export const isIterableArray = array => Array.isArray(array) && !!array.length;

export const priorities = [
  {
    name: 'Crítico',
    value: 'CRITICAL',
    color: '#e63757',
    data: 100
  },
  {
    name: 'Alta',
    value: 'HIGH',
    color: '#F4C900',
    data: 75
  },
  {
    name: 'Média',
    value: 'MEDIUM',
    color: '#00D27B',
    data: 50
  },
  {
    name: 'Baixa',
    value: 'LOW',
    color: '#2A7BE4',
    data: 25
  },
  {
    name: 'Planejado',
    value: 'PLANNED',
    color: '#868686',
    data: 0
  }
];

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key));
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match(
    '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
  );
  return value ? value.pop() : null;
};

export const createCookie = (name, value) => {
  Cookies.set(name, value, { expires: 1 });
}


export const getMyCompanyId = () => {
  return Cookies.get('companyId');
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
      ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(number)) >= 1.0e3
        ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
        : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ]
    : null;
};

export const priorityMap = {
  PLANNED: { label: "Planejado", color: "#868686" },
  LOW: { label: "Baixa", color: "#2A7BE4" },
  MEDIUM: { label: "Média", color: "#00D27B" },
  HIGH: { label: "Alta", color: "#F4C900" },
  CRITICAL: { label: "Crítico", color: "#e63757" }
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

// export const colors = [
//   '#2c7be5',
//   '#00d97e',
//   '#e63757',
//   '#39afd1',
//   '#fd7e14',
//   '#02a8b5',
//   '#727cf5',
//   '#6b5eae',
//   '#ff679b',
//   '#f6c343'
// ];

export const colors = [
  '#C0FB50', // Amarelo-limão
  '#0C446E', // Azul escuro
  '#414941', // Verde escuro
  '#89D709', // Verde brilhante

  '#1a4f77', // Azul aço
  '#96EC09', // Verde brilhante variante
  '#295a7f', // Azul intenso
  '#556B2F', // Verde oliva escuro
  '#0b4068', // Azul profundo
  '#4c544c', // Verde escuro profundo
  '#0b3c61', // Azul ardósia
  '#262b26', // Verde primavera
  '#457090', // Azul real
  '#b5ec4b', // Verde marinho

  '#8A2BE2', // Azul violeta
  '#9370DB', // Violeta médio
  '#800080', // Roxo escuro

  '#fd7e14', // Laranja
  '#FF4500', // Laranja avermelhado
  '#FFA500', // Laranja

  '#FF1493', // Rosa vívido
  '#FF69B4', // Rosa intenso

  '#00d97e', // Verde esmeralda
  '#39afd1', // Azul turquesa
  '#02a8b5', // Azul turquesa mais escuro

  '#727cf5', // Azul lavanda
  '#6b5eae', // Azul lavanda escuro
  '#ff679b', // Rosa claro

  '#f6c343', // Amarelo mostarda

  '#00CED1', // Azul turquesa claro
  '#20B2AA', // Verde mar
  '#40E0D0', // Turquesa

  '#BA55D3', // Orquídea média
  '#8B4513', // Marrom sela
  '#DC143C', // Vermelho carmesim
  '#00FA9A', // Verde médio

  '#D2691E', // Marrom chocolate
  '#6B8E23', // Verde oliva
  '#FFA07A', // Salmão claro

  '#4B0082', // Índigo
  '#DAA520', // Amarelo dourado escuro
  '#87CEEB', // Azul céu
];


export const colorsSecondary = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343',
  '#96EC09', // roxo
  '#FF1493', // rosa vívido
  '#1E90FF', // azul intenso
  '#32CD32', // verde-limão
  '#FFD700', // amarelo dourado
  '#FF6347', // coral
  '#4169E1', // azul real
  '#FF4500', // laranja avermelhado
  '#00CED1', // azul turquesa
  '#9932CC', // violeta profundo
  '#FFA500', // laranja
  '#00FF7F', // verde primavera
  '#8A2BE2', // azul violeta
  '#FF69B4', // rosa intenso
  '#20B2AA', // verde mar
  '#FF8C00', // laranja escuro
  '#4682B4', // azul aço
  '#DAA520', // amarelo dourado escuro
  '#87CEEB', // azul céu
  '#FF6347', // coral
  '#40E0D0', // turquesa
  '#BA55D3', // orquídea média
  '#8B4513', // marrom sela
  '#00FF00', // verde-limão
  '#800080', // roxo escuro
  '#20B2AA', // verde mar
  '#FFD700', // amarelo dourado
  '#FF69B4', // rosa intenso
  '#8B008B', // violeta escuro
  '#00BFFF', // azul profundo
  '#FFA500', // laranja
  '#2E8B57', // verde marinho
  '#DC143C', // vermelho carmesim
  '#00FA9A', // verde médio
  '#9932CC', // violeta profundo
  '#D2691E', // marrom chocolate
  '#FF1493', // rosa vívido
  '#6495ED', // azul ardósia
  '#FF4500', // laranja avermelhado
  '#9370DB', // violeta médio
  '#DAA520', // amarelo dourado escuro
  '#6B8E23', // verde oliva
  '#8A2BE2', // azul violeta
  '#FFA07A', // salmão claro
  '#1E90FF', // azul intenso
  '#8B4513', // marrom sela
  '#4B0082', // índigo
  '#FF6347', // coral
  '#4682B4', // azul aço
  '#FFD700', // amarelo dourado
  '#2E8B57', // verde marinho
  '#BA55D3', // orquídea média
  '#FF8C00', // laranja escuro
  '#1E90FF', // azul intenso
  '#8B008B', // violeta escuro
  '#FF69B4', // rosa intenso
];


export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};

export const classColors = [
  'success',
  'info',
  'warning',
  'danger',
  'dark',
  'secondary'
];

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

//===============================

// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});
//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);
export const getSubtotal = items =>
  items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) =>
  total - total * (discount / 100);

export const getProductsQuantity = products =>
  products.reduce((acc, product) => product.quantity + acc, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const camelize = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = str => {
  return str.toLowerCase().replaceAll(' ', '-');
};

//routes helper

export const flatRoutes = childrens => {
  const allChilds = [];

  const flatChild = childrens => {
    childrens.forEach(child => {
      if (child.children) {
        flatChild(child.children);
      } else {
        allChilds.push(child);
      }
    });
  };
  flatChild(childrens);

  return allChilds;
};

export const getFlatRoutes = children =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children)
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val]
        };
      }
    },
    { unTitled: [] }
  );

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const reactBootstrapDocsUrl = 'https://react-bootstrap.github.io';

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const tooltipFormatter = params => {
  let tooltipItem = ``;
  params.forEach(el => {
    tooltipItem =
      tooltipItem +
      `<div class='ms-1'>
        <h6 class="text-700"><span class="fas fa-circle me-1 fs--2" style="color:${el.borderColor ? el.borderColor : el.color
      }"></span>
          ${el.seriesName} : ${typeof el.value === 'object' ? el.value[1] : el.value
      }
        </h6>
      </div>`;
  });
  return `<div>
            <p class='mb-2 text-600'>
              ${dayjs(params[0].axisValue).isValid()
      ? dayjs(params[0].axisValue).format('MMMM DD')
      : params[0].axisValue
    }
            </p>
            ${tooltipItem}
          </div>`;
};

export const addIdField = items => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item
  }));
};

// get file size

export const getSize = size => {
  if (size < 1024) {
    return `${size} Byte`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};

/* Get A Random Number */
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

/* get Dates between */

export const getDates = (
  startDate,
  endDate,
  interval = 1000 * 60 * 60 * 24
) => {
  const duration = endDate - startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};

export const formatValue = value => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
    // Formato: YYYY-MM-DD
    return dayjs(value).format('DD/MM');
  } else if (/^\d{4}-\d{2}$/.test(value)) {
    // Formato: YYYY-MM
    return dayjs(value).format('MM/YYYY');
  } else if (/^\d{4}-Q\d$/.test(value)) {
    // Formato: YYYY-QN
    const [year, quarter] = value.split('-Q');
    return `Q${quarter}/${year}`;
  } else if (/^\d{4}-\d{2}-W\d$/.test(value)) {
    // Formato: YYYY-MM-WN
    const [yearMonth, week] = value.split('-W');
    return `Sem ${week} de ${dayjs(yearMonth).format('MMM')}`;
  } else {
    // Formato padrão
    return value;
  }
};

/* Get Past Dates */
export const getPastDates = duration => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};

export const formatDate = date => {
  let d = new Date(date);
  let dayOfMonth = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  return `${dayOfMonth}/${month}/${d.getFullYear()}`;
};

export const formatDateUs = date => {
  let d = new Date(date);
  let dayOfMonth = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  return `${d.getFullYear()}-${month}-${dayOfMonth}`;
};

export const formatDateTimeUs = date => {
  let d = new Date(date);
  let dayOfMonth = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  return `${d.getFullYear()}-${month}-${dayOfMonth}T${hours}:${min}`;
};

export const daysOfWeek = [
  {
    label: "Domingo",
    value: "SUNDAY"
  },
  {
    label: "Segunda-feira",
    value: "MONDAY"
  },
  {
    label: "Terça-feira",
    value: "TUESDAY"
  },
  {
    label: "Quarta-feira",
    value: "WEDNESDAY"
  },
  {
    label: "Quinta-feira",
    value: "THURSDAY"
  },
  {
    label: "Sexta-feira",
    value: "FRIDAY"
  },
  {
    label: "Sábado",
    value: "SATURDAY"
  },
];

export const getDayName = (dayOfWeek) => {
  const days = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
  return days[dayOfWeek - 1];
};

export const getDaysOfMonth = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const daysOfMonth = [];

  for (let day = 1; day <= lastDayOfMonth; day++) {
    daysOfMonth.push(day);
  }

  return daysOfMonth;

};

export const formatDateTime = date => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
};

export const formatYear = date => {
  const d = new Date(date);
  return `${d.getFullYear()}`;
}

export const formatCustomizerDate = date => {
  return moment(date).format('LL');
};

export const formatHours = date => {
  return moment(date).format('LT');
};

export const formatDateInFromNow = date => {
  return moment(date).fromNow();
};

export const formatTime = time => {
  return time.substr(0, time.lastIndexOf(":"));
};

export function formatTimeToHoursAndMinutes(timeString) {
  if (timeString) {
    const [hours, minutes] = timeString.split(":");
    return `${hours}:${minutes}`;
  } else {
    return "00:00";
  }
}

export function getStartOfWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek);
  return startOfWeek;
}


export const translationMap = {
  priority: {
    Low: 'Baixa',
    Medium: 'Média',
    High: 'Alta',
    Urgent: 'Urgente'
  },

  status: {
    'To-Do': 'A fazer',
    'In progress': 'Atendendo',
    Pending: 'Pausado',
    Completed: 'Concluído'
  }
};

export const dattoDeviceProperties = [
  { value: 'uid', name: 'Uid', dataType: 'TEXT' },
  { value: 'siteUid', name: 'Site Uid', dataType: 'TEXT' },
  { value: 'siteName', name: 'Nome do Site', dataType: 'TEXT' },
  { value: 'hostname', name: 'Nome do Dispositivo', dataType: 'TEXT' },
  { value: 'deviceTypeCategory', name: 'Categoria do Tipo de Dispositivo', dataType: 'TEXT' },
  { value: 'deviceTypeType', name: 'Tipo do Dispositivo', dataType: 'TEXT' },
  { value: 'operatingSystem', name: 'Sistema Operacional', dataType: 'TEXT' },
  { value: 'description', name: 'Descrição', dataType: 'TEXT' },
  { value: 'lastAuditDate', name: 'Data da Última Auditoria', dataType: 'DATETIME' },
  { value: 'intIpAddress', name: 'Endereço IP Interno', dataType: 'TEXT' },
  { value: 'extIpAddress', name: 'Endereço IP Externo', dataType: 'TEXT' },
  { value: 'domain', name: 'Domínio', dataType: 'TEXT' },
  { value: 'lastLoggedUser', name: 'Último Usuário Conectado', dataType: 'TEXT' },
  { value: 'a64Bit', name: '64 Bits', dataType: 'CHECKBOX' },
  { value: 'portalUrl', name: 'URL do Portal', dataType: 'TEXT' },
  { value: 'antivirusStatus', name: 'Status do Antivírus', dataType: 'TEXT' },
  { value: 'patchStatus', name: 'Status do Patch', dataType: 'TEXT' },
  { value: 'softwareStatus', name: 'Status do Software', dataType: 'TEXT' },
  { value: 'webRemoteUrl', name: 'URL do Web Remote', dataType: 'TEXT' },
  { value: 'deleted', name: 'Deletado', dataType: 'CHECKBOX' },
  { value: 'manufacturer', name: 'Fabricante', dataType: 'TEXT' },
  { value: 'model', name: 'Modelo', dataType: 'TEXT' },
  { value: 'totalPhysicalMemory', name: 'Memória RAM Total', dataType: 'INTEGER' },
  { value: 'totalCpuCores', name: 'Total de Núcleos da CPU', dataType: 'INTEGER' },
  { value: 'serialNumber', name: 'Número de Série', dataType: 'TEXT' },
  { value: 'processor', name: 'Processador', dataType: 'TEXT' },
  { value: 'baseBoard', name: 'Placa Mãe', dataType: 'TEXT' }
];


// Add id to items in array
export const addId = items =>
  items.map((item, index) => ({
    id: index + 1,
    ...item
  }));

//
export const getTimeDuration = (startDate, endDate, format = '') => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};

// Get Percentage
export const getPercentage = (number, percent) => {
  return (Number(number) / 100) * Number(percent);
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

// Slugify text
export const slugifyText = str =>
  str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');