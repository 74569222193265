import { faFilter, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useApi from 'helpers/api';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DateFilterQueryItem from './DateFilterQueryItem';
import FilterQueryItem from './FilterQueryItem';

const mapServerDataToFilterQueryOptions = (serverData, labelProp, valueProp) => {
  return serverData.map(item => ({
    label: item[labelProp],
    value: item[valueProp].toString() // Convert value to string
  }));
};

const FilterQueryGroup = ({ onFilterChange, refreshData }) => {
  const api = useApi();
  const [customers, setCustomers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedServiceGroups, setSelectedServiceGroups] = useState([]);
  const [selectedInitialCreationDate, setSelectedInitialCreationDate] = useState('');
  const [selectedEndCreationDate, setSelectedEndCreationDate] = useState('');
  const [clearDate, setClearDate] = useState(false);
  const [showValueCount, setShowValueCount] = useState({});
  const [openMenu, setOpenMenu] = useState(null);
  const [filters] = useState({ size: 100, includeInactives: false });
  const queryParams = new URLSearchParams(filters).toString();
  const priorityValues = [
    { label: 'Planejado', value: 'PLANNED' },
    { label: 'Baixa', value: 'LOW' },
    { label: 'Média', value: 'MEDIUM' },
    { label: 'Alta', value: 'HIGH' },
    { label: 'Crítica', value: 'CRITICAL' }
  ];

  const clearFilters = () => {
    setSelectedPriorities([]);
    setSelectedAgents([]);
    setSelectedCustomers([]);
    setSelectedServiceGroups([]);
    setSelectedInitialCreationDate('');
    setSelectedEndCreationDate('');
    setShowValueCount({});
    setClearDate(true);
  };

  useEffect(() => {
    setClearDate(false);
  }, [selectedInitialCreationDate, selectedEndCreationDate]);

  useEffect(() => {
    const getData = async () => {
      const [resCustomers, resAgents, resServiceGroups] = await Promise.all([
        api.customers.list(queryParams),
        api.agents.list(queryParams),
        api.serviceGroups.listSummaryData(queryParams)
      ]);

      if (resCustomers.content) {
        setCustomers(
          mapServerDataToFilterQueryOptions(resCustomers.content, 'name', 'id')
        );
      }
      if (resAgents.content) {
        setAgents(
          mapServerDataToFilterQueryOptions(resAgents.content, 'firstName', 'id')
        );
      }
      if (resServiceGroups.content) {
        setServiceGroups(
          mapServerDataToFilterQueryOptions(resServiceGroups.content, 'name', 'id')
        );
      }
    };
    getData();
  }, []);

  return (
    <Row className="ms-n3 px-2">
      <Col className="d-flex justify-content-start align-items-center col-filters">
        <div className={showValueCount.priority ? 'selected' : ''}>
          <FilterQueryItem
            title="Prioridade"
            options={priorityValues}
            onFilterChange={values => {
              setSelectedPriorities(values);
              setShowValueCount(prevState => ({
                ...prevState,
                priority: values.length > 0
              }));
            }}
            showValueCount={showValueCount.priority}
            selectedValues={selectedPriorities}
            isOpen={openMenu === 'priority'}
            onToggle={() => setOpenMenu(openMenu === 'priority' ? null : 'priority')}
          />
        </div>

        <div className={showValueCount.agent ? 'selected' : ''}>
          <FilterQueryItem
            title="Agentes"
            options={agents}
            onFilterChange={values => {
              setSelectedAgents(values);
              setShowValueCount(prevState => ({ ...prevState, agent: values.length > 0 }));
            }}
            showValueCount={showValueCount.agent}
            selectedValues={selectedAgents}
            isOpen={openMenu === 'agent'}
            onToggle={() => setOpenMenu(openMenu === 'agent' ? null : 'agent')}
          />
        </div>

        <div className={showValueCount.customer ? 'selected' : ''}>
          <FilterQueryItem
            title="Clientes"
            options={customers}
            onFilterChange={values => {
              setSelectedCustomers(values);
              setShowValueCount(prevState => ({
                ...prevState,
                customer: values.length > 0
              }));
            }}
            showValueCount={showValueCount.customer}
            selectedValues={selectedCustomers}
            isOpen={openMenu === 'customer'}
            onToggle={() => setOpenMenu(openMenu === 'customer' ? null : 'customer')}
          />
        </div>

        <div className={showValueCount.serviceGroup ? 'selected' : ''}>
          <FilterQueryItem
            title="Grupos de serviço"
            options={serviceGroups}
            onFilterChange={values => {
              setSelectedServiceGroups(values);
              setShowValueCount(prevState => ({
                ...prevState,
                serviceGroup: values.length > 0
              }));
            }}
            showValueCount={showValueCount.serviceGroup}
            selectedValues={selectedServiceGroups}
            isOpen={openMenu === 'serviceGroup'}
            onToggle={() => setOpenMenu(openMenu === 'serviceGroup' ? null : 'serviceGroup')}
          />
        </div>

        <div
          onClick={() => setOpenMenu('period')}
          className={showValueCount.creationDate ? 'selected' : ''}>
          <DateFilterQueryItem
            title="Período"
            onFilterChange={(startDate, endDate) => {
              setSelectedInitialCreationDate(startDate);
              setSelectedEndCreationDate(endDate);
              setShowValueCount(prevState => ({
                ...prevState,
                creationDate: !!startDate
              }));
            }}
            clearDate={clearDate}
            isOpen={openMenu === 'period'}
          />
        </div>
      </Col>
      <Col className="d-flex justify-content-start align-items-center col-filters2">
        <Button
          variant='outline-primary'
          className="me-1 mb-1"
          style={{ fontSize: 10 }}
          type="button"
          onClick={() =>
            onFilterChange({
              priority: selectedPriorities,
              agent: selectedAgents,
              customer: selectedCustomers,
              serviceGroup: selectedServiceGroups,
              initialCreationDate: selectedInitialCreationDate || '',
              endCreationDate: selectedEndCreationDate || ''
            })
          }
        >
          <FontAwesomeIcon icon={faFilter} />
        </Button>

        <Button
          variant='outline-danger'
          className="me-1 mb-1"
          style={{ fontSize: 10 }}
          type="button"
          onClick={() => {
            onFilterChange({
              priority: [],
              agent: [],
              customer: [],
              serviceGroup: [],
              initialCreationDate: '',
              endCreationDate: ''
            });
            clearFilters();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>

        <Button
          variant='outline-info'
          className="me-1 mb-1"
          style={{ fontSize: 10 }}
          type="button"
          onClick={refreshData}
        >
          <FontAwesomeIcon icon={faSyncAlt} />
        </Button>
      </Col>
    </Row>
  );
};

export default FilterQueryGroup;
