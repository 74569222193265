import DOMPurify from "dompurify";
import { formatDateTime } from "helpers/utils";
import { Col, Modal, Row } from "react-bootstrap";

const TicketAppointmentViewModal = ({ data, showModal, handleClose }) => {
  const sanitizedHtml = DOMPurify.sanitize(data.description);
  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Visualizar apontamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col md={4}>
            <h6>Tipo</h6>
            <p>{data.typeService && data.typeService.description}</p>
          </Col>

          <Col md={4}>
            <h6>Dia</h6>
            <p>{formatDateTime(data.date)}</p>
          </Col>

          <Col md={4}>
            <h6>Horas</h6>
            <p>{data.timeSpent}</p>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h6>Descrição</h6>
            <div className="w-100 overflow-x-hidden" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default TicketAppointmentViewModal;