import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiSelect from "components/common/MultiSelect";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const SubcategoryRegistrationModal = ({ showModal, handleClose, handleSubmit, parentCategory, serviceCatalogId }) => {
  const api = useApi();
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState({ name: '' });
  const [categories, setCategories] = useState({ content: [] });
  const [formattedCategories, setFormattedCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState({});
  const form = useRef(null);

  useEffect(() => {
    setFormattedCategories(categories.content.map(item => ({
      value: item.id,
      label: item.name
    })));
  }, [categories]);

  useEffect(() => {
    if (showModal) {
      setBody({ name: '', categories: [] });
      setValidated(false);
    }
  }, [showModal]);

  useEffect(() => {
    const getCategories = async () => {
      const filter = { size: 100 };
      const queryParams = new URLSearchParams(filter).toString();
      let response = await api.categories.listByServiceCatalog(serviceCatalogId, queryParams);
      setCategories(response);
    };
    getCategories();
    setDefaultCategory(formattedCategories.find(item => item.value == parentCategory.id));
  }, [parentCategory]);

  useEffect(() => {
    setSelectedCategories([defaultCategory]);
  }, [defaultCategory]);

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({ name: body.name, categories: selectedCategories.map(item => item.value) });
      setBody({ name: '', categories: [] });
      setValidated(false);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Nova subcategoria</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nome"
              autoFocus
              value={body.name}
              onChange={e => setBody(prevBody => ({ ...prevBody, name: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Categoria <span className="text-danger">*</span></Form.Label>
            <MultiSelect
              value={selectedCategories}
              options={formattedCategories}
              placeholder='Selecione as categorias'
              onChange={selectedOptions => setSelectedCategories(selectedOptions)} />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Escolha uma opção!</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" type="submit" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubcategoryRegistrationModal;