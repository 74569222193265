import FailedRequest from 'components/alerts/FailedRequest';
import SuccessRequest from 'components/alerts/SuccessRequest';
import Flex from 'components/common/Flex';
import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const RunNewAnalysisModal = ({ show, close, refreshData }) => {
  const api = useApi();
  const [mapAll, setMapAll] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    show && setMapAll(false);
  }, [show]);

  const map = async () => {
    const response = await api.dattoSites.map(mapAll);
    if (response.ok) {
      setShowSuccess(true);
      refreshData();
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
    close();
  };

  return (
    <>
      <SuccessRequest
        message={"Análise feita com sucesso"}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <Modal show={show} onHide={close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Executar uma nova análise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label className='mb-3'>
                Esta ferramenta mapeará automaticamente os clientes MSP Desk aos Sites com base no nome fantasia. Antes de
                executar a ferramenta de Análise, selecione como os mapeamentos existentes devem ser manipulados:
              </Form.Label>
              <Form.Check
                type="radio"
                label="Mantenha os mapeamentos existentes e analise apenas os clientes MSP Desk não capturados"
                value={mapAll}
                checked={!mapAll}
                onChange={() => setMapAll(false)} />
              <Form.Check
                type="radio"
                label="Apague todos os mapeamentos existentes e analise todos os clientes MSP Desk"
                value={mapAll}
                checked={mapAll}
                onChange={() => setMapAll(true)} />
            </Form.Group>

            <Form.Group as={Flex} justifyContent="end" className='w-100'>
              <Button variant="link" onClick={close} className='me-2'>Cancelar</Button>
              <Button variant="primary" onClick={map}>Executar análise</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RunNewAnalysisModal;