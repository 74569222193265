import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import { account, generals, sla, team, ticket } from 'data/support-desk/quickLinksData';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AdminItem = ({ item }) => {
  return (
    <Col md={4} xxl={4}>
      <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
        {item.isNew && (
          <Flex justifyContent="end" className='w-100'>
            <SubtleBadge bg='success'>BETA</SubtleBadge>
            <div className='w-25'></div>
          </Flex>
        )}
        <div
          className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-soft-${item.color}`}
        >
          {item.icon}
        </div>
        <h5 className="mt-3 mb-2">
          <Link to={item.to} className="text-900 hover-primary stretched-link me-2">
            {item.title}
          </Link>
        </h5>
        <h6 className="w-75 mx-auto text-600 mb-x1">{item.description}</h6>
      </div>
    </Col>
  );
};

AdminItem.propTypes = {
  item: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isNew: PropTypes.bool
  })
};

export const Generals = () => {
  return (
    <Card className="mt-3">
      <Card.Header className="border-bottom border-200">
        <h6 className="mb-0">Geral</h6>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3">
          {generals.map((item, index) => (
            <AdminItem key={index} item={item} />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};


export const Ticket = () => {
  return (
    <Card className="mt-3">
      <Card.Header className="border-bottom border-200">
        <h6 className="mb-0">Ticket</h6>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3">
          {ticket.map((item, index) => (
            <AdminItem key={index} item={item} />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};


export const Sla = () => {
  return (
    <Card className="mt-3">
      <Card.Header className="border-bottom border-200">
        <h6 className="mb-0">Configurações de SLA</h6>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3">
          {sla.map((item, index) => (
            <AdminItem key={index} item={item} />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export const Team = () => {
  return (
    <Card>
      <Card.Header className="border-bottom border-200">
        <h6 className="mb-0">Equipe</h6>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3">
          {team.map((item, index) => (
            <AdminItem key={index} item={item} />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export const Account = () => {
  return (
    <Card className="mt-3">
      <Card.Header className="border-bottom border-200">
        <h6 className="mb-0">Conta</h6>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3">
          {account.map((item, index) => (
            <AdminItem key={index} item={item} />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};