import { faEye, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TicketsByCatalogReport from "components/charts/TicketsByCatalogReport";
import TicketsByCategory from "components/charts/by-category/TicketsByCategory";
import TicketsByPriority from "components/charts/by-priority/TicketsByPriority";
import TicketsByTypeTicket from "components/charts/by-type-ticket/TicketsByTypeTicket";
import DatePickerComponent from "components/common/DatePickerComponent";
import Flex from "components/common/Flex";
import MultiSelect from "components/common/MultiSelect";
import useApi from 'helpers/api';
import { formatDate, formatDateUs } from "helpers/utils";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import SummaryExecutive from "../SummaryExecutive";
import '../styles.css';
import AppointmentsExecutiveCustomer from "./AppointmentsExecutiveCustomer";
import CatalogStatistics from "./CatalogStatistics";

const ExecutiveCustomer = () => {
  const api = useApi();
  const date = new Date();

  const [showReports, setShowReports] = useState(false);
  const filtersCustomerQuery = {
    includeInactives: false,
    size: 500,
    page: 0
  };
  const [filters, setFilters] = useState({
    customers: [],
    typeDate: 'CREATION_DATE',
    initialDate: '',
    endDate: ''
  });
  const [customers, setCustomers] = useState([]);
  const [formattedCustomers, setFormattedCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 30));
  const [endDate, setEndDate] = useState(new Date());
  const [byCatalog, setByCatalog] = useState([]);
  const [byTypeTicket, setByTypeTicket] = useState([]);
  const [byPriority, setByPriority] = useState([]);
  const [byCategory, setByCategory] = useState([]);
  const [summary, setSummary] = useState({});
  const [appointments, setAppointments] = useState({});
  const [catalogStatistics, setCatalogStatistics] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [company, setCompany] = useState({});
  const [companyLogo, setCompanyLogo] = useState('');
  const reportRef = useRef(null);

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    setFormattedCustomers(customers.map(item => ({
      value: item.id,
      label: item.name
    })))
  }, [customers]);

  useEffect(() => {
    setFilters({ ...filters, customers: selectedCustomers.map(item => item.value) });
  }, [selectedCustomers]);

  useEffect(() => {
    setFilters({ ...filters, initialDate: formatDateUs(startDate), endDate: formatDateUs(endDate) });
  }, [startDate, endDate]);

  const getCustomers = async () => {
    const queryParams = new URLSearchParams(filtersCustomerQuery).toString();
    const response = await api.customers.list(queryParams);
    response.content && setCustomers(response.content);
  };

  const getByCatalog = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.executive.byCatalog(queryParams);
    !response.userMessage && setByCatalog(response);
  };

  const getByTypeTicket = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.executive.byTypeTicket(queryParams);
    !response.userMessage && setByTypeTicket(response);
  };

  const getByPriority = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.executive.byPriority(queryParams);
    !response.userMessage && setByPriority(response);
  };

  const getByCategory = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.executive.byCategory(queryParams);
    !response.userMessage && setByCategory(response);
  };

  const getSummary = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.executive.summary(queryParams);
    !response.userMessage && setSummary(response);
  };

  const getAppointments = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.executive.appointments(queryParams);
    !response.userMessage && setAppointments(response);
  };

  const getCatalogStatistics = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.executive.catalogStatistics(queryParams);
    !response.userMessage && setCatalogStatistics(response);
  };

  useEffect(() => {
    getCompanyId();
  }, []);

  useEffect(() => {
    getCompanyInfo();
    getLogo();

    return () => {
      if (companyLogo) {
        URL.revokeObjectURL(companyLogo);
      }
    };
  }, [companyId]);

  const getCompanyId = async () => {
    const response = await api.companies.getMyCompanyId();
    setCompanyId(response);
  };

  const getCompanyInfo = async () => {
    if (companyId) {
      const response = await api.companies.findById(companyId);
      setCompany(response);
    }
  };

  const getLogo = async () => {
    const request = await api.companies.logo.find(companyId);
    if (request.ok) {
      const blob = await request.blob();
      const url = URL.createObjectURL(blob);
      setCompanyLogo(url);
    }
  };

  const captureContent = (element) => {
    return html2canvas(element, {
      scale: 2, // Reduz a escala para otimizar o tamanho do PDF
      useCORS: true, // Resolve problemas de CORS se houverem imagens externas
    });
  };

  const compressImage = (imgData, quality = 0.75) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imgData;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/jpeg', quality));
      };
    });
  };

  const generatePdf = async () => {
    if (reportRef.current) {
      const pdf = new jsPDF('p', 'pt', 'a4');
      const elements = reportRef.current.children;
      let yOffset = 0; // Inicializa o offset vertical

      // Define as margens
      const marginLeft = 8; // em pontos
      const marginTop = 8; // em pontos

      // Captura a imagem do logo da empresa
      let logoImg = null;
      if (companyLogo) {
        try {
          logoImg = await loadImage(companyLogo);
        } catch (err) {
          console.error('Erro ao carregar a imagem do logo:', err);
        }
      }

      // Adiciona a capa personalizada, se necessário
      addCustomCoverPage(pdf, logoImg, 'RELATÓRIO EXECUTIVO', 'ATENDIMENTOS TÉCNICOS');

      for (const element of elements) {
        const canvas = await html2canvas(element, { scale: 2 }); // Captura em alta resolução
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * marginLeft;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let remainingHeight = pdfHeight;
        let currentYOffset = yOffset;

        while (remainingHeight > 0) {
          const availableHeight = pdf.internal.pageSize.getHeight() - marginTop - currentYOffset;

          if (remainingHeight > availableHeight) {
            // Se a imagem restante ultrapassar a altura disponível na página atual
            const canvasPart = document.createElement('canvas');
            canvasPart.width = canvas.width;
            canvasPart.height = (availableHeight / pdfWidth) * canvas.width;

            const ctx = canvasPart.getContext('2d');
            ctx.drawImage(canvas, 0, pdfHeight - remainingHeight, canvas.width, canvasPart.height, 0, 0, canvasPart.width, canvasPart.height);

            const imgPartData = canvasPart.toDataURL('image/jpeg', 1.0);

            pdf.addImage(imgPartData, 'JPEG', marginLeft, currentYOffset + marginTop, pdfWidth, availableHeight);

            pdf.addPage();
            currentYOffset = 0;
            remainingHeight -= availableHeight;
          } else {
            const canvasPart = document.createElement('canvas');
            canvasPart.width = canvas.width;
            canvasPart.height = (remainingHeight / pdfWidth) * canvas.width;

            const ctx = canvasPart.getContext('2d');
            ctx.drawImage(canvas, 0, pdfHeight - remainingHeight, canvas.width, canvasPart.height, 0, 0, canvasPart.width, canvasPart.height);

            const imgPartData = canvasPart.toDataURL('image/jpeg', 1.0);

            pdf.addImage(imgPartData, 'JPEG', marginLeft, currentYOffset + marginTop, pdfWidth, remainingHeight);
            currentYOffset += remainingHeight;
            remainingHeight = 0;
          }
        }

        yOffset = currentYOffset;

        // Adiciona uma nova página se a imagem ocupar toda a página
        if (currentYOffset + 2 * marginTop >= pdf.internal.pageSize.getHeight()) {
          pdf.addPage();
          yOffset = 0; // Reseta o offset vertical
        }
      }

      pdf.save('relatorio-executivo-clientes.pdf');
    }
  };

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      if (!url) {
        resolve(null);
        return;
      }
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  const addCustomCoverPage = (pdf, img, title, subtitle) => {
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(20);
    pdf.text(title, pdfWidth / 2, pdfHeight / 2 - 70, { align: 'center' });

    if (img) {
      const imgDisplayWidth = 250;
      const imgDisplayHeight = 100;
      pdf.addImage(img, 'JPEG', (pdfWidth - imgDisplayWidth) / 2, pdfHeight / 2 - imgDisplayHeight / 2 - 180, imgDisplayWidth, imgDisplayHeight);
    }

    pdf.setFontSize(14);
    pdf.text(subtitle, pdfWidth / 2, pdfHeight / 2 - 40, { align: 'center' });

    pdf.setFontSize(14);
    pdf.text(`${selectedCustomers.map(item => item.label)}`, pdfWidth / 2, pdfHeight / 2 - 10, { align: 'center' });
    pdf.text(`${formatDate(filters.initialDate)} - ${formatDate(filters.endDate)}`, pdfWidth / 2, pdfHeight / 2 + 10, { align: 'center' });

    pdf.setFontSize(12);
    pdf.text('Powered by ' + company.fantasyName || 'MSP Desk', pdfWidth / 2, pdfHeight - 20, { align: 'center' });

    pdf.addPage();
  };

  const handleGenerateReports = async () => {
    await Promise.all([
      getByCatalog(),
      getByTypeTicket(),
      getByPriority(),
      getByCategory(),
      getSummary(),
      getAppointments(),
      getCatalogStatistics()
    ]);
    setShowReports(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await handleGenerateReports();
  };

  return (
    <div style={{ minHeight: '100vh' }}>
      <Form className="d-flex justify-content-between align-items-end" onSubmit={handleSubmit}>
        <Form.Group className="w-50 me-3">
          <Form.Label>Data <span className="text-danger"> *</span></Form.Label>

          <Flex>
            <Form.Select style={{ width: '40%', borderRadius: '4px 0 0 4px' }} value={filters.typeDate} onChange={e => setFilters({ ...filters, typeDate: e.target.value })}>
              <option value="CREATION_DATE">Criação</option>
              <option value="COMPLETION_DATE">Conclusão</option>
            </Form.Select>

            <DatePickerComponent startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} applyCustomStyles={true} />
          </Flex>
        </Form.Group>

        <Form.Group className="w-50">
          <Form.Label>Clientes <span className="text-danger"> *</span></Form.Label>
          <MultiSelect
            placeholder="Selecione..."
            options={formattedCustomers}
            value={selectedCustomers}
            onChange={values => setSelectedCustomers(values)} />
        </Form.Group>
      </Form>

      <Flex className="w-100 my-3" justifyContent="end">
        {showReports &&
          <Button variant="falcon-default" size="sm" onClick={generatePdf} className="me-2">
            <FontAwesomeIcon icon={faFilePdf} className="me-2" />
            Exportar PDF
          </Button>
        }

        <Button variant="primary" size="sm" onClick={handleSubmit}>
          <FontAwesomeIcon icon={faEye} className="me-2" />
          Exibir
        </Button>
      </Flex>

      {showReports && (
        <div ref={reportRef}>
          <Row>
            <Col md={12}>
              <ListGroup className="mb-3">
                <ListGroup.Item className="bg-100">
                  <h5>Resumo</h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <SummaryExecutive data={summary} />
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={12}>
              <ListGroup className="mb-3">
                <ListGroup.Item className="bg-100">
                  <h5>Horas apontadas</h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <AppointmentsExecutiveCustomer data={appointments} />
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <ListGroup className="mb-3">
                <ListGroup.Item className="bg-100">
                  <h5>Tickets por Catálogo</h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <TicketsByCatalogReport data={byCatalog} />
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={6}>
              <ListGroup className="mb-3">
                <ListGroup.Item className="bg-100">
                  <h5>Tickets por Categoria</h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <TicketsByCategory data={byCategory} />
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <ListGroup className="mb-3">
                <ListGroup.Item className="bg-100">
                  <h5>Tickets por Prioridade</h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <TicketsByPriority data={byPriority} />
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <ListGroup className="mb-3">
                <ListGroup.Item className="bg-100">
                  <h5>Tickets por Tipo</h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <TicketsByTypeTicket data={byTypeTicket} />
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <ListGroup className="mb-3">
                <ListGroup.Item className="bg-100">
                  <h5>Estatísticas de Catálogo</h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <CatalogStatistics data={catalogStatistics} />
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ExecutiveCustomer;
