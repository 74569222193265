import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{zIndex: '3000', width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.4)', position: 'fixed', left:0, bottom: 0}}>
      <Spinner animation="border" style={{width: 200, height: 200}} variant="dark" />
    </div>
  );
};

export default Loading;