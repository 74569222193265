import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import FalconComponentCard from "components/common/FalconComponentCard";
import Pagination from "components/pagination";
import useApi from 'helpers/api';
import { formatDateTime } from "helpers/utils";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AssetList = () => {
  const api = useApi();

  const [assetName, setAssetName] = useState('');
  const [assets, setAssets] = useState({ content: [] });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: false,
    name: ''
  });

  useEffect(() => {
    getAssets();
  }, [filters]);

  useEffect(() => {
    if (assetName.length === 0) {
      setFilters({ ...filters, name: '' });
    }
  }, [assetName]);

  const getAssets = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.assets.list(queryParams);

    if (response.content) {
      setAssets(response);
    }
  };

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { id, name } = rowData.row.original;
        return (
          <Link to={`/assets/${id}`}>
            {name}
          </Link>
        );
      }
    },
    {
      accessorKey: 'assetType',
      header: 'Tipo',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { assetType } = rowData.row.original;
        return (
          <span>
            {assetType?.name}
          </span>
        );
      }
    },
    {
      accessorKey: 'customer',
      header: 'Cliente',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { customer } = rowData.row.original;
        return (
          <span>{customer.name}</span>
        )
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { inactive } = rowData.row.original;
        return (
          inactive
            ? <span className="text-danger">Inativo</span>
            : <span className="text-success">Ativo</span>
        );
      }
    },
    {
      accessorKey: 'creationDate',
      header: 'Criado em',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { creationDate } = rowData.row.original;
        return (
          <span>{formatDateTime(creationDate)}</span>
        );
      }
    },
    {
      accessorKey: 'lastUpdateDate',
      header: 'Atualizado em',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { lastUpdateDate } = rowData.row.original;
        return (
          <span>{formatDateTime(lastUpdateDate)}</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: '',
      enableSorting: false,
      cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Link to={`/assets/${id}`}>
            <FontAwesomeIcon icon={faPen} size="sm" />
          </Link>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: assets.content,
    columns
  });

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header title="Ativos">
          {/* <Button
            type="button"
            variant="primary"
            size="sm"
            onClick={() => setShowModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
            Novo
          </Button> */}
        </FalconComponentCard.Header>

        <FalconComponentCard.Body>
          <Row className="justify-content-between g-0">
            <Col className="d-flex align-items-center col-auto pe-3 mb-3">
              <InputGroup className="position-relative input-search-width">
                <FormControl
                  size="sm"
                  id="search"
                  type="search"
                  className="shadow-none"
                  placeholder="Buscar por nome"
                  value={assetName}
                  onChange={e => setAssetName(e.target.value)}
                  style={{ borderRadius: '1px 0px 0px 1px' }}
                />
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="border-300 hover-border-secondary"
                  onClick={() =>
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      page: 0,
                      name: assetName
                    }))
                  }
                >
                  <FontAwesomeIcon icon="search" className="fs-10" />
                </Button>
              </InputGroup>
            </Col>

            <Col className="col-auto px-3">
              <Form.Select
                size="sm"
                className="mb-3 rounded-pill"
                onChange={e => setFilters({ ...filters, includeInactives: e.target.value })}
              >
                <option value="true">Filtro por status</option>
                <option value="true">Todos</option>
                <option value="false">Somente ativos</option>
              </Form.Select>
            </Col>
          </Row>

          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableProvider>
          <Pagination
            data={assets}
            filters={filters}
            setFilters={setFilters}
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default AssetList;