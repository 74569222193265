import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';

const PreviewTicket = ({ data }) => {
  const sanitizeHtml = useMemo(() => DOMPurify.sanitize(data.description), [data]);

  return (
    <Card
      className='position-absolute'
      style={{ width: '50%', zIndex: 99999 }}>
      <Card.Header>
        <Card.Title style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{data.subject}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className='d-flex'>
          <div style={{ width: 8, height: 40, backgroundColor: data?.status.color }} className='me-2' />
          <div className='d-flex flex-column'>
            <p className='m-0 mb-1'>{data.status?.description}</p>
            <p>#{data.code}</p>
          </div>
        </div>
        <div >
          <div className='rich-text-content' style={{ width: '100%', whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: sanitizeHtml }} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default PreviewTicket;
