import { ListGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const DatePickerComponent = ({ startDate, endDate, setStartDate, setEndDate, applyCustomStyles }) => {
  const handleCurrentYear = () => {
    const start = new Date(new Date().getFullYear(), 0, 1);
    const end = new Date(new Date().getFullYear(), 11, 31);
    setStartDate(start);
    setEndDate(end);
  };

  const handleLastYear = () => {
    const year = new Date().getFullYear() - 1;
    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);
    setStartDate(start);
    setEndDate(end);
  };

  const handleThisMonth = () => {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setStartDate(start);
    setEndDate(end);
  };

  const handleLastThreeMonths = () => {
    const date = new Date();
    const end = new Date(date.getFullYear(), date.getMonth(), 1);
    const start = new Date(date.getFullYear(), date.getMonth() - 3, 1);
    end.setDate(end.getDate() - 1);

    setStartDate(start);
    setEndDate(end);
  };


  const handleLastMonth = () => {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const end = new Date(date.getFullYear(), date.getMonth(), 0);
    setStartDate(start);
    setEndDate(end);
  };

  const handleLastThirtyDays = () => {
    const date = new Date();
    const start = new Date(date.setDate(date.getDate() - 30));
    const end = new Date();
    setStartDate(start);
    setEndDate(end);
  };

  const handleLastWeek = () => {
    const date = new Date();
    const start = new Date(date.setDate(date.getDate() - 7));
    const end = new Date();
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className={`date-picker-wrapper ${applyCustomStyles ? 'custom-styles' : ''}`}>
      <DatePicker
        popperPlacement='bottom-start'
        selected={startDate}
        onChange={([start, end]) => {
          setStartDate(start);
          setEndDate(end);
        }}
        startDate={startDate}
        formatWeekDay={day => day.slice(0, 3)}
        calendarContainer={({ children }) => (
          <div className="react-datepicker react-datepicker__predefined-ranges">
            <ListGroup variant="flush" className="react-datepicker__ranges">
              <ListGroup.Item action onClick={handleCurrentYear}>
                Este ano
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLastYear}>
                Último ano
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleThisMonth}>
                Este mês
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLastThreeMonths}>
                Último três meses
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLastMonth}>
                Último mês
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLastThirtyDays}>
                Últimos trinta dias
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLastWeek}>
                Última semana
              </ListGroup.Item>
            </ListGroup>
            {children}
          </div>
        )}
        showPreviousMonths
        endDate={endDate}
        selectsRange
        dateFormat="dd/MM/yyyy"
        className='form-control'
      />
    </div>
  );
};

export default DatePickerComponent;