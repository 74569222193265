import { Col, Row } from "react-bootstrap";
import TicketsByTypeChart from "./TicketsByTypeChart";

const TicketsByTypeTicket = ({ title, data }) => {
  return (
    <div className="h-100 pt-3">
      <Row className="w-100">
        <Col lg={12} xs={12}>
          {data &&
            <TicketsByTypeChart data={data} />
          }
        </Col>
      </Row>

    </div>
  );
};

export default TicketsByTypeTicket;