import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import DialogModal from "components/modals/DialogModal";
import Pagination from "components/pagination";
import useApi from 'helpers/api';
import { formatDateTime } from "helpers/utils";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ScheduledTicketList = () => {
  const api = useApi();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [success, setSuccess] = useState('');
  const [error, setError] = useState({});

  const [selectedScheduledTicket, setSelectedScheduledTicket] = useState({});
  const [scheduledsTickets, setScheduledsTickets] = useState({ content: [] });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: false
  });

  useEffect(() => {
    getData();
  }, [filters]);

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.scheduledsTickets.list(queryParams);
    response.content && setScheduledsTickets(response);
  };

  const activate = async id => {
    const request = await api.scheduledsTickets.activate(id);
    handleRequest(request, "Política de ticket agendado ativada com sucesso");
  };

  const inactivate = async id => {
    const request = await api.scheduledsTickets.inactivate(id);
    handleRequest(request, "Política de ticket agendado inativada com sucesso");
  };

  const deleteScheduledTicket = async response => {
    if (response) {
      const request = await api.scheduledsTickets.delete(selectedScheduledTicket.id);
      handleRequest(request, "Política de ticket agendado excluída com sucesso");
      setShowDialog(false);
    }
  };

  const handleDelete = item => {
    setSelectedScheduledTicket(item);
    setShowDialog(true);
  };

  function handleRequest(request, message) {
    if (request.ok) {
      setSuccess(message);
      setShowSuccess(true);
      getData();
    } else {
      setError(request);
      setShowError(true);
    }
  }

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'frequency',
      header: 'Periodicidade',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { repeatType } = rowData.row.original;
        return (
          <span>
            {repeatType.description}
          </span>
        );
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { inactive } = rowData.row.original;
        return (
          inactive
            ? <span className="text-danger">Inativo</span>
            : <span className="text-success">Ativo</span>
        );
      }
    },
    {
      accessorKey: 'nextDate',
      header: 'Próxima data agendada',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { nextDate } = rowData.row.original;
        return (
          <span>{formatDateTime(nextDate)}</span>
        );
      }
    },
    {
      accessorKey: 'customers',
      header: 'Clientes',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { customers } = rowData.row.original;
        return (
          customers.map((customer, index) => (
            <span key={customer.name}>
              {customer.name}
              {index !== customers.length - 1 && ', '}
            </span>
          ))
        )
      }
    },
    {
      accessorKey: 'creationDate',
      header: 'Criado em',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { creationDate } = rowData.row.original;
        return (
          <span>{formatDateTime(creationDate)}</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <Flex alignItems='center'>
            <Form.Check
              type='switch'
              id='status'
              value={item.inactive}
              defaultChecked={!item.inactive}
              disabled={item.isDefault}
              onClick={() => item.inactive ? activate(item.id) : inactivate(item.id)}
            />

            <Button as={Link} to={`/registration/scheduleds-tickets/${item.id}`} variant="link" size="sm">
              <FontAwesomeIcon icon={faPen} size="sm" />
            </Button>

            <Button variant="link" size="sm" onClick={() => handleDelete(item)}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          </Flex>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: scheduledsTickets.content,
    columns
  });

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message={success} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <DialogModal
        message="Tem certeza que deseja excluir esta política de ticket agendado?"
        showModal={showDialog}
        handleClose={() => setShowDialog(false)}
        response={deleteScheduledTicket} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Política de tickets agendados">
          <div className="w-100 d-flex justify-content-between">
            <Button as={Link} to={'/registration/scheduleds-tickets/new'} type="button" variant="primary" size="sm">
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Button>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <Row className="justify-content-end g-0">
            <Col className="col-auto px-3">
              <Form.Select size="sm" className="mb-3 rounded-pill" onChange={e => setFilters(prevFilters => ({ ...prevFilters, includeInactives: e.target.value }))}>
                <option value="true">Filtro por status</option>
                <option value="true">Todos</option>
                <option value="false">Somente ativos</option>
              </Form.Select>
            </Col>
          </Row>
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }} />
          </AdvanceTableProvider>
          <Pagination
            data={scheduledsTickets}
            filters={filters}
            setFilters={setFilters} />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default ScheduledTicketList;