import { FilterContext } from "context/Context";
import { useState } from "react";

const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    code: '',
    priorities: '',
    status: '',
    customer: '',
    serviceCatalog: '',
    category: '',
    subcategory: '',
    subject: '',
    agent: '',
    serviceGroup: '',
    contact: '',
    typeTicket: '',
    initialCreationDate: '',
    endCreationDate: '',
    initialSolutionDate: '',
    endSolutionDate: '',
    statusSlaResponse: '',
    statusSlaSolution: '',
    createdBy: '',
    defaultFilter: '',
    answered: false
  });

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  )
};

export default FilterProvider;