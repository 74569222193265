import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconCardHeader from "components/common/FalconCardHeader";
import Flex from "components/common/Flex";
import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";

const SummaryOverview = ({ filters }) => {
  const api = useApi();
  const [data, setData] = useState({});

  useEffect(() => {
    getData();
  }, [filters]);

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.overview.summaryTicket(queryParams);

    setData(response.status ? {} : response);
  };
  return (
    <Card className="my-3">
      <FalconCardHeader
        title="Resumo"
        titleTag="h4"
        className="py-2 bg-body-tertiary"
      />
      <Flex justifyContent="between" alignItems="center" className="p-3">
        <div>
          <span>Tickets Criados</span>
          <h3 className="m-0 text-primary">{data && data.createdTickets}</h3>
        </div>
        <div>
          <span>Tickets Fechados</span>
          <h3 className="m-0 text-success">{data && data.closedTickets}</h3>
        </div>

        <div>
          <span>Conformidade SLA Resposta</span>
          <h3 className="m-0 text-info">{data && data.slaResponseCompliance}%</h3>
        </div>

        <div>
          <span>Conformidade SLA Solução</span>
          <h3 className="m-0 text-warning">{data && data.slaSolutionCompliance}%</h3>
        </div>

        <div>
          <Flex alignItems="center">
            <span className="me-2">Média por Ticket</span>
            <OverlayTrigger overlay={<Tooltip>O tempo médio de apontamento por ticket é calculado pela soma dos tempos de apontamento dividida pelo número total de tickets.</Tooltip>}>
              <span><FontAwesomeIcon icon={faInfoCircle} /></span>
            </OverlayTrigger>
          </Flex>
          <h3 className="m-0 text-body">{data && data.averageTimeSpentOnTicket}</h3>
        </div>
      </Flex>
    </Card>
  )
};

export default SummaryOverview;