import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "components/common/SoftBadge";
import { formatDate } from "helpers/utils";
import { PrioritySelect } from "hooks/useTicketsTable";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const CardLayout = ({ data, isSelectedItem, toggleSelectedItem }) => {
  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-center gap-3 mb-3">
      {data.slice(0, 12).map((ticket, index) => (
        <div
          key={index}
          style={{ width: '95%' }}
          className="mt-3 bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height"
        >
          <div className="d-flex align-items-start align-items-sm-center">
            <Form.Check
              type="checkbox"
              id="inboxBulkSelect"
              className="fs-0 form-check me-2 me-xxl-3 mb-0"
            >
              <Form.Check.Input
                type="checkbox"
                checked={isSelectedItem(ticket.id)}
                onChange={() => toggleSelectedItem(ticket.id)}
              />
            </Form.Check>
            <div className="ms-1 ms-sm-3">
              <p className="fw-semi-bold mb-3 mb-sm-2">
                <Link to={`/tickets/${ticket.id}`}>#{ticket.code} {ticket.subject}</Link>
              </p>
              <Row className="align-items-center gx-0 gy-2">
                <Col xs="auto" className="me-2">
                  <h6 className="mb-0">
                    <Link
                      to={`/registration/customers/${ticket.customer.id}`}
                      className="text-800 d-flex align-items-center gap-1"
                    >
                      <FontAwesomeIcon icon="user" transform="shrink-3 up-1" />
                      <span>{ticket.customer.fantasyName || ticket.customer.name}</span>
                    </Link>
                  </h6>
                </Col>
                <Col xs="auto" className="lh-1 me-3">
                  <SoftBadge style={{ backgroundColor: ticket.status.color }}>
                    {ticket.status.description}
                  </SoftBadge>
                </Col>
                <Col xs="auto">
                  <h6 className="mb-0 text-500">{formatDate(ticket.creationDate)}</h6>
                </Col>
              </Row>
            </div>
          </div>
          <div className="border-bottom mt-4 mb-x1"></div>
          <div className="d-flex justify-content-between ms-auto">
            <PrioritySelect
              title={ticket.priority.description}
              color={ticket.priority.color}
              data={ticket.priority.data}
              className="ms-md-4 ms-xl-0"
              style={{ width: '7.5rem' }}
            />
            <h6>{ticket.agent ? ticket.agent.firstName : '---'}</h6>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardLayout;