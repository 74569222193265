import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AssetTypeProperties from "./AssetTypeProperties";

const AssetTypeEdit = () => {
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const form = useRef(null);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [validated, setValidated] = useState(false);

  const [body, setBody] = useState({
    name: '',
    description: '',
    inactive: false
  });

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, []);

  const getDetails = async () => {
    const response = await api.assetTypes.find(id);
    response.id && setBody(response);
  };

  const onSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      const response = await api.assetTypes.edit(id, body);

      if (response.id) {
        setShowSuccess(true);
        getDetails();
      } else {
        setError(response);
        setShowError(true);
      }
    }
  };

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message="Tipo de ativo editado com sucesso" />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error.userMessage} />
      <FalconComponentCard>
        <Card.Header as={Flex} alignItems="center" justifyContent="between">
          <Flex>
            <IconButton
              onClick={() => navigate(-1)}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
              className="me-2"
            />
            <h3 className="m-0">Editar informações</h3>
          </Flex>

          <Flex>
            <Button size="sm" onClick={onSubmit}>
              <FontAwesomeIcon icon={faSave} className="me-1" />
              Salvar
            </Button>
          </Flex>
        </Card.Header>

        <Card.Body>
          <Form validated={validated} ref={form}>
            <Row>
              <Form.Group as={Col} md={5} xxl={5} className="mb-3">
                <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Nome"
                  value={body.name}
                  onChange={e => setBody({ ...body, name: e.target.value })}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={6} xxl={6} className="mb-3">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Uma breve descrição"
                  value={body.description}
                  onChange={e => setBody({ ...body, description: e.target.value })}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  type="switch"
                  placeholder="Uma breve descrição"
                  value={body.inactive}
                  checked={!body.inactive}
                  onChange={e => setBody({ ...body, inactive: !body.inactive })}
                />
              </Form.Group>
            </Row>
          </Form>

          <hr />

          <AssetTypeProperties assetTypeId={id} hasIntegration={body.hasIntegration} />
        </Card.Body>
      </FalconComponentCard>
    </>
  );
};

export default AssetTypeEdit;