import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useAppContext } from 'providers/AppProvider';
import React from 'react';
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { camelize } from '../../helpers/utils';
import FalconCardBody from './FalconCardBody';
import Flex from './Flex';

const PreviewCode = () => {
  return (
    <Row className="d-inline-block">
      <Col>
        <Nav variant="pills" className="nav-pills-falcon m-0">
          <Nav.Item>
            <Nav.Link as={Button} size="sm" eventKey="preview">
              Preview
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Button} size="sm" eventKey="code">
              Code
            </Nav.Link>
          </Nav.Item>
          {/* <Button variant="" type="button" size="sm">
            Preview
          </Button>
          <Button variant="" type="button" size="sm">
            Code
          </Button> */}
        </Nav>
      </Col>
    </Row>
  );
};

const FalconComponentCardHeader = ({
  light,
  className,
  title,
  subTitle,
  children,
  noPreview
}) => {
  const location = useLocation();
  const {
    config: { isRTL }
  } = useAppContext();
  return (
    <Card.Header
      className={classNames({ 'bg-body-tertiary': light }, className)}
    >
      <Row
        className={classNames('w-100', 'align-items-center', 'd-flex', {
          'justify-content-start': !children,
          'justify-content-between ': children
        })}
      >
        <Col>
          {title && (
            <Flex direction="column">
              <h3
                className="mb-0 hover-actions-trigger text-truncate text-nowrap"
                id={camelize(title)}
              >
                {isRTL ? (
                  <>
                    <HashLink
                      to={`${location.pathname}#${camelize(title)}`}
                      className="hover-actions ps-2"
                      style={{ top: 0, left: '-25px' }}
                    >
                      #
                    </HashLink>
                    {title}
                  </>
                ) : (
                  <>
                    {title}
                    <HashLink
                      to={`${location.pathname}#${camelize(title)}`}
                      className="hover-actions ps-2"
                      style={{ top: 0, right: '-25px' }}
                    >
                      #
                    </HashLink>
                  </>
                )}
              </h3>
              {subTitle && <p className="mt-2 w-50 fs-10">{subTitle}</p>}
            </Flex>
          )}
        </Col>
        {!noPreview && <Col className="col-auto">{children}</Col>}
      </Row>
    </Card.Header>
  );
};

const FalconComponentCard = ({
  children,
  multiSections,
  noGuttersBottom,
  ...rest
}) => {
  return (
    <Card className={classNames({ 'mb-3': !noGuttersBottom })} {...rest}>
      {multiSections ? (
        <>{children}</>
      ) : (
        <Tab.Container defaultActiveKey="preview">{children}</Tab.Container>
      )}
    </Card>
  );
};

FalconComponentCard.Header = FalconComponentCardHeader;
FalconComponentCard.Body = FalconCardBody;

FalconComponentCard.propTypes = {
  children: PropTypes.node,
  multiSections: PropTypes.bool,
  noGuttersBottom: PropTypes.bool
};

FalconComponentCardHeader.propTypes = {
  light: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
  noPreview: PropTypes.bool
};

export default FalconComponentCard;
