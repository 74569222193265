import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FailedRequest from 'components/alerts/FailedRequest';
import SuccessRequest from 'components/alerts/SuccessRequest';
import CustomerDetailsHeader from 'components/app/e-commerce/customers-details/CustomerDetailsHeader';
import CustomerInfo from 'components/app/e-commerce/customers-details/CustomerInfo';
import CustomerAddress from 'components/registration/customers-details/CustomerAddress';
import CustomerContact from 'components/registration/customers-details/CustomerContact';
import useApi from 'helpers/api';
import React, { useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import Tickets from './TicketsCustomer';

const CustomerDetails = () => {
  const { id } = useParams();
  const api = useApi();
  const [customer, setCustomer] = useState({});
  const [error, setError] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const getCustomer = async () => {
      const response = await api.customers.findById(id);
      setCustomer(response);
    };

    getCustomer();
  }, []);

  return (
    <>
      <SuccessRequest
        message="Cliente editado com sucesso"
        showAlert={isSuccess}
        setShowAlert={() => setIsSuccess(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showError}
        setShowAlert={(() => setShowError(false))} />
      <CustomerDetailsHeader data={customer} />
      <Row className="g-3">
        <Col xxl={12}>
          <Card>
            <Tab.Container defaultActiveKey="basicData">
              <SimpleBar>
                <Card.Header className="p-0">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="basicData"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="stream"
                          className="text-600 tab-icon"
                        />
                        <h6 className="text-600 mb-0 ms-1">Dados básicos</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="addresses"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon icon="map-marker-alt" className="text-600" />
                        <h6 className="text-600 mb-0 ms-1">Endereços</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="contacts"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon="user"
                          className="text-600"
                        />
                        <h6 className="text-600 mb-0 ms-1">Contatos</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="tickets"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <FontAwesomeIcon icon="ticket-alt" className="text-600" />
                        <h6 className="text-600 mb-0 ms-1">Tickets</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="basicData">
                    <CustomerInfo
                      data={customer}
                      success={response => setIsSuccess(response)}
                      error={error => {
                        setError(error);
                        setShowError(true);
                      }} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="contacts">
                    <CustomerContact data={customer} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="addresses">
                    <CustomerAddress data={customer} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="tickets">
                    <Tickets data={customer} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomerDetails;