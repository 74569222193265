import { faDesktop } from "@fortawesome/free-solid-svg-icons";

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/dashboards/tickets',
      active: true
    },
  ]
};
export const ticketsRoutes = {
  label: 'Tickets',
  labelDisable: true,
  children: [
    {
      name: 'Tickets',
      icon: 'ticket-alt',
      active: true,
      to: '/tickets'
    },
  ]
};

export const customersRoutes = {
  label: 'Clientes',
  labelDisable: true,
  children: [
    {
      name: 'Clientes',
      icon: 'user',
      to: '/registration/customers',
      active: true
    },
  ]
};

export const contactsRoutes = {
  label: 'Contatos',
  labelDisable: true,
  children: [
    {
      name: 'Contatos',
      icon: 'users',
      to: '/registration/contacts',
      active: true
    },
  ]
};

export const assetsRoutes = {
  label: 'Ativos',
  labelDisable: true,
  children: [
    {
      name: 'Ativos',
      icon: faDesktop,
      to: '/assets',
      active: true,
      badge: {
        type: 'success',
        text: 'BETA'
      }
    }

  ]
};

export const reportsRoutes = {
  label: 'Relatórios',
  labelDisable: true,
  children: [
    {
      name: 'Relatórios',
      icon: 'poll',
      to: '/reports/overview',
      active: true
    },
  ]
};

export const configurationRoutes = {
  label: 'configuration',
  labelDisable: true,
  active: true,
  children: [
    {
      name: 'Configurações',
      icon: 'cog',
      to: '/settings',
      active: true,
    }
  ]
};

export default [
  dashboardRoutes,
  ticketsRoutes,
  customersRoutes,
  contactsRoutes,
  assetsRoutes,
  reportsRoutes,
  configurationRoutes,
];