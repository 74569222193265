import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { colors, getColor, rgbaColor } from 'helpers/utils';
import React, { useEffect, useRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data) => ({
  series: [
    {
      type: 'pie',
      radius: window.innerWidth < 530 ? '70%' : '75%',
      label: {
        show: true,
        position: 'inside',
        formatter: '{c}', // {b} para o nome, {c} para o valor
        color: getColor('white')
      },
      center: ['50%', '55%'],
      data: data && data.map((item, i) => ({
        value: item.value,
        name: item.name,
        itemStyle: {
          color: colors[i]
        }
      })),
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: rgbaColor(getColor('gray-600'), 0.5)
        }
      }
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-1100') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});


const PizzaChart = ({ data }) => {
  const chartRef = useRef(null);

  const updateDimensions = () => {
    if (window.innerWidth < 530) {
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '45%' }]
      });
    }
    else
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '60%' }]
      });
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(data)}
      ref={chartRef}
      style={{ height: '20rem' }}
    />
  );
}

export default PizzaChart;