import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const TicketSubjectEditModal = ({ showModal, data, handleClose, handleSubmit }) => {
  const [body, setBody] = useState({
    subject: ''
  });
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if(data) {
      setBody({subject: data});
      setValidated(false);
    }
  }, [data]);

  const onSubmit = e => {
    if(form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({subject: body.subject});
      setValidated(false);
    }
  }

  return (
    <>
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar assunto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="editForm.Name">
            <Form.Label>Assunto*</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Assunto"
              autoFocus
              value={body.subject}
              onChange={e => setBody((prevBody) => ({ ...prevBody, subject: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um assunto.</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
    </>

  );
};

export default TicketSubjectEditModal;