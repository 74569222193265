import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Pagination from 'components/pagination';
import useApi from 'helpers/api';
import { formatDate, translationMap } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const PrioritySelect = ({ title, color, data }) => {
  return (
    <div
      style={{ width: '7.5rem' }}
      className="d-flex align-items-center gap-2 ms-md-4 ms-xl-0 ms-xxl-4"
    >
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={color}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{title}</h6>
    </div>
  );
};

PrioritySelect.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const AgentSelect = ({ agent }) => {
  return (
    <Flex wrap="wrap" className="pt-2 pe-2">
      <h6 className="mb-0 text-600 lh-base">
        <FontAwesomeIcon
          icon={faHeadset}
          className="me-2"
        />
        {agent ? agent.firstName : '---'}
      </h6>
    </Flex>
  );
};

AgentSelect.propTypes = {
  agent: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object
};

const TicketsCustomer = ({ data }) => {
  const api = useApi();
  const [customer, setCustomer] = useState({});
  const [filters, setFilters] = useState({
    page: 0,
    size: 10
  });
  const [tickets, setTickets] = useState({ content: [] });

  useEffect(() => {
    setCustomer(data);
  }, [data]);

  useEffect(() => {
    if (Object.keys(customer).length != 0) getTickets();
  }, [customer, filters]);

  const getTickets = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    let response = await api.tickets.listByCustomer(customer.id, queryParams);
    setTickets(response);
  };

  return (
    <Flex direction="column" className="gap-3">
      {tickets ? (
        tickets.content.map((ticket, index) => {
          const { id, code, subject, status, priority, agent, creationDate } = ticket;
          return (
            <div
              key={index}
              className="bg-white dark__bg-1100 p-x1 rounded-3 shadow-sm d-md-flex d-xl-inline-block d-xxl-flex align-items-center"
            >
              <div>
                <p className="fw-semi-bold">
                  <Link to={`/tickets/${id}`}>#{code} {subject}</Link>
                </p>
                <Flex alignContent="center">
                  <h6 className="mb-0 me-3 text-800 lh-base">{formatDate(creationDate)}</h6>
                  <SoftBadge style={{ backgroundColor: status.color }}>{translationMap.status[status.description]}</SoftBadge>
                </Flex>
              </div>
              <div className="border-bottom mt-4 mb-x1"></div>
              <Flex justifyContent="between" className="ms-auto">
                <PrioritySelect
                  title={priority.description}
                  color={priority.color}
                  data={priority.data}
                />
                <AgentSelect agent={agent} />
              </Flex>
            </div>
          );
        })) : (<p>Carregando...</p>)}

      <Pagination
        data={tickets}
        filters={filters}
        setFilters={setFilters} />
    </Flex>
  );
};

export default TicketsCustomer;