import LayoutReport from "../LayoutReport";
import Overview from "./Overview";

const ReportSupportDeskOverView = () => {
  return (
    <LayoutReport>
      <Overview />
    </LayoutReport>
  );
};

export default ReportSupportDeskOverView;