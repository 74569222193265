import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import Editor from "components/editor/Editor";
import Loading from "components/loading/Loading";
import useApi from 'helpers/api';
import { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Offcanvas } from "react-bootstrap";

const AddNoteOffcanvas = ({ show, handleClose, data, refreshData, refreshNotes, refreshLogs, ticket }) => {
  const api = useApi();
  const form = useRef(null);
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState({
    note: '',
    type: 'PRIVATE'
  });
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data == undefined)
      data = {};

    if (Object.keys(data).length != 0) {
      setTitle("Editar nota");
      setIsEdit(true);
      handleSetBody('type', data.typeView.type);
      handleSetBody('note', data.note);
    } else {
      setTitle("Adicionar nota");
      setIsEdit(false);
    }
  }, [data]);

  useEffect(() => {
    if (show) {
      if (Object.keys(data).length === 0)
        clearFields();

      setValidated(false);
    }
  }, [show]);

  const onSubmit = async e => {
    setLoading(true);
    if (form.current.checkValidity() === false || body.note.trim() === '' || body.note === '<p><br></p>') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response;
      if (isEdit) {
        response = await api.ticketNotes.edit(ticket.id, data.id, body);
        setSuccess("Nota editada com sucesso");
      } else {
        response = await api.ticketNotes.add(ticket.id, body);
        setSuccess("Nota adicionada com sucesso");
      }

      handleResponse(response);
      clearFields();
      handleClose();
      data = {};
    }
    setLoading(false);
  };

  const addAndSetAsPending = async e => {
    setLoading(true);
    if (form.current.checkValidity() === false || body.note.trim() === '' || body.note === '<p><br></p>') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response = await api.ticketNotes.addAndSetAsPending(ticket.id, body);
      setSuccess("Nota adicionada com sucesso");
      handleResponse(response);
      refreshData();
      clearFields();
      handleClose();
    }
    setLoading(false);
  };

  const addAndSetAsCompleted = async e => {
    setLoading(true);
    if (form.current.checkValidity() === false || body.note.trim() === '' || body.note === '<p><br></p>') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response = await api.ticketNotes.addAndSetAsCompleted(ticket.id, body);
      setSuccess("Nota adicionada com sucesso");
      handleResponse(response);
      refreshData();
      clearFields();
      handleClose();
    }
    setLoading(false);
  };

  const handleSetBody = (fieldName, fieldValue) => {
    setBody(prevBody => ({ ...prevBody, [fieldName]: fieldValue }));
  };

  function handleResponse(response) {
    if (response.id) {
      setShowSuccess(true);
      refreshLogs();
      refreshNotes();
    } else {
      setError(response);
      setShowError(true);
    }
  }

  const clearFields = () => {
    setBody({
      note: '',
      type: 'PRIVATE'
    });
  };

  return (
    <>
      {loading && <Loading />}
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <Offcanvas show={show} onHide={handleClose} placement='end' style={{ minWidth: '45%', maxWidth: '90%' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pb-0">
          <Form ref={form} validated={validated}>
            <div className="mb-2 mt-n2">
              <Form.Label className="mb-1 fs-10">Visualização <span className="text-danger">*</span></Form.Label>
              <Form.Select
                required
                size="sm"
                value={body.type}
                onChange={e => handleSetBody('type', e.target.value)}>
                <option value='PRIVATE'>Privado</option>
                <option value="PUBLIC">Público</option>
              </Form.Select>
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Selecione um tipo de visualização.</Form.Control.Feedback>
            </div>
            <div className="mb-2">
              <Form.Label className="mb-1 fs-10">Descrição <span className="text-danger">*</span></Form.Label>
              <Editor value={value => handleSetBody('note', value)} height="55vh" data={data.note} />
            </div>
          </Form>
          <br /><br />

          <footer className="fixed-bottom p-2 d-flex justify-content-end">
            <Button variant="falcon-default" type="submit" className="me-2" onClick={handleClose}>
              Fechar
            </Button>
            {!isEdit ?
              <>
                <Button variant="primary" type="submit" onClick={onSubmit} style={{ borderRadius: '5px 0 0 5px' }}>
                  Salvar
                </Button>

                <Dropdown drop='up' className="d-inline-block">
                  <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

                  <Dropdown.Menu className="flex-wrap overflow-x-scroll" style={{ width: '230px' }}>
                    <Dropdown.Item onClick={(e) => addAndSetAsPending(e)} disabled={ticket.status && ticket.status.status != "TO_DO"}>Salvar e definir como Pausado</Dropdown.Item>
                    <Dropdown.Item onClick={e => addAndSetAsCompleted(e)} disabled={ticket.status && ticket.status.status == "COMPLETED"}>Salvar e definir como Concluído</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </> :
              <>
                <Button variant="primary" type="submit" onClick={onSubmit}>
                  Salvar
                </Button>
              </>
            }
          </footer>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const CustomToggle = forwardRef(({ onClick }, ref) => (
  <Button
    style={{ borderRadius: '0 5px 5px 0' }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faChevronDown} />
  </Button>
));

export default AddNoteOffcanvas;