import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SubtleBadge from 'components/common/SubtleBadge';
import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import AssetDetailsFromRMM from './AssetDetailsFromRMM';
import AssetOverview from './AssetOverveiw';
import AssetProperties from './AssetProperties';
import DattoDevice from './DattoDevice';

const AssetEdit = () => {
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();

  const [asset, setAsset] = useState({});

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log(asset);
  }, [asset]);

  const getData = async () => {
    const response = await api.assets.find(id);
    response.id && setAsset(response);
  };

  return (
    <>
      <Row className="gx-2">
        <Col xxl={9} md={9}>
          <Card>
            <Flex alignItems="center" className="w-100 py-3 ps-2">
              <IconButton
                onClick={() => navigate(-1)}
                variant="falcon-default"
                size="sm"
                icon="arrow-left"
                className="me-2"
              />
              <h3 className='m-0 me-2'>{asset.name}</h3>
              <SubtleBadge bg='info'>{asset.assetType?.name}</SubtleBadge>
            </Flex>
            <Tab.Container defaultActiveKey="basicData">
              <SimpleBar>
                <Card.Header className="p-0">

                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="basicData"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <h6 className="text-600 mb-0">Dados básicos</h6>
                      </Nav.Link>
                    </Nav.Item>
                    {asset.dattoDevice &&
                      <Nav.Item>
                        <Nav.Link
                          eventKey="detailsFromRMM"
                          className="d-flex align-items-center py-3 px-x1 mb-0"
                        >
                          <h6 className="text-600 mb-0">Detalhes Datto RMM</h6>
                        </Nav.Link>
                      </Nav.Item>
                    }
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="basicData">
                    <AssetOverview data={asset} />
                  </Tab.Pane>
                  {asset.dattoDevice &&
                    <Tab.Pane eventKey="detailsFromRMM">
                      <AssetDetailsFromRMM data={asset.dattoDevice} />
                    </Tab.Pane>
                  }
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>

        <Col xxl={3} md={3}>
          {asset.dattoDevice && <DattoDevice data={asset.dattoDevice} />}
          <AssetProperties data={asset} />
        </Col>
      </Row>
    </>
  );
};

export default AssetEdit;