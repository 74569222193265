import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import DialogModal from "components/modals/DialogModal";
import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Card, Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import './style.css';

const SlaPolicyList = () => {
  const api = useApi();
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await api.slaPolicies.list();
    setPolicies(response);
  };

  const activate = async id => {
    const request = await api.slaPolicies.activate(id);
    if (request.ok) {
      setSuccess("Política ativada com sucesso");
      setShowSuccess(true);
      getData();
    } else {
      setError(request);
      setShowError(true);
    }
  };

  const inactivate = async id => {
    const request = await api.slaPolicies.inactivate(id);
    if (request.ok) {
      setSuccess("Política inativada com sucesso");
      setShowSuccess(true);
      getData();
    } else {
      setError(request);
      setShowError(true);
    }
  };

  const deletePolicy = async response => {
    if (response) {
      const request = await api.slaPolicies.delete(selectedPolicy.id);
      if (request.ok) {
        setSuccess("Política excluída com sucesso");
        setShowSuccess(true);
        getData();
      } else {
        setError(request);
        setShowError(true);
      }
      handleCloseDialog();
    }
  };

  const handleDelete = item => {
    setSelectedPolicy(item);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  }

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <DialogModal
        message="Tem certeza que deseja excluir essa política?"
        showModal={showDialog}
        handleClose={handleCloseDialog}
        response={deletePolicy} />

      <FalconComponentCard>
        <FalconComponentCard.Header title="Políticas de SLA" subTitle="As políticas de Acordo de Nível de Serviço (SLA) ajudam você a configurar e manter metas durante o período em que suas equipes respondem e resolvem tickets.">
          <div className="w-100 d-flex justify-content-between">
            {/* <Link to="/registration/sla-policies/new" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Link> */}
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          {policies.map((policy, i) => (
            <Card className="mb-3">
              <Card.Body>
                <Flex justifyContent="between">
                  <div>
                    <span className="fw-semi-bold">{i + 1}.</span> <Link to={`/registration/sla-policies/${policy.id}`} className="fw-semi-bold">{policy.name}</Link>
                    <p className="fs-10 mt-2 ps-3">{policy.description}</p>
                  </div>

                  <div className="d-flex align-items-center">
                    <Form.Check
                      type='switch'
                      id='defaultSwitch'
                      value={policy.inactive}
                      defaultChecked={!policy.inactive}
                      disabled={policy.isDefault}
                      onClick={() => policy.inactive ? activate(policy.id) : inactivate(policy.id)}
                    />
                    <Dropdown
                      align="end"
                      className="btn-reveal-trigger d-inline-block ms-2"
                    >
                      <Dropdown.Toggle split variant="link" size="md">
                        <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="border py-0">
                        <div className="py-2">
                          <Dropdown.Item as={Link} to={`/registration/sla-policies/${policy.id}`} className="w-100 py-1 px-3">Editar</Dropdown.Item>

                          {!policy.isDefault &&
                            <Dropdown.Item onClick={() => handleDelete(policy)} className="text-gray" disabled={policy.isDefault}>Excluir</Dropdown.Item>
                          }
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Flex>
              </Card.Body>
            </Card>
          ))}
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default SlaPolicyList;