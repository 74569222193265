import Flex from "components/common/Flex";

const SummaryExecutive = ({ data }) => {
  return (
    <Flex justifyContent="between" alignItems="center" className="p-3">
      <div>
        <span>Tickets Abertos</span>
        <h5>{data && data.createdTickets}</h5>
      </div>
      <div>
        <span>Tickets Fechados</span>
        <h5>{data && data.closedTickets}</h5>
      </div>

      <div>
        <span>Conformidade SLA Resposta</span>
        <h5>{data && data.slaResponseCompliance}%</h5>
      </div>

      <div>
        <span>Conformidade SLA Solução</span>
        <h5>{data && data.slaSolutionCompliance}%</h5>
      </div>
    </Flex>
  )
};

export default SummaryExecutive;