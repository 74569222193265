import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import IconButton from "components/common/IconButton";
import ContactCustomerEditModal from "components/modals/CustomerContactEditModal";
import ContactCustomerRegistrationModal from "components/modals/CustomerContactRegistrationModal";
import CustomerDomainEditModal from "components/modals/CustomerDomainEditModal";
import CustomerDomainRegistrationModal from "components/modals/CustomerDomainRegistrationModal";
import DialogModal from "components/modals/DialogModal";
import Pagination from "components/pagination";
import useApi from "helpers/api";
import List from "list.js";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

const CustomerContact = ({ data }) => {
  const api = useApi();
  const [customer, setCustomer] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [showModalDomainRegistration, setShowModalDomainRegistration] = useState(false);
  const [showModalRegistration, setShowModalRegistration] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalEditDomain, setShowModalEditDomain] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedDomain, setSelectedDomain] = useState({});
  const [contacts, setContacts] = useState({ content: [] });
  const [domains, setDomains] = useState({ content: [] });
  const [filterContacts, setFilterContacts] = useState({
    page: 0,
    size: 10
  });

  const [filterDomains, setFilterDomains] = useState({
    page: 0,
    size: 10
  });

  useEffect(() => {
    setCustomer(data);
  }, [data]);

  useEffect(() => {
    if (Object.keys(customer).length != 0) {
      getContacts();
    }
  }, [customer, filterContacts]);

  useEffect(() => {
    if (Object.keys(customer).length != 0) {
      getDomains();
    }
  }, [customer, filterDomains]);

  const options = {
    valueNames: ['name', 'email', 'phone'],
    item: '<tr><td className="name"></td><td className="email"></td><td className="phone"></td></tr>'
  };
  const optionsDomain = {
    valueNames: ['domain'],
    item: '<tr><td className="domain"></td></tr>'
  };
  const contactList = new List('contacts', options);
  const domainList = new List('domains', optionsDomain);

  const getContacts = async () => {
    const queryParams = new URLSearchParams(filterContacts).toString();
    let response = await api.customersContacts.list(customer.id, queryParams);
    setContacts(response);
  };

  const getDomains = async () => {
    const queryParams = new URLSearchParams(filterContacts).toString();
    let response = await api.allowedDomains.list(customer.id, queryParams);
    setDomains(response);
  };

  const addContact = async body => {
    let response = await api.customersContacts.add(body);
    if (response.status) {
      setError(response);
      setShowError(true);
    } else {
      getContacts();
      setSuccess("Contato adicionado com sucesso");
      setShowSuccess(true);
    }
    handleCloseModalRegistration();
  };

  const addDomain = async body => {
    let response = await api.allowedDomains.add(customer.id, body);
    if (response.status) {
      setError(response);
      setShowError(true);
    } else {
      getDomains();
      setSuccess("Domínio liberado com sucesso");
      setShowSuccess(true);
    }
    handleCloseModalDomainRegistration();
  };

  const editContact = async body => {
    let response = await api.customersContacts.edit(selectedContact.id, body);
    if (response.status) {
      setError(response);
      setShowError(true);
    } else {
      getContacts();
      setSuccess("Contato editado com sucesso");
      setShowSuccess(true);
    }
    handleCloseModalEdit();
  };

  const deleteDomain = async response => {
    if (response) {
      let response = await api.allowedDomains.delete(customer.id, selectedDomain.id);
      if (response.ok) {
        getDomains();
        setSuccess("Domínio excluído com sucesso");
        setShowSuccess(true);
      } else {
        setError(response);
        setShowError(true);
      }
      handleCloseDialog();
    }
  };

  const editDomain = async body => {
    let response = await api.allowedDomains.edit(customer.id, selectedDomain.id, body);
    if (response.status) {
      setError(response);
      setShowError(true);
    } else {
      getDomains();
      setSuccess("Domínio editado com sucesso");
      setShowSuccess(true);
    }
    handleCloseModalEditDomain();
  };

  const handleCloseModalRegistration = () => {
    setShowModalRegistration(false);
  };

  const handleCloseModalDomainRegistration = () => {
    setShowModalDomainRegistration(false);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  };

  const handleCloseModalEditDomain = () => {
    setShowModalEditDomain(false);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleEdit = item => {
    setSelectedContact(item);
    setShowModalEdit(true);
  };

  const handleEditDomain = item => {
    setSelectedDomain(item);
    setShowModalEditDomain(true);
  };

  const handleDeleteDomain = item => {
    setShowDialog(true);
    setSelectedDomain(item);
  };

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <ContactCustomerRegistrationModal
        showModal={showModalRegistration}
        handleClose={handleCloseModalRegistration}
        handleSubmit={addContact}
        customer={customer} />
      <ContactCustomerEditModal
        data={selectedContact}
        refreshData={() => getContacts()}
        showModal={showModalEdit}
        handleClose={handleCloseModalEdit}
        handleSubmit={editContact}
        error={error => setError(error)} />
      <CustomerDomainRegistrationModal
        showModal={showModalDomainRegistration}
        handleClose={handleCloseModalDomainRegistration}
        handleSubmit={addDomain} />
      <CustomerDomainEditModal
        data={selectedDomain}
        showModal={showModalEditDomain}
        handleClose={handleCloseModalEditDomain}
        handleSubmit={editDomain} />
      <DialogModal
        message="Tem certeza que deseja excluir este domínio?"
        showModal={showDialog}
        handleClose={handleCloseDialog} response={deleteDomain} />
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Contatos</b>

          <IconButton
            className="ms-2"
            iconClassName="fs--2 me-1"
            variant="primary"
            size="sm"
            icon="plus"
            onClick={() => setShowModalRegistration(true)}>
            Novo
          </IconButton>
        </Card.Header>
        <Card.Body className="py-0">
          <div id="contacts">
            <div className="table-responsive scrollbar">
              <table className="table table-bordered table-striped fs-10 mb-0">
                <thead className="bg-100 text-900">
                  <tr>
                    <th className="sort" data-sort="name">Nome</th>
                    <th className="sort" data-sort="email">E-mail</th>
                    <th className="sort" data-sort="landline">Telefone</th>
                    <th className="sort" data-sort="phone">Celular</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {contacts.content.map((item, i) => (
                    <tr key={i}>
                      <td className="name">{item.name}</td>
                      <td className="email">{item.email}</td>
                      <td className="landline">{item.landline}</td>
                      <td className="phone">{item.phone}</td>
                      <td>
                        <IconButton
                          className="ms-2"
                          iconClassName="fs--2"
                          variant="link"
                          size="sm"
                          icon="pencil-alt"
                          onClick={() => handleEdit(item)}>
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              data={contacts}
              filters={filterContacts}
              setFilters={setFilterContacts} />
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Domínios liberados</b>

          <IconButton
            className="ms-2"
            iconClassName="fs--2 me-1"
            variant="primary"
            size="sm"
            icon="plus"
            onClick={() => setShowModalDomainRegistration(true)}>
            Novo
          </IconButton>
        </Card.Header>
        <Card.Body className="py-0">
          <div id="domains">
            <div className="table-responsive scrollbar">
              <table className="table table-bordered table-striped fs-10 mb-0">
                <thead className="bg-100 text-900">
                  <tr>
                    <th className="sort" style={{ width: '80%' }} data-sort="domain">Domínio</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {domains.content.map((item, i) => (
                    <tr key={i}>
                      <td className="domain">{item.domain}</td>
                      <td>
                        <IconButton
                          className="ms-2"
                          iconClassName="fs--2"
                          variant="link"
                          size="sm"
                          icon="pencil-alt"
                          onClick={() => handleEditDomain(item)}>
                        </IconButton>

                        <IconButton
                          className="ms-2"
                          iconClassName="fs--2"
                          variant="link"
                          size="sm"
                          icon="trash-alt"
                          onClick={() => handleDeleteDomain(item)}>
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              data={domains}
              filters={filterDomains}
              setFilters={setFilterDomains} />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomerContact;