import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import IntegrationList from './IntegrationList';

const LayoutIntegration = ({ children }) => {
  const [reportName, setReportName] = useState('');

  return (
    <Row className="gx-3">
      <Col md={2}>
        <IntegrationList reportName={name => setReportName(name)} />
      </Col>
      <Col md={10}>
        <Card>
          <Card.Body className='pt-0 ps-0'>
            {children}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LayoutIntegration;
