import { createContext, useContext, useState } from "react";

export const SelectedViewContext = createContext();

const SelectedViewProvider = ({ children }) => {
  const [selectedViewName, setSelectedViewName] = useState("Todos os tickets");

  return (
    <SelectedViewContext.Provider value={{ selectedViewName, setSelectedViewName }}>
      {children}
    </SelectedViewContext.Provider>
  );
};

export const useSelectedView = () => useContext(SelectedViewContext);

export default SelectedViewProvider;