import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { priorityMap } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const TicketsByPriority = ({ data }) => {
  const columns = [
    {
      accessorKey: 'colorPriority',
      header: '',
      enableSorting: false,  // Desabilitar ordenação para esta coluna
      cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <FontAwesomeIcon icon={faCircle} style={{ color: priorityMap[name].color }} />
        )
      }
    },
    {
      accessorKey: 'name',
      header: 'PRIORIDADE',
      meta: {
        headerProps: { className: 'text-secondary' }
      },
      cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <span className='text-body-emphasis mb-2'>{priorityMap[name].label}</span>
        );
      }
    },
    {
      accessorKey: 'value',
      header: 'QUANTIDADE',
      meta: {
        headerProps: { className: 'text-secondary' }
      },
      cell: rowData => {
        const { value } = rowData.row.original;
        return (
          <span className='text-body-emphasis'>{value}</span>
        );
      }
    },
  ];

  const table = useAdvanceTable({
    data,
    columns
  });

  return (
    <Card className="h-100 pt-3">
      <FalconCardHeader
        title="Tickets em Aberto por Prioridade"
        titleTag="h4"
        className="py-2"
      />
      <Card.Body>
        <Row className="w-100 h-100">
          <Col lg={12} xs={12}>
            <AdvanceTableProvider {...table}>
              <AdvanceTable
                table
                headerClassName="align-middle"
                rowClassName="btn-reveal-trigger align-middle"
                tableProps={{
                  size: 'sm',
                  className: 'w-100 fw-bold fs-9 overflow-hidden',
                  borderless: true,
                }}
              />
            </AdvanceTableProvider>
          </Col>
        </Row>
      </Card.Body>
      <style jsx>{`
        .table td {
          padding: 0.6rem 0.75rem;
        }
      `}</style>
    </Card>
  );
};

export default TicketsByPriority;
