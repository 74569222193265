import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Flex from 'components/common/Flex';
import Pagination from 'components/pagination';
import useApi from 'helpers/api';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Modal } from "react-bootstrap";
import { columns } from './columnsCompletedTicketsByServiceGroup';
import ExportMenu from './ExportMenu';

const CompletedTicketsByServiceGroupModal = ({
  show,
  close,
  tickets,
  filters,
  setFilters,
  groupId
}) => {
  const api = useApi();

  const table = useAdvanceTable({
    data: tickets?.content,
    columns
  });

  const downloadFile = async (format, agentId, filters, fileName) => {
    try {
      const queryParams = new URLSearchParams(filters).toString();
      let response;

      switch (format) {
        case 'xlsx':
          response = await api.reports.productivity.export.ticketsByServiceGroup.toXLSX(agentId, queryParams);
          break;
        case 'csv':
          response = await api.reports.productivity.export.ticketsByServiceGroup.toCSV(agentId, queryParams);
          break;
        case 'pdf':
          response = await api.reports.productivity.export.ticketsByServiceGroup.toPDF(agentId, queryParams);
          break;
        default:
          throw new Error('Formato de arquivo desconhecido');
      }

      if (!response.ok) {
        throw new Error(`Erro ao exportar o arquivo ${format.toUpperCase()}: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Limpa o URL do objeto e remove o link do DOM
      window.URL.revokeObjectURL(url);
      link.remove();

    } catch (error) {
      console.error(error.message);
    }
  };

  const fileName = 'tickets_concluidos';
  const downloadXLSX = () => downloadFile('xlsx', groupId, filters, `${fileName}.xlsx`);
  const downloadCSV = () => downloadFile('csv', groupId, filters, `${fileName}.csv`);
  const downloadPDF = () => downloadFile('pdf', groupId, filters, `${fileName}.pdf`);

  return (
    <Modal show={show} onHide={close} size='xl'>
      <Modal.Header closeButton>
        <Flex className="w-100" justifyContent="between">
          <Modal.Title>Tickets concluídos por grupo de serviço</Modal.Title>
          <ExportMenu
            downloadXLSX={downloadXLSX}
            downloadCSV={downloadCSV}
            downloadPDF={downloadPDF} />
        </Flex>
      </Modal.Header>
      <Modal.Body>
        {tickets?.content ?
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              headerClassName="bg-body-tertiary text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                borderless: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableProvider>
          : <p>Carregando...</p>}
      </Modal.Body>
      <Modal.Footer>
        <Pagination
          data={tickets}
          filters={filters}
          setFilters={setFilters} />
      </Modal.Footer>
    </Modal>
  );
};

export default CompletedTicketsByServiceGroupModal;