import Flex from "components/common/Flex";

const AppointmentsExecutiveCustomer = ({ data }) => {
  return (
    <Flex justifyContent="between" alignItems="center" className="p-3">
      <div>
        <span>Total Horas</span>
        <h5>{data && data.totalHours}</h5>
      </div>

      <div>
        <span>Horas internas</span>
        <h5>{data && data.internalHours}</h5>
      </div>

      <div>
        <span>Horas externas</span>
        <h5>{data && data.externalHours}</h5>
      </div>

      <div>
        <span>Apontamentos</span>
        <h5>{data && data.count}</h5>
      </div>
    </Flex>
  )
};

export default AppointmentsExecutiveCustomer;