import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const EmailBoxRegistrationModal = ({ showModal, handleClose, handleSubmit }) => {
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState({
    name: '',
    email: '',
    password: '',
    provider: '',
    imap: '',
    port: '',
  });
  const form = useRef(null);

  useEffect(() => {
    if(showModal) {
      clearFields();
      setValidated(false);
    }
  }, [showModal]);

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({
        name: body.name,
        email: body.email,
        password: body.password,
        provider: body.provider,
        imap: body.imap,
        port: body.port
      });
      clearFields();
      setValidated(false);
    }
  };

  function clearFields() {
    setBody({
      name: '',
      email: '',
      password: '',
      provider: '',
      imap: '',
      port: ''
    });
  }

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Nova caixa de email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="emailbox.name">
              <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                value={body.name}
                onChange={e => setBody(prevBody => ({ ...prevBody, name: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="emailbox.provider">
              <Form.Label>Provedor <span className="text-danger">*</span></Form.Label>
              <Form.Select aria-label="Provedor" className="flex-1 me-2" onChange={e => setBody(prevBody => ({ ...prevBody, provider: e.target.value }))} required>
                <option value="">Selecione uma opção</option>
                <option value="OFFICE_365">Office 365</option>
                <option value="GOOGLE">Google</option>
                <option value="OTHERS">Outros</option>
              </Form.Select>
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um provedor.</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="emailbox.email">
              <Form.Label>E-mail <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="E-mail"
                value={body.email}
                onChange={e => setBody(prevBody => ({ ...prevBody, email: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um e-mail.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="emailbox.password">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                required={body.provider == 'OTHERS'}
                disabled={body.provider != 'OTHERS' && body.provider != ''}
                type="password"
                placeholder="Senha"
                value={body.password}
                onChange={e => setBody(prevBody => ({ ...prevBody, password: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira uma senha.</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="10" controlId="emailbox.imap">
              <Form.Label>IMAP <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="IMAP"
                value={body.imap}
                onChange={e => setBody(prevBody => ({ ...prevBody, imap: e.target.value }))} />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira o endereço IMAP.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="2" controlId="emailbox.port">
              <Form.Label>Porta <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Porta"
                value={body.port}
                onChange={e => setBody(prevBody => ({ ...prevBody, port: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira uma porta.</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" type="submit" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailBoxRegistrationModal;