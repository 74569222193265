import React from 'react';
import LayoutReport from '../LayoutReport';
import ExecutiveCustomer from './ExecutiveCustomer';

const ReportExecutiveCustomer = () => {
  return (
    <LayoutReport>
      <ExecutiveCustomer />
    </LayoutReport>
  );
};

export default ReportExecutiveCustomer;