import IconButton from 'components/common/IconButton';
import useApi from 'helpers/api';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';

const CustomerInfo = ({ data, error, success }) => {
  const api = useApi();
  const form = useRef(null);
  const [body, setBody] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setBody(data);
  }, [data]);

  useEffect(() => {
    isJuridicalPerson && setBody({ ...body, name: body.fantasyName });
  }, [body.fantasyName]);

  const handleSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response = await api.customers.edit(body.id, body);
      if (response.status) {
        error(response);
      } else {
        success(true);
        setBody(response);
      }
      setValidated(false);
    }
  };

  const isJuridicalPerson = body.type === "JURIDICAL_PERSON";

  return (
    <div className="mb-3">
      <div>
        <Row>
          <Col lg xxl={12}>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Editar informações
            </h6>

            <Form validated={validated} ref={form}>
              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="customer.firstName">
                  <Form.Label>Tipo</Form.Label>
                  <Form.Select aria-label="Tipo" className="flex-1 me-2" onChange={e => setBody(prevBody => ({ ...prevBody, type: e.target.value }))} required value={body.type}>
                    <option value="">Selecione uma opção</option>
                    <option value="NATURAL_PERSON">Física</option>
                    <option value="JURIDICAL_PERSON">Jurídica</option>
                  </Form.Select>
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um tipo.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="customer.firstName">
                  <Form.Label>Status</Form.Label>
                  <Form.Select aria-label="Tipo" className="flex-1 me-2" onChange={e => setBody(prevBody => ({ ...prevBody, inactive: e.target.value }))} required value={body.inactive}>
                    <option value="">Selecione uma opção</option>
                    <option value="false">Ativo</option>
                    <option value="true">Inativo</option>
                  </Form.Select>
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um tipo.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="customer.lastName">
                  <Form.Label>Razão social</Form.Label>
                  <Form.Control
                    required={body.type === "JURIDICAL_PERSON"}
                    type="text"
                    placeholder="Razão social"
                    value={body.corporateName}
                    onChange={e => setBody(prevBody => ({ ...prevBody, corporateName: e.target.value }))}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira uma razão social.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="customer.lastName">
                  <Form.Label>Nome fantasia</Form.Label>
                  <Form.Control
                    required={body.type === "JURIDICAL_PERSON"}
                    type="text"
                    placeholder="Nome fantasia"
                    value={body.fantasyName}
                    onChange={e => setBody(prevBody => ({ ...prevBody, fantasyName: e.target.value }))}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um nome fantasia.</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="customer.cnpj">
                  <Form.Label>CNPJ</Form.Label>
                  <MaskedInput
                    mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                    guide={false}
                    required={body.type === "JURIDICAL_PERSON"}
                    type="text"
                    placeholder="CNPJ"
                    value={body.type === "JURIDICAL_PERSON" ? body.cnpj : null}
                    onChange={e => setBody(prevBody => ({ ...prevBody, cnpj: e.target.value }))}
                    render={(ref, props) => <Form.Control ref={ref} {...props} />}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um CNPJ.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="customer.ie">
                  <Form.Label>Inscrição estadual</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Inscrição estadual"
                    value={body.stateRegistration}
                    onChange={e => setBody(prevBody => ({ ...prevBody, stateRegistration: e.target.value }))}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um inscrição estadual.</Form.Control.Feedback>
                </Form.Group>
              </Row>

              {!isJuridicalPerson &&
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="customer.fullname">
                    <Form.Label>Nome completo</Form.Label>
                    <Form.Control
                      required={body.type === "NATURAL_PERSON"}
                      disabled={body.type === "JURIDICAL_PERSON"}
                      type="text"
                      placeholder="Nome completo"
                      value={body.name}
                      onChange={e => setBody(prevBody => ({ ...prevBody, name: e.target.value }))}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um nome completo.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="customer.cpf">
                    <Form.Label>CPF</Form.Label>

                    <MaskedInput
                      mask={[/[1-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                      guide={false}
                      required={body.type === "NATURAL_PERSON"}
                      disabled={body.type === "JURIDICAL_PERSON"}
                      type="text"
                      placeholder="CPF"
                      value={body.cpf}
                      onChange={e => setBody(prevBody => ({ ...prevBody, cpf: e.target.value }))}
                      render={(ref, props) => <Form.Control ref={ref} {...props} />}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um cpf.</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              }
            </Form>
          </Col>
        </Row>
      </div>
      <div className="border-top text-end pt-3">
        <IconButton
          className="ms-2"
          iconClassName="fs--2 me-1"
          variant="falcon-primary"
          size="sm"
          icon="check"
          onClick={handleSubmit}
        >
          Salvar
        </IconButton>
      </div>
    </div>
  );
};

export default CustomerInfo;