import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TableRow from '../../dashboards/support-desk/TableRow';
import TicketsByServiceGroupChart from './TicketsByServiceGroupChart';

const TicketsByServiceGroup = ({ data }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Tickets em Aberto por Grupo de Serviço"
        titleTag="h4"
        className="py-2"
      />
      <Card.Body as={Flex} alignItems="center" className="p-3">
        <Row className="w-100">
          <Col lg={12} xs={12}>
            <TicketsByServiceGroupChart data={data} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

TicketsByServiceGroup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(TableRow.propTypes.data)).isRequired
};

export default TicketsByServiceGroup;
