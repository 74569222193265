import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import FalconComponentCard from "components/common/FalconComponentCard";
import AssetTypeRegistrationModal from "components/modals/AssetTypeRegistrationModal";
import Pagination from "components/pagination";
import useApi from 'helpers/api';
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AssetTypeRegistration = () => {
  const api = useApi();
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: false
  });
  const [types, setTypes] = useState({ content: [] });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getData();
  }, [filters]);

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.assetTypes.list(queryParams);
    if (response.content) setTypes(response);
  };

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900 w-75' }
      },
      cell: rowData => {
        const { id, name } = rowData.row.original;
        return (
          <Link
            to={`/registration/asset-types/${id}`}
          >
            {name}
          </Link>
        );
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { inactive } = rowData.row.original;
        return inactive ? (
          <span className="text-danger">Inativo</span>
        ) : (
          <span className="text-success">Ativo</span>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: types.content,
    columns
  });

  return (
    <>
      <AssetTypeRegistrationModal
        show={showModal}
        close={() => setShowModal(false)}
        refresh={() => getData()} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Tipos de ativo">
          <Button
            type="button"
            variant="primary"
            size="sm"
            onClick={() => setShowModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
            Novo
          </Button>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body className="bg-white">
          <Row className="justify-content-end g-0">
            <Col className="col-auto px-3">
              <Form.Select
                size="sm"
                className="mb-3 rounded-pill"
                onChange={e =>
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    includeInactives: e.target.value
                  }))
                }
              >
                <option value="true">Filtro por status</option>
                <option value="true">Todos</option>
                <option value="false">Somente ativos</option>
              </Form.Select>
            </Col>
          </Row>
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableProvider>
          <Pagination
            data={types}
            filters={filters}
            setFilters={setFilters}
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default AssetTypeRegistration;