import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import MultiSelect from "components/common/MultiSelect";
import TreeSelect from "components/common/TreeSelect";
import Editor from "components/editor/Editor";
import useApi from 'helpers/api';
import { daysOfWeek, formatDateTimeUs, getDaysOfMonth, priorities } from "helpers/utils";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ScheduledTicketRegistration = () => {
  const navigate = useNavigate();
  const api = useApi();

  const form = useRef(null);
  const inputContact = useRef(null);

  const [validated, setValidated] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});

  const [filters, setFilters] = useState({
    page: 0,
    size: 200,
    includeInactives: false,
    name: ''
  });

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [formattedCustomers, setFormattedCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [contacts, setContacts] = useState([]);
  const [catalogs, setCatalogs] = useState([
    { categories: [{ subcategories: [] }] }
  ]);
  const [agents, setAgents] = useState([]);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [typesTicket, setTypesTicket] = useState([]);
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);
  const [body, setBody] = useState({
    name: '',
    description: '',
    repeatType: '',
    repeatEvery: 1,
    dayOfMonth: 1,
    daysOfWeek: [],
    startDate: '',
    ticketSubject: '',
    ticketDescription: '',
    ticketPriority: '',
    agent: { id: '' },
    serviceGroup: { id: '' },
    serviceCatalog: { id: '' },
    category: { id: '' },
    subcategory: { id: '' },
    contactCustomer: { id: '' },
    typeTicket: { id: '' },
    customers: []
  });

  const isWeekly = body.repeatType === "WEEKLY";
  const isDaily = body.repeatType === "DAILY";
  const isMonthly = body.repeatType === "MONTHLY";

  useEffect(() => {
    setBody({ ...body, startDate: formatDateTimeUs(new Date()) });
    getCustomers();
    getServiceGroups();
    getTypesTicket();
  }, []);

  useEffect(() => {
    if (body.serviceGroup.id != '') {
      getAgents();
      getServiceCatalogs();
    }
  }, [body.serviceGroup.id]);

  useEffect(() => {
    selectedDaysOfWeek.length > 0 && setBody({ ...body, daysOfWeek: selectedDaysOfWeek.map(item => item.value) });
  }, [selectedDaysOfWeek]);

  useEffect(() => {
    selectedCustomers.length > 0 && setBody({ ...body, customers: selectedCustomers.map(item => item.value) });
  }, [selectedCustomers]);

  useEffect(() => {
    setFormattedCustomers(customers.map(customer => ({
      value: customer.id,
      label: customer.fantasyName ? customer.fantasyName : customer.name
    })))
  }, [customers]);

  useEffect(() => {
    getContacts();
  }, [filters.name]);

  const getCustomers = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.customers.list(queryParams);
    response.content && setCustomers(response.content);
  };

  const getServiceGroups = async () => {
    const queryParams = new URLSearchParams(filters);
    const response = await api.serviceGroups.listSummaryData(queryParams);
    response.content && setServiceGroups(response.content);
  };

  const getAgents = async () => {
    const response = await api.serviceGroupsAgents.list(body.serviceGroup.id);
    response && setAgents(response);
  };

  const getTypesTicket = async () => {
    const queryParams = new URLSearchParams(filters);
    const response = await api.typeTickets.list(queryParams);
    response.content && setTypesTicket(response.content);
  };

  const getServiceCatalogs = async () => {
    if (body.serviceGroup.id) {
      let response = await api.serviceCatalogs.listByServiceGroupTreeView(body.serviceGroup.id);
      setCatalogs(response);
    }
  };

  const getContacts = async () => {
    if (filters.name.length > 2) {
      const queryParams = new URLSearchParams(filters);
      const response = await api.contacts.list(queryParams);
      response.content && setContacts(response.content);
    } else if (filters.name.length === 0) {
      setContacts([]);
    }
  };

  const handleSubmit = async e => {
    if (form.current.checkValidity() === false || body.ticketDescription.trim() === '' || body.ticketDescription === '<p><br></p>') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let updatedBody = body;
      if (!isWeekly) {
        updatedBody = removeFromBody('daysOfWeek');
      } else if (!isMonthly) {
        updatedBody = removeFromBody('dayOfMonth');
      } else {
        updatedBody = removeFromBody('daysOfWeek');
        updatedBody = removeFromBody('dayOfMonth');
      }

      const response = await api.scheduledsTickets.create(updatedBody);
      if (response.id) {
        setValidated(false);
        setShowSuccess(true);
        setTimeout(() => {
          navigate("/registration/scheduleds-tickets");
        }, 2000);
      } else {
        setError(response);
        setShowError(true);
      }
    }
  };

  const handleChangeBody = e => {
    setBody({ ...body, [e.target.name]: e.target.value });
  };

  const removeFromBody = key => {
    const updatedState = body;
    delete updatedState[key];
    return updatedState;
  };

  const handleSelectServiceCatalog = ids => {
    setBody({
      ...body,
      serviceCatalog: { id: parseInt(ids[0]) },
      category: { id: parseInt(ids[1]) },
      subcategory: { id: parseInt(ids[2]) }
    });
  }

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message="Política de ticket agendado criada com sucesso" />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error.userMessage} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Novo ticket agendado">
          <div className="d-flex align-items-center">
            <IconButton
              onClick={() => navigate(-1)}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
              className="me-2"
            />
          </div>
        </FalconComponentCard.Header>

        <FalconComponentCard.Body>
          <Row>
            <Col md={6}>
              <Form ref={form} validated={validated}>
                <p className="fs-8 fw-semibold">Geral</p>

                <Form.Group className="mb-3 w-75">
                  <Form.Label>Nome <span className="text-danger"> *</span></Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={body.name}
                    name="name"
                    onChange={handleChangeBody} />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Nome é obrigatório</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 w-75">
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="(opcional)"
                    value={body.description}
                    name="description"
                    onChange={handleChangeBody} />
                </Form.Group>

                <Form.Group className="mb-3 w-75">
                  <Form.Label>Repetição <span className="text-danger"> *</span></Form.Label>
                  <Form.Select
                    required
                    name="repeatType"
                    value={body.repeatType}
                    onChange={handleChangeBody}>
                    <option value="" key="0">Selecionar...</option>
                    <option value="DAILY" key="DAILY">Diário</option>
                    <option value="WEEKLY" key="WEEKLY">Semanal</option>
                    <option value="MONTHLY" key="MONTHLY">Mensal</option>
                  </Form.Select>
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Repetição é obrigatório</Form.Control.Feedback>
                </Form.Group>

                {isDaily &&
                  <>
                    <Form.Group className="mb-3 w-75">
                      <Form.Label>Repetir a cada <span className="text-danger"> *</span></Form.Label>
                      <Flex alignItems="center">
                        <Form.Control
                          type="number"
                          min={1}
                          required
                          name="repeatEvery"
                          value={body.repeatEvery}
                          onChange={handleChangeBody}
                          className="w-50 me-3" />
                        <span>dia(s)</span>
                      </Flex>
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Repetir a cada é obrigatório</Form.Control.Feedback>
                    </Form.Group>
                  </>
                }

                {isWeekly &&
                  <>
                    <Form.Group className="mb-3 w-50">
                      <Form.Label>
                        Dias da semana
                        <OverlayTrigger overlay={<Tooltip>
                          Por favor, coloque os dias da semana em ordem cronológica para garantir que o próximo agendamento seja calculado corretamente.
                        </Tooltip>}>
                          <span className="ms-1"><FontAwesomeIcon icon={faInfoCircle} size="sm" /></span>
                        </OverlayTrigger>
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <MultiSelect
                        required={isWeekly}
                        placeholder="Selecione..."
                        options={daysOfWeek}
                        value={selectedDaysOfWeek}
                        onChange={selectedOptions => setSelectedDaysOfWeek(selectedOptions)} />
                    </Form.Group>
                    <Form.Group className="mb-3 w-75">
                      <Form.Label>Repetir a cada <span className="text-danger"> *</span></Form.Label>
                      <Flex alignItems="center">
                        <Form.Control
                          type="number"
                          min={1}
                          required
                          name="repeatEvery"
                          value={body.repeatEvery}
                          onChange={handleChangeBody}
                          className="w-50 me-3" />
                        <span>semana(s)</span>
                      </Flex>
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Repetir a cada é obrigatório</Form.Control.Feedback>
                    </Form.Group>
                  </>
                }
                {isMonthly &&
                  <>
                    <Form.Group className="mb-3 w-50">
                      <Form.Label>Dia do mês <span className="text-danger"> *</span></Form.Label>
                      <Form.Select
                        required={isMonthly}
                        value={body.dayOfMonth}
                        onChange={handleChangeBody} name="dayOfMonth">
                        {getDaysOfMonth().map((item, i) => (
                          <option key={i}>{item}</option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Dia do mês é obrigatório</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 w-75">
                      <Form.Label>Repetir a cada <span className="text-danger"> *</span></Form.Label>
                      <Flex alignItems="center">
                        <Form.Control
                          type="number"
                          min={1}
                          required
                          name="repeatEvery"
                          value={body.repeatEvery}
                          onChange={handleChangeBody}
                          className="w-50 me-3" />
                        <span>mês(es)</span>
                      </Flex>
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Repetir a cada é obrigatório</Form.Control.Feedback>
                    </Form.Group>
                  </>
                }

                {body.repeatType != '' &&
                  <Form.Group className="w-50">
                    <Form.Label>Primeira data agendada <span className="text-danger"> *</span></Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="startDate"
                      required
                      min={new Date()}
                      value={body.startDate}
                      onChange={handleChangeBody} />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Primeira data é obrigatório</Form.Control.Feedback>
                  </Form.Group>
                }
              </Form>
            </Col>

            <Col md={6}>
              <Form ref={form} validated={validated}>
                <p className="fs-8 fw-semibold">Campos do ticket</p>

                <Row>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Grupo de serviço <span className="text-danger"> *</span></Form.Label>
                    <Form.Select
                      required
                      value={body.serviceGroup.id}
                      name="serviceGroup"
                      onChange={e => setBody({ ...body, serviceGroup: { id: parseInt(e.target.value) } })}>
                      <option value="" key="-1">Selecionar...</option>
                      {serviceGroups.map((item, i) => (
                        <option value={item.id} key={item.name}>{item.name}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Grupo de serviço é obrigatório</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Agente <span className="text-danger"> *</span></Form.Label>
                    <Form.Select
                      value={body.agent.id}
                      name="agent"
                      onChange={e => setBody({ ...body, agent: { id: parseInt(e.target.value) } })}
                      required
                      placeholder="Selecionar...">
                      <option value="" key="-1">Selecionar...</option>
                      {agents && agents.map(item => (
                        <option value={item.id} key={item.firstName}>{item.firstName}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Agente é obrigatório</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Clientes <span className="text-danger"> *</span></Form.Label>
                    <MultiSelect
                      required
                      placeholder="Selecionar..."
                      options={formattedCustomers}
                      value={selectedCustomers}
                      onChange={options => setSelectedCustomers(options)} />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Cliente é obrigatório</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Contato padrão <span className="text-danger"> *</span></Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Digite para pesquisar'
                      value={filters.name}
                      ref={inputContact}
                      onFocus={() => setShowMenu(true)}
                      onChange={e => setFilters(prevFilters => ({ ...prevFilters, name: e.target.value }))} />
                    {showMenu &&
                      <ul className='px-0 list-customer bg-100 shadow rounded z-3' style={{ height: 'auto', maxHeight: 350, overflowY: 'auto' }}>
                        {contacts.map(item => (
                          <li
                            style={{ listStyleType: 'none', cursor: 'pointer' }}
                            className='px-3 py-2 bg-primary-hover item-list-hover'
                            data-value={item.id}
                            key={item.name + item.id}
                            onClick={() => {
                              setFilters(prevFilters => ({ ...prevFilters, name: item.name }));
                              setBody({ ...body, contactCustomer: { id: parseInt(item.id) } });
                              setShowMenu(false);
                            }}>
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    }
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Selecione um cliente.</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Prioridade <span className="text-danger"> *</span></Form.Label>
                    <Form.Select
                      value={body.ticketPriority}
                      name="ticketPriority"
                      onChange={handleChangeBody}
                      required
                      placeholder="Selecionar...">
                      <option value="" key="-2">Selecionar...</option>
                      {priorities.map(item => (
                        <option value={item.value} key={item.name}>{item.name}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Prioridade é obrigatório</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Tipo de ticket <span className="text-danger"> *</span></Form.Label>
                    <Form.Select
                      value={body.typeTicket.id}
                      name="typeTicket"
                      onChange={e => setBody({ ...body, typeTicket: { id: parseInt(e.target.value) } })}
                      required
                      placeholder="Selecionar...">
                      <option value="" key="-1">Selecionar...</option>
                      {typesTicket && typesTicket.map(item => (
                        <option value={item.id} key={item.name}>{item.name}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Tipo de ticket é obrigatório</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md={12} className="mb-3">
                    <Form.Label>Catálogo de serviço <span className="text-danger"> *</span></Form.Label>
                    <TreeSelect data={catalogs} onSelect={handleSelectServiceCatalog} />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Catálogo de serviço é obrigatório</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label>Assunto <span className="text-danger"> *</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="ticketSubject"
                    value={body.ticketSubject}
                    onChange={handleChangeBody}
                    required />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Assunto é obrigatório</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md={12} className="mb-2">
                  <Form.Label>Descrição <span className="text-danger">*</span></Form.Label>
                  <Editor data={body.ticketDescription} value={value => setBody({ ...body, ticketDescription: value })} />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira uma descrição.</Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <footer className="w-100 d-flex justify-content-end">
            <Button variant="falcon-default" className="me-2" onClick={() => navigate(-1)}>Cancelar</Button>
            <Button type="submit" onClick={handleSubmit}>Salvar</Button>
          </footer>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default ScheduledTicketRegistration;