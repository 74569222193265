import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { colors } from 'helpers/utils';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PizzaChart from './Pie';

const TicketsByCatalog = ({ data, title }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title={title}
        titleTag="h4"
        className="py-2"
      />
      <Card.Body className="p-3 d-flex align-items-center">
        <Row className="w-100">
          <Col lg={8} xs={12}>
            {data &&
              <PizzaChart data={data} />
            }
          </Col>

          <Col lg={4} xs={12} className="h-100 scroll-area">
            <div style={{ height: '300px', overflowY: 'scroll' }}>
              <h6 className="mb-3">Resumo</h6>
              <div>
                {data &&
                  data.map((item, i) => (
                    <div key={item.name}>
                      <Flex>
                        <FontAwesomeIcon
                          icon={faCircle}
                          color={colors[i]}
                          size="sm"
                        />
                        <h6
                          className="m-0 p-0 ms-1 text-nowrap overflow-hidden"
                          style={{ textOverflow: 'ellipsis' }}
                        >
                          {item.name}
                        </h6>
                      </Flex>
                      <p className="fs-10">Tickets {item.value}</p>
                    </div>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TicketsByCatalog;
