import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import Loading from 'components/loading/Loading';
import Pagination from 'components/pagination';
import useApi from 'helpers/api';
import { formatTimeToHoursAndMinutes } from 'helpers/utils';
import { useReportFilters } from 'hooks/useReportFilters';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, OverlayTrigger, ProgressBar, Row, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import CompletedTicketsByAgentModal from './CompletedTicketsByAgentModal';
import HoursIndicatedByAgentModal from './HoursIndicatedByAgentModal';

const AgentsRow = ({
  id,
  firstName,
  lastName,
  inactive,
  completedTicketsPercentage,
  completedTickets,
  totalHours,
  totalHoursPercentage,
  slaSolutionCompliance,
  averageTimeSpentOnTicket,
  isLast,
  onAgentSelect,
}) => {
  const api = useApi();
  const [photo, setPhoto] = useState('');

  useEffect(() => {
    const fetchUserPhoto = async () => {
      const response = await api.agentPhoto.find(id);
      if (response.ok) {
        const data = await response.json();
        setPhoto(data.url);
      }
    };

    fetchUserPhoto();
  }, [id, api]);

  return (
    <tr className={classNames({ 'border-bottom border-200': !isLast })}>
      <td>
        <Flex alignItems="center" className="position-relative">
          <Avatar
            size="2xl"
            src={photo || ''}
            width="60"
            alt={firstName}
            name={firstName}
          />
          <div className="flex-1 ms-3">
            <h6 className="mb-0 fw-semibold">
              <Link className="text-1100 stretched-link" to="#!">
                {firstName} {lastName}{' '}
                {inactive && <span className='text-danger'>(Inativo)</span>}
              </Link>
            </h6>
          </div>
        </Flex>
      </td>
      <td className="align-middle text-center fw-semibold">
        <Flex alignItems="center">
          <OverlayTrigger
            overlay={
              <Tooltip>
                Tickets resolvidos: {completedTickets} - {completedTicketsPercentage}%
              </Tooltip>
            }
          >
            <ProgressBar
              now={completedTicketsPercentage}
              style={{ height: 5 }}
              className="flex-1 rounded-pill align-middle"
              variant="progress-gradient"
              onClick={() => onAgentSelect(id, 1)}
              role='button'
            />
          </OverlayTrigger>
        </Flex>
      </td>
      <td className="align-middle text-center fw-semibold">
        <SubtleBadge pill bg='info'>
          {totalHoursPercentage || 0}%
        </SubtleBadge>
        <p
          className="fs-10 mb-0"
          role='button'
          onClick={() => onAgentSelect(id, 2)}
        >
          {totalHours || '00:00'}
        </p>
      </td>
      <td className="align-middle text-center fw-semibold">
        <SubtleBadge pill bg='info'>
          {slaSolutionCompliance || 0}%
        </SubtleBadge>
      </td>
      <td className="align-middle">
        <Row className="g-2 justify-content-end">
          <Col xs="auto">
            <p className="fs-10 mb-0">
              {formatTimeToHoursAndMinutes(averageTimeSpentOnTicket)}
            </p>
          </Col>
        </Row>
      </td>
    </tr>
  );
};

const ProductivityByAgent = ({ filters }) => {
  const api = useApi();

  const [productivityFilters, setProductivityFilters] = useReportFilters(filters);
  const [completedTicketsFilters, setCompletedTicketsFilters] = useReportFilters(filters);
  const [hoursIndicatedFilters, setHoursIndicatedFilters] = useReportFilters(filters);

  const [data, setData] = useState({ content: [] });
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [completedTickets, setCompletedTickets] = useState({});
  const [hoursIndicatedTickets, setHoursIndicatedTickets] = useState({});
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState(0);
  const [showCompletedTickets, setShowCompletedTickets] = useState(false);
  const [showHoursIndicated, setShowHoursIndicated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(productivityFilters).toString();
      const response = await api.reports.productivity.productivityByAgent(queryParams);
      setData(response.content ? response : {});
    };

    fetchData();
  }, [productivityFilters, api]);

  useEffect(() => {
    if (selectedAgentId && reportType === 1) {
      const fetchCompletedTickets = async () => {
        setLoading(true);
        const queryParams = new URLSearchParams(completedTicketsFilters).toString();
        const response = await api.reports.productivity.completedTicketsByAgent(selectedAgentId, queryParams);
        if (response.content) {
          setCompletedTickets(response);
        }
        setLoading(false);
      };

      fetchCompletedTickets();
    }
  }, [selectedAgentId, reportType, completedTicketsFilters, api]);

  useEffect(() => {
    if (selectedAgentId && reportType === 1) {
      setShowCompletedTickets(true);
    } else if (selectedAgentId && reportType === 2) {
      setShowHoursIndicated(true);
    }
  }, [selectedAgentId, reportType, api]);

  useEffect(() => {
    if (selectedAgentId && reportType === 2) {
      const fetchHoursIndicatedTickets = async () => {
        setLoading(true);
        const queryParams = new URLSearchParams(hoursIndicatedFilters).toString();
        const response = await api.reports.productivity.hoursIndicatedByAgent(selectedAgentId, queryParams);
        if (response.content) {
          setHoursIndicatedTickets(response);
        }
        setLoading(false);
      };

      fetchHoursIndicatedTickets();
    }
  }, [selectedAgentId, hoursIndicatedFilters, reportType, api]);

  const handleAgentSelection = (agentId, report) => {
    setSelectedAgentId(agentId);
    setReportType(report);

    if (report === 1) {
      setCompletedTicketsFilters((prevFilters) => ({ ...prevFilters, page: 0 }));
    } else if (report === 2) {
      setHoursIndicatedFilters((prevFilters) => ({ ...prevFilters, page: 0 }));
    }
  };

  return (
    <>
      {loading && <Loading />}
      <CompletedTicketsByAgentModal
        show={showCompletedTickets}
        close={() => {
          setShowCompletedTickets(false);
          setSelectedAgentId(null);
        }}
        tickets={completedTickets}
        filters={completedTicketsFilters}
        setFilters={setCompletedTicketsFilters}
        agentId={selectedAgentId}
      />
      <HoursIndicatedByAgentModal
        show={showHoursIndicated}
        close={() => {
          setShowHoursIndicated(false);
          setSelectedAgentId(null);
        }}
        tickets={hoursIndicatedTickets}
        filters={hoursIndicatedFilters}
        setFilters={setHoursIndicatedFilters}
        agentId={selectedAgentId}
      />
      <Card className="h-100 my-3">
        <FalconCardHeader
          title="Produtividade por Agente"
          titleTag="h4"
          className="py-2"
        />
        <Card.Body className="p-0">
          <SimpleBarReact>
            <Table
              borderless
              className="mb-0 fs-10 border-200 rounded-3 table-dashboard table-member-info"
            >
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-900">Agente</th>
                  <th className="text-900 text-center">
                    Tickets Concluídos
                    <OverlayTrigger overlay={<Tooltip>Para visualizar os tickets de forma detalhada, clique sobre a barra de progressão.</Tooltip>}>
                      <span className='ms-1'><FontAwesomeIcon icon={faInfoCircle} /></span>
                    </OverlayTrigger>
                  </th>
                  <th className="text-900 text-center">
                    Horas Apontadas
                    <OverlayTrigger overlay={<Tooltip>Para visualizar as horas apontadas de forma detalhada, clique sobre as horas.</Tooltip>}>
                      <span className='ms-1'><FontAwesomeIcon icon={faInfoCircle} /></span>
                    </OverlayTrigger>
                  </th>
                  <th className="text-900 text-center">Conformidade SLA Solução</th>
                  <th className="text-900 text-end">Média por Ticket</th>
                </tr>
              </thead>
              <tbody>
                {data.content.map((info, index) => (
                  <AgentsRow
                    {...info}
                    isLast={index === data.length - 1}
                    key={info.id}
                    onAgentSelect={handleAgentSelection}
                  />
                ))}
              </tbody>
            </Table>
          </SimpleBarReact>
        </Card.Body>

        <Card.Footer className="bg-body-tertiary py-2">
          <Pagination data={data} filters={productivityFilters} setFilters={setProductivityFilters} />
        </Card.Footer>
      </Card>
    </>
  );
};

ProductivityByAgent.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default ProductivityByAgent;
