import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';

const HolidayRegistrationModal = ({ showModal, handleClose, handleSubmit }) => {
  const [body, setBody] = useState({
    date: '',
    description: ''
  });
  const [validated, setValidated] = useState(false);
  const form = useRef(null);
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    setBody({
      date: '',
      description: ''
    });
    setValidated(false);
  }, [showModal]);

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      handleSubmit({ date: formattDate(body.date), description: body.description });
      setBody({
        date: '',
        description: ''
      });
    }
  };

  const handleSetBody = (fieldName, fieldValue) => {
    setBody(prevBody => ({ ...prevBody, [fieldName]: fieldValue }));
  };

  const getMonth = date => {
    const d = new Date(date);
    return d.getMonth();
  };

  const formattDate = date => {
    const d = new Date(date);
    let dayOfMonth = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    return `${dayOfMonth}/${month}`;
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar feriado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="w-100">Data <span className="text-danger">*</span></Form.Label>
            <DatePicker
              required
              placeholderText="Data"
              className="form-control"
              selected={body.date}
              renderCustomHeader={({
                date,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button variant="link" size="sm" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {"<"}
                  </Button>
                  <select
                    className="form-control border border-0"
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <Button variant="link" size="sm" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {">"}
                  </Button>
                </div>
              )}
              onChange={date => handleSetBody('date', date)}
              dateFormat="dd/MM" />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira uma data.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Descrição <span className="text-danger">*</span></Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Descrição"
              value={body.description}
              onChange={e => handleSetBody('description', e.target.value)}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira uma descrição.</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HolidayRegistrationModal;