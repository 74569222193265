import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { formatValue, getColor, rgbaColor } from 'helpers/utils';
import { useAppContext } from 'providers/AppProvider';
import React, { forwardRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (opened, closed, diff, isDark) => ({
  color: [
    getColor('primary'),
    isDark ? '#5A8D05' : '#D2F669'
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-900') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: function (params) {
      return `<div>${params.seriesName}</div>
      <div class="fs-10 d-flex flex-between-center">
      <div>
      <div class="dot me-1 fs--2 d-inline-block" style="background-color:${params.borderColor ? params.borderColor : params.color
        }"></div>
        <span>${formatValue(params.name)} : </span>
        </div>
      <strong>${params.value}</strong>
      </div>`;
    }
  },
  legend: {
    show: false
  },
  xAxis: {
    data: opened.map(item => item.date),
    splitLine: { show: false },
    splitArea: { show: false },
    axisLabel: {
      formatter: value => formatValue(value),
      color: getColor('gray-600')
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: {
      show: false
    }
  },
  yAxis: {
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLabel: {
      color: getColor('gray-600')
    }
  },
  series: [
    {
      name: 'Tickets abertos',
      type: 'bar',
      stack: 'one',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: opened && opened.map(item => item.count)
    },
    {
      name: 'Tickets fechados',
      type: 'bar',
      stack: 'two',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: closed && closed.map(item => item.count)
    },
    {
      type: 'line',
      name: 'Saldo',
      data: diff,
      symbol: 'circle',
      symbolSize: 6,
      animation: false,
      itemStyle: {
        color: getColor('warning')
      },
      lineStyle: {
        type: 'dashed',
        width: 2,
        color: getColor('warning')
      }
    }
  ],
  itemStyle: {
    borderRadius: [3, 3, 0, 0]
  },
  barWidth: '12px',
  grid: {
    top: '10%',
    bottom: 0,
    left: 0,
    right: 0,
    containLabel: true
  }
});

const NumberOfTicketsChart = forwardRef(({ opened, closed, diff }, ref) => {
  const { config } = useAppContext();
  const { isDark } = config;

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(opened, closed, diff, isDark)}
      ref={ref}
      style={{ height: '18.5rem', minWidth: '40rem' }}
    />
  );
});

export default NumberOfTicketsChart;