import BlotFormatter from 'quill-blot-formatter';
import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';

Quill.register('modules/blotFormatter', BlotFormatter);

const Editor = props => {
  const [editorHtml, setEditorHtml] = useState('');

  useEffect(() => {
    const toolbar = document.querySelector('.ql-toolbar');
    if (toolbar) {
      toolbar.setAttribute('tabIndex', -1);
      toolbar.querySelectorAll('button').forEach((item) => {
        item.setAttribute('tabIndex', -1);
      });
    }
  }, []);

  useEffect(() => {
    if(props.data) {
      setEditorHtml(props.data);
    }
  }, [props.data]);

  const handleChange = (html) => {
    setEditorHtml(html);
    props.value(html);
  };


  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    },
    blotFormatter: {}
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  return (
    <ReactQuill
      className='mb-3'
      formats={formats}
      theme={props.theme}
      onChange={handleChange}
      value={editorHtml}
      modules={modules}
      bounds={'.quill'}
      placeholder="Insira uma descrição"
    />
  );
};

export default Editor;
