import { faTicketAlt, faUserAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const ReportList = ({ reportName }) => {
  const reports = [
    {
      name: "Visão Geral do Service Desk",
      value: '/reports/overview',
      icon: faTicketAlt
    },
    {
      name: 'Análise de Produtividade',
      value: '/reports/productivity-analysis',
      icon: faUsers
    },
    {
      name: "Resumo Executivo",
      value: '/reports/executive',
      icon: faUserAlt
    }
  ];

  const location = useLocation();
  const initialReport = reports.find(report => report.value === location.pathname) || reports[0];

  const [selectedReport, setSelectedReport] = useState(initialReport);
  const navigate = useNavigate();

  useEffect(() => {
    reportName(selectedReport.name);
    if (selectedReport.value !== location.pathname) {
      navigate(selectedReport.value);
    }
  }, [selectedReport, reportName, navigate, location.pathname]);

  return (
    <FalconComponentCard className="position-sticky top-0" style={{ height: '85vh' }}>
      <FalconComponentCard.Header title="Relatórios" />
      <Card.Body className="p-0">
        <Flex direction="column" alignItems="start">
          {reports.map(item => (
            <Button
              key={item.value}
              onClick={() => setSelectedReport(item)}
              variant="link"
              size="sm"
              className={`d-flex align-items-center w-100 py-2 rounded text-body${selectedReport.name === item.name ? ' bg-100' : ''}`}
              style={{ textDecoration: 'none' }}
            >
              <FontAwesomeIcon icon={item.icon} className={`me-2${selectedReport.name === item.name ? ' text-primary' : ' text-secondary'}`} />
              {item.name}
            </Button>
          ))}
        </Flex>
      </Card.Body>
    </FalconComponentCard>
  );
};

export default ReportList;
