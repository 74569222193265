export const rootPaths = {
  registrationRoot: '/registration',
  errorsRoot: '/errors',
  authRoot: '/authentication',
  dashboardRoot: '/dashboards',
  ticketsRoot: '/tickets',
  userRoot: '/user',
  companyRoot: '/company',
  reports: '/reports',
  settings: '/settings',
  integrations: '/integrations',
  assets: '/assets'
};

export default {
  starter: '/',
  landing: '/landing',
  login: '/login',
  authorized: '/authorized',
  error403: `${rootPaths.errorsRoot}/403`,
  error404: `${rootPaths.errorsRoot}/404`,
  error500: `${rootPaths.errorsRoot}/500`,
  error403: `${rootPaths.errorsRoot}/access-denied`,
  settings: `${rootPaths.settings}`,
  dashTickets: `${rootPaths.dashboardRoot}/tickets`,
  listTickets: `${rootPaths.ticketsRoot}`,
  ticketsPreview: `${rootPaths.ticketsRoot}/:id`,
  listPreTickets: `/pre-tickets`,
  serviceGroupRegistration: `${rootPaths.registrationRoot}/service-groups`,
  contactRegistration: `${rootPaths.registrationRoot}/contacts`,
  typeTicketRegistration: `${rootPaths.registrationRoot}/types-ticket`,
  agentRegistration: `${rootPaths.registrationRoot}/agents`,
  profileRegistration: `${rootPaths.registrationRoot}/profiles`,
  customerRegistration: `${rootPaths.registrationRoot}/customers`,
  customerDetails: `${rootPaths.registrationRoot}/customers/:id`,
  emailBoxRegistration: `${rootPaths.registrationRoot}/mailbox`,
  serviceCatalogRegistration: `${rootPaths.registrationRoot}/services-catalog`,
  serviceCatalogDetails: `${rootPaths.registrationRoot}/services-catalog/:id`,
  holidayRegistration: `${rootPaths.registrationRoot}/holidays`,
  businessHourRegistration: `${rootPaths.registrationRoot}/business-hour`,
  slaPolicyList: `${rootPaths.registrationRoot}/sla-policies`,
  slaPolicyRegistration: `${rootPaths.registrationRoot}/sla-policies/new`,
  slaPolicyEdit: `${rootPaths.registrationRoot}/sla-policies/:id`,
  pauseReasonRegistration: `${rootPaths.registrationRoot}/pause-reasons`,
  scheduledTicketList: `${rootPaths.registrationRoot}/scheduleds-tickets`,
  scheduledTicketRegistration: `${rootPaths.registrationRoot}/scheduleds-tickets/new`,
  scheduledTicketEdit: `${rootPaths.registrationRoot}/scheduleds-tickets/:id`,
  profileUser: `${rootPaths.userRoot}/profile`,
  myCompany: `${rootPaths.companyRoot}/my-company`,
  reportExecutiveCustomer: `${rootPaths.reports}/executive`,
  productivityAnalysis: `${rootPaths.reports}/productivity-analysis`,
  reportOverview: `${rootPaths.reports}/overview`,
  integrations: `${rootPaths.settings}/integrations`,
  dattoRMM: `${rootPaths.integrations}/datto-rmm`,
  assetTypeRegistration: `${rootPaths.registrationRoot}/asset-types`,
  assetTypeEdit: `${rootPaths.registrationRoot}/asset-types/:id`,
  assetList: `${rootPaths.assets}`,
  assetEdit: `${rootPaths.assets}/:id`
};


