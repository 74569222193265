import { useAppContext } from 'providers/AppProvider';
import KanbanProvider from 'providers/KanbanProvider';
import React, { useEffect, useState } from 'react';
import KanbanContainer from './KanbanContainer';

const Kanban = ({ filters }) => {
  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useAppContext();
  const [kanbanIsFluid] = useState(isFluid);
  const [kanbanIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);

    return () => {
      setConfig('isFluid', kanbanIsFluid);
      setConfig('isNavbarVerticalCollapsed', kanbanIsNavbarVerticalCollapsed);
    };
  }, []);

  return (
    <>
      <KanbanProvider>
        {/* <KanbanHeader /> */}
        <KanbanContainer filters={filters} />
      </KanbanProvider>
    </>
  );
};

export default Kanban;
