import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const CustomPropertyRegistrationModal = ({ assetTypeId, show, close, refresh, data, setData }) => {
  const api = useApi();
  const [body, setBody] = useState({
    name: '',
    dataType: '',
    required: false
  });
  const [dataTypes, setDataTypes] = useState([]);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if (show) {
      setBody({ name: '', dataType: '', required: false });
      setValidated(false);
      getDataTypes();
    }
  }, [show]);

  useEffect(() => {
    if (data) {
      setBody({
        name: data.name,
        dataType: data.dataType && data.dataType.dataType,
        required: data.required
      });
    }
  }, [data]);

  const onSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);

      let response;
      if (data) {
        response = await api.assetTypes.properties.edit(assetTypeId, data.id, body);
        setSuccess("Propriedade alterada com sucesso");

      } else {
        response = await api.assetTypes.properties.create(assetTypeId, body);
        setSuccess("Propriedade cadastrada com sucesso");
      }

      if (response.id) {
        setShowSuccess(true);
        close();
        refresh();
      } else {
        setError(response);
        setShowError(true);
      }
      setData(null);
    }
  };

  const getDataTypes = async () => {
    const response = await api.assetTypes.dataTypes();

    if (Array.isArray(response))
      setDataTypes(response);
  };

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message={success} />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error.userMessage} />
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{data ? "Editar " : "Nova "} propriedade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Form.Group className="mb-3">
              <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                autoFocus
                value={body.name}
                onChange={e => setBody({ ...body, name: e.target.value })}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tipo de dado <span className="text-danger">*</span></Form.Label>
              <Form.Select
                value={body.dataType}
                required
                onChange={e => setBody({ ...body, dataType: e.target.value })}
              >
                <option value="" key="">Selecione...</option>
                {dataTypes.map(({ dataType, value }) => (
                  <option value={dataType} key={dataType}>{value}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um tipo de dado.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Obrigatório <span className="text-danger">*</span></Form.Label>
              <Form.Check
                type="switch"
                value={body.required}
                checked={body.required}
                onChange={() => setBody({ ...body, required: !body.required })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-default" onClick={close}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomPropertyRegistrationModal;