import FailedRequest from "components/alerts/FailedRequest";
import SuccessRequest from "components/alerts/SuccessRequest";
import IconButton from "components/common/IconButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AddressCustomerEditModal from "components/modals/CustomerAddressEditModal";
import AddressCustomerRegistrationModal from "components/modals/CustomerAddressRegistrationModal";
import DialogModal from "components/modals/DialogModal";
import Pagination from "components/pagination";
import useApi from "helpers/api";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";

const CustomerAddress = ({ data }) => {
  const api = useApi();
  const [customer, setCustomer] = useState({});
  const [showModalRegistration, setShowModalRegistration] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addresses, setAddresses] = useState({
    content: [
      {
        name: '',
        neighborhood: '',
        state: '',
        city: '',
        cep: '',
        street: '',
        number: '',
        complement: ''
      }
    ]
  });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10
  });

  const handleCloseModalRegistration = () => {
    setShowModalRegistration(false);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  };

  const handleCloseDialog = () => {
    setShowDialogConfirm(false);
  }

  const handleEdit = item => {
    setSelectedAddress(item);
    setShowModalEdit(true);
  };

  const handleDelete = item => {
    setSelectedAddress(item);
    setShowDialogConfirm(true);
  };

  const getAddresses = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    let response = await api.customersAddresses.list(customer.id, queryParams);
    setAddresses(response);
  };

  const addAddress = async body => {
    let response = await api.customersAddresses.add(customer.id, body);
    if (response.status) {
      setError(response);
      setShowError(true);
    } else {
      getAddresses();
      setShowSuccess(true);
    }
    handleCloseModalRegistration();
  };

  const editAddress = async body => {
    let response = await api.customersAddresses.edit(customer.id, selectedAddress.id, body);
    if (response.status) {
      setError(response);
    } else {
      getAddresses();
      setShowSuccess(true);
    }
    handleCloseModalEdit();
  };

  const deleteAddress = async response => {
    if (response) {
      let request = await api.customersAddresses.delete(customer.id, selectedAddress.id);
      if (request.ok) {
        getAddresses();
      } else {
        setError(request);
        setShowError(true);
      }
      handleCloseDialog();

    }
  }

  useEffect(() => {
    setCustomer(data);
  }, [data]);

  useEffect(() => {
    if (Object.keys(customer).length != 0) {
      getAddresses();
    }
  }, [customer, filters]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'state',
      header: 'Estado',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'city',
      header: 'Cidade',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'neighborhood',
      header: 'Bairro',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'cep',
      header: 'CEP',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'street',
      header: 'Rua',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'number',
      header: 'Número',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'complement',
      header: 'Complemento',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <>
            <IconButton
              className="ms-2"
              iconClassName="fs--2"
              variant="link"
              size="sm"
              icon="pencil-alt"
              onClick={() => handleEdit(item)}>
            </IconButton>

            <IconButton
              className="ms-2"
              iconClassName="fs--2"
              variant="link"
              size="sm"
              icon="trash"
              onClick={() => handleDelete(item)}>
            </IconButton>
          </>
        )
      }
    },
  ];

  const table = useAdvanceTable({
    data: addresses.content,
    columns
  });

  return (
    <>
      <SuccessRequest
        message="Contato cadastrado com sucesso."
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <AddressCustomerRegistrationModal
        showModal={showModalRegistration}
        handleClose={handleCloseModalRegistration}
        handleSubmit={addAddress} />
      <AddressCustomerEditModal
        data={selectedAddress}
        showModal={showModalEdit}
        handleClose={handleCloseModalEdit}
        handleSubmit={editAddress} />
      <DialogModal
        message="Tem certeza que deseja excluir este endereço?"
        showModal={showDialogConfirm}
        handleClose={handleCloseDialog}
        response={deleteAddress} />
      <div>
        <div className="py-2 d-flex justify-content-end align-items-center">
          <IconButton
            className="ms-2"
            iconClassName="fs--2 me-1"
            variant="primary"
            size="sm"
            icon="plus"
            onClick={() => setShowModalRegistration(true)}>
            Novo
          </IconButton>
        </div>
        <div className="py-0">
          <div id="addresses">
            <AdvanceTableProvider {...table}>
              <AdvanceTable
                table
                headerClassName="bg-100 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs-10 mb-0 overflow-hidden'
                }}
              />
            </AdvanceTableProvider>

            <Pagination
              data={addresses}
              filters={filters}
              setFilters={setFilters} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerAddress;