import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import MaskedInput from "react-text-mask";

const AgentRegistrationModal = ({ showModal, handleClose, handleSubmit }) => {
  const api = useApi();
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    inactive: false,
    profile: { id: null }
  });
  const [profiles, setProfiles] = useState([]);
  const form = useRef(null);

  useEffect(() => {
    const getProfiles = async () => {
      let response = await api.profiles.list();
      if (response.content) setProfiles(response.content);
    };
    getProfiles();
  }, []);

  useEffect(() => {
    if (showModal) {
      clearFields();
      setValidated(false);
    }
  }, [showModal]);

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({
        firstName: body.firstName,
        lastName: body.lastName,
        email: body.email,
        password: body.password,
        phone: body.phone,
        profile: body.profile
      });
      clearFields();
      setValidated(false);
    }
  };

  function clearFields() {
    setBody({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phone: '',
      inactive: false,
      profile: { id: null }
    });
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Novo agente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="agent.firstName">
              <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                autoFocus
                value={body.firstName}
                onChange={e => setBody(prevBody => ({ ...prevBody, firstName: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="agent.lastName">
              <Form.Label>Sobrenome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Sobrenome"
                value={body.lastName}
                onChange={e => setBody(prevBody => ({ ...prevBody, lastName: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um sobrenome.</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="agent.email">
              <Form.Label>E-mail <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="E-mail"
                value={body.email}
                onChange={e => setBody(prevBody => ({ ...prevBody, email: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um e-mail.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="agent.password">
              <Form.Label>
                Senha
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                required
                type="password"
                pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=\S+$).{8,}$"
                placeholder="Senha"
                value={body.password}
                onChange={e => setBody(prevBody => ({ ...prevBody, password: e.target.value }))}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">A senha deve ter 8 caracteres, incluindo pelo menos uma letra maiúscula, um número e um caractere especial.</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="agent.phone">
            <Form.Label>Telefone <span className="text-danger">*</span></Form.Label>
            <MaskedInput
              mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              placeholder="Telefone"
              required
              guide={false}
              value={body.phone}
              onChange={e => setBody(prevBody => ({ ...prevBody, phone: e.target.value }))}
              render={(ref, props) => (
                <Form.Control ref={ref} {...props} />
              )}
            />

            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um telefone.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="agent.profile">
            <Form.Label>Perfil de agente <span className="text-danger">*</span></Form.Label>
            <Form.Select aria-label="Perfil" className="flex-1 me-2" onChange={e => setBody(prevBody => ({ ...prevBody, profile: { id: e.target.value } }))} required>
              <option value="">Selecione uma opção</option>
              {profiles.map((item, i) => (
                <option value={item.id} key={i}>{item.name}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Escolha uma opção!</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" type="submit" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AgentRegistrationModal;